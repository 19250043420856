import {
  Button,
  CircularLoader,
  Component,
  Scrollable,
  styleForFullExpansion,
  theme,
  Tips,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { builder, Count, hasLength, Index, ZERO } from '@atomica.co/utils';
import { FetchConnectingsRequest, UserEntity, UserId } from '@atomica.co/yosemal-v2';
import { Typography } from '@material-ui/core';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import { default as React, useEffect, useRef } from 'react';
import styled from 'styled-components';
import SelectableUserCard from '../../../../../components/card/SelectableUserCard';
import ConnectionRequest from '../../../../../requests/connection-request';
import { CreateBoardIndex } from '../CreateBoard';

const NO_CONECTIONS_MESSAGE = '新しく人と繋がると\nイベントに招待できるようになります。';

const LIMIT = 10;

const OPTIONS: IntersectionObserverInit = {
  root: null,
  rootMargin: '0px 0px 300px 0px'
};

interface P {
  userId: UserId;
  ignoredConnections: UserEntity[];
  selectedConnections: UserEntity[];
  onClickSaveButton(users: UserEntity[]): void;
  goTo(index: CreateBoardIndex): void;
}

const SelectConnectionsToInvite: React.FC<P> = React.memo(props => {
  const { userId, ignoredConnections, selectedConnections: initSelectedConnections, onClickSaveButton, goTo } = props;

  const ref = useRef<HTMLDivElement>();
  const hasMore = useRef<boolean>(true);
  const count = useRef<Count>(ZERO);
  const unmountRef = useUnmountRef();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [connections, setConnections] = useSafeState<UserEntity[]>(unmountRef, []);
  const [selectedConnections, setSelectedConnections] = useSafeState<UserEntity[]>(unmountRef, initSelectedConnections);

  const loadConnections = useSafeCallback(async (): Promise<void> => {
    if (!hasMore) return;

    const request = builder<FetchConnectingsRequest>().userId(userId).limit(LIMIT).offset(count.current).build();

    const response = await ConnectionRequest.fetchConnectings(request);
    const connectionsToAdd = response.connections;

    hasMore.current = connectionsToAdd.length === LIMIT;
    count.current += connectionsToAdd.length;
    setConnections(connections =>
      [...connections, ...response.connections].filter(
        target => !ignoredConnections.find(ignored => ignored.userId === target.userId)
      )
    );
    setLoaded(true);
  }, [userId, setConnections, ignoredConnections, setLoaded]);

  useEffect(() => {
    loadConnections();
  }, [loadConnections]);

  const onScroll = useSafeCallback(
    (entries: IntersectionObserverEntry[]): void => {
      for (const entry of entries) {
        if (!entry.isIntersecting) return;
        loadConnections();
      }
    },
    [loadConnections]
  );

  useEffect(() => {
    if (!loaded) return;

    const observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => onScroll(entries), OPTIONS);

    ref.current && observer.observe(ref.current);
    return () => observer.disconnect();
  }, [loaded, onScroll]);

  const handleConnectionClicked = useSafeCallback(
    (newlyClickedConnection: UserEntity): void => {
      setSelectedConnections(previouslySelectedConnections => {
        const isSelected = !!previouslySelectedConnections.find(
          previouslySelectedConnection => previouslySelectedConnection.userId === newlyClickedConnection.userId
        );

        return isSelected
          ? previouslySelectedConnections.filter(
              previouslySelectedConnection => previouslySelectedConnection.userId !== newlyClickedConnection.userId
            )
          : [...previouslySelectedConnections, newlyClickedConnection];
      });
    },
    [setSelectedConnections]
  );

  return (
    <Component style={styleForFullExpansion} className='select-connections-to-invite'>
      <Header>
        <ButtonArea>
          <Button onClick={() => goTo(CreateBoardIndex.CREATE_BOARD_MAIN)}>
            <BackIcon />
          </Button>
        </ButtonArea>

        <LabelArea>Yosemal で招待</LabelArea>

        <ButtonArea>
          {hasLength(connections) && (
            <Button type='primary' shape='rect' onClick={() => onClickSaveButton(selectedConnections)}>
              <ButtonLabel>OK</ButtonLabel>
            </Button>
          )}
        </ButtonArea>
      </Header>

      <Body>
        {hasLength(connections) && (
          <Scrollable>
            <ListWrapper>
              {connections.map((connection: UserEntity, index: Index) => (
                <CardWrapper key={index}>
                  <SelectableUserCard
                    selected={!!selectedConnections.find(s => s.userId === connection.userId)}
                    user={connection}
                    onClick={() => handleConnectionClicked(connection)}
                  />
                </CardWrapper>
              ))}
            </ListWrapper>

            {hasMore.current && (
              <LoaderWrapper>
                <CircularLoader />
              </LoaderWrapper>
            )}

            <Bottom ref={ref} />
          </Scrollable>
        )}

        {!hasLength(connections) && (
          <TipsWrapper>
            <Tips message={NO_CONECTIONS_MESSAGE} />
          </TipsWrapper>
        )}
      </Body>
    </Component>
  );
});

export default SelectConnectionsToInvite;

const Header = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.mixins.spacing}px 0px;
`;

const ButtonArea = styled.div`
  min-width: 72px;
  height: 32px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

const BackIcon = styled(ArrowBackIosOutlinedIcon)`
  color: ${theme.mixins.typography.fontColor.lightGray};
`;

const LabelArea = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.lightGray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds} !important;
  font-family: ${theme.mixins.typography.fontFamily};
`;

const ButtonLabel = styled.div`
  width: 48px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Body = styled.div`
  width: 100%;
  height: calc(100% - 48px);
  background: ${theme.mixins.background.yellow};
`;

const ListWrapper = styled.div`
  width: 100%;
  height: auto;
  padding-top: ${theme.mixins.spacing}px;
`;

const CardWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing / 2}px ${theme.mixins.spacing}px;
`;

const LoaderWrapper = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Bottom = styled.div`
  width: 100%;
  height: 240px;
`;

const TipsWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
