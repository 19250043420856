import {
  Button,
  CheckButton,
  Component,
  getMobileWidth,
  Scrollable,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { builder, EMPTY, Index, noop, Width } from '@atomica.co/utils';
import { FrameId, SaveNewUserRequest, UserEntity } from '@atomica.co/yosemal-v2';
import { Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import UserPolaroid from '../../../components/polaroid/UserPolaroid';
import { HEADER_HEIGHT } from '../../../constants/common-constants';

interface P {
  request: SaveNewUserRequest;
  onSelect(request: SaveNewUserRequest): void;
}

const SelectUserFrame: React.FC<P> = React.memo(props => {
  const { request, onSelect } = props;
  const unmountRef = useUnmountRef();
  const width = useMemo(() => getMobileWidth(), []);
  const [frameIdToSave, setFrameIdToSave] = useSafeState<FrameId>(unmountRef, request.frameId);

  const user = useMemo<UserEntity>(() => {
    return builder<UserEntity>()
      .familyName(request.familyName || EMPTY)
      .familyNameKana(request.familyNameKana || EMPTY)
      .firstName(request.firstName || EMPTY)
      .firstNameKana(request.firstNameKana || EMPTY)
      .dateOfBirth(request.dateOfBirth || new Date())
      .frameId(request.frameId)
      .photoURL(request.photoURL)
      .build();
  }, [request]);

  const handleSaveButtonClicked = useSafeCallback((): void => {
    const updatedRequest = { ...request, frameId: frameIdToSave };
    onSelect(updatedRequest);
  }, [request, frameIdToSave, onSelect]);

  return (
    <Component className='select-user-frame'>
      <Container>
        <Scrollable>
          {Object.values(FrameId).map((id: FrameId, index: Index) => (
            <PolaroidArea key={index} width={width} onClick={() => setFrameIdToSave(id)}>
              <PolaroidWrapper>
                <UserPolaroid size='large' rotate='none' frameId={id} user={user} />
              </PolaroidWrapper>

              <CheckButtonWrapper width={width}>
                <CheckButton checked={id === frameIdToSave} onClick={noop} />
              </CheckButtonWrapper>
            </PolaroidArea>
          ))}

          <SaveButtonWrapper>
            <Button type='primary' onClick={handleSaveButtonClicked}>
              <Label>保存</Label>
            </Button>
          </SaveButtonWrapper>
        </Scrollable>
      </Container>
    </Component>
  );
});

export default SelectUserFrame;

const Container = styled.div`
  width: 100%;
  height: calc(100vh - ${HEADER_HEIGHT}px);
  display: flex;
  flex-flow: column;
`;

const PolaroidArea = styled.div<{ width: Width }>`
  width: ${props => props.width}px;
  height: ${props => props.width + theme.mixins.spacing * 10}px;
  position: relative;
`;

const PolaroidWrapper = styled.div`
  width: 100%;
  height: auto;
  position: absolute;
  padding: ${theme.mixins.spacing}px;
`;

const CheckButtonWrapper = styled.div<{ width: Width }>`
  width: 40px;
  height: auto;
  position: absolute;
  top: ${props => props.width / 2 + theme.mixins.spacing * 4}px;
  left: ${props => props.width / 2 - theme.mixins.spacing * 2}px;
`;

const SaveButtonWrapper = styled.div`
  width: 100%;
  height: auto;
  position: fixed;
  left: 0px;
  bottom: 16px;
  display: flex;
  justify-content: center;
`;

const Label = styled(Typography)`
  width: 120px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
