import {
  HeaderArea,
  theme,
  TransitionGroup,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { builder, Code, CODE_CONST } from '@atomica.co/utils';
import { ProviderId, VerifyUserRequest } from '@atomica.co/yosemal-v2';
import { default as React, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import Screen from '../../components/screen/Screen';
import { HEADER_HEIGHT } from '../../constants/common-constants';
import { toSignUpInTab } from '../../converters/path-converter';
import { Process } from '../../enums/common-enum';
import firebase, { analytics, auth } from '../../firebase';
import useCashedURL from '../../redux/hooks/useCashedURL';
import usePath from '../../redux/hooks/usePath';
import useProcess from '../../redux/hooks/useProcess';
import useUser from '../../redux/hooks/useUser';
import AuthRequest from '../../requests/auth-request';
import { Path } from '../../router/Routes';
import SignIn from './sign-in/SignIn';
import SignUp from './sign-up/SignUp';
import SignUpInTabs, { SignUpInTabEnum } from './tabs/SignUpInTabs';

interface P extends RouteComponentProps {}

const SignUpInScreen: React.FC<P> = React.memo(() => {
  const { getUser } = useUser();
  const { path, queryParams, openPath } = usePath();
  const { process, setProcess } = useProcess();
  const { hasCashedURL, openCashedURL } = useCashedURL();
  const unmountRef = useUnmountRef();
  const [index, setIndex] = useSafeState<SignUpInTabEnum>(unmountRef, toSignUpInTab(path));

  useEffect(() => {
    const indexToUpdate = toSignUpInTab(path);
    setIndex(indexToUpdate);
  }, [path, setIndex]);

  const succeededToSignIn = useSafeCallback(
    async (res: firebase.auth.UserCredential): Promise<void> => {
      if (!res.user) {
        setProcess(Process.INITIAL);
        return;
      }

      const user = await getUser();
      if (!user || !user.isActivated) {
        openPath(Path.REGISTER_ACCOUNT);
        return;
      }

      setProcess(Process.COMPLETED);
      hasCashedURL() ? openCashedURL() : openPath(Path.BOARD_LIST);
    },
    [setProcess, getUser, openPath, hasCashedURL, openCashedURL]
  );

  const failedToSignIn = useSafeCallback((): void => {
    setProcess(Process.INITIAL);
  }, [setProcess]);

  const signInWithRedirect = useSafeCallback((): void => {
    auth.getRedirectResult().then(succeededToSignIn).catch(failedToSignIn);
  }, [succeededToSignIn, failedToSignIn]);

  const signInWithLine = useSafeCallback(
    async (code: Code): Promise<void> => {
      const idToken = await AuthRequest.fetchIdToken(code);
      const request = builder<VerifyUserRequest>().idToken(idToken).providerId(ProviderId.LINE).build();
      const response = await AuthRequest.verifyUser(request);

      if (!response.customToken) {
        setProcess(Process.INITIAL);
        return;
      }

      auth
        .signInWithCustomToken(response.customToken)
        .then(succeededToSignIn)
        .catch(() => setProcess(Process.INITIAL));
    },
    [succeededToSignIn, setProcess]
  );

  useEffect(() => {
    const code = queryParams[CODE_CONST];
    analytics.setCurrentScreen('ログイン画面');

    if (!!code) {
      signInWithLine(code);
      return;
    }

    signInWithRedirect();
  }, [queryParams, signInWithLine, signInWithRedirect]);

  return (
    <Screen loading={process !== Process.INITIAL} className='sign-up-in-screen'>
      <HeaderArea>
        <SignUpInTabs />
      </HeaderArea>

      <TransitionWrapper>
        <TransitionGroup index={index} components={[<SignIn onSignIn={succeededToSignIn} />, <SignUp />]} />
      </TransitionWrapper>
    </Screen>
  );
});

export default SignUpInScreen;

const TransitionWrapper = styled.div`
  margin-top: ${HEADER_HEIGHT}px;
  ${theme.mixins.userSelectDisabled};
`;
