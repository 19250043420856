import { Component, theme } from '@atomica.co/components';
import { Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import yosemal from './../../assets/yosemal/yosemal_logo.png';

interface P {}

const Logo: React.FC<P> = React.memo(() => {
  return (
    <Component className='logo'>
      <Container>
        <Image src={yosemal} />

        <CatchCopy>寄せ書いてつながろう</CatchCopy>
      </Container>
    </Component>
  );
});

export default Logo;

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
  padding: ${theme.mixins.spacing}px;
`;

const CatchCopy = styled(Typography)`
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  padding: ${theme.mixins.spacing}px;
`;
