import { UserId } from '@atomica.co/yosemal-v2';
import React from 'react';
import ExpandableModal from '../../../components/modal/ExpandableModal';
import ShareAccount from './parts/ShareAccount';

const HEIGHT = 480;

interface P {
  userId: UserId | undefined;
  isModalOpen: boolean;
  onClose(): void;
}

const AccountPartialModal: React.FC<P> = React.memo(props => {
  const { userId, isModalOpen, onClose } = props;

  return (
    <ExpandableModal isOpen={isModalOpen} modalHeight={HEIGHT} onClose={onClose}>
      <ShareAccount userId={userId} onClose={onClose} />
    </ExpandableModal>
  );
});

export default AccountPartialModal;
