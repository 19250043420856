import { Component, Scrollable, theme, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { builder, URL } from '@atomica.co/utils';
import { BoardEntity, BoardId, BoardMessageId, FetchBoardRequest, UserEntity } from '@atomica.co/yosemal-v2';
import React, { useEffect, useImperativeHandle, useMemo } from 'react';
import styled from 'styled-components';
import BoardDetailsPolaroid from '../../../components/polaroid/BoardDetailsPolaroid';
import { HEADER_HEIGHT } from '../../../constants/common-constants';
import usePath from '../../../redux/hooks/usePath';
import BoardRequest from '../../../requests/board-request';
import { Path } from '../../../router/Routes';
import { BoardAction } from '../BoardScreen';
import BoardMessageList, { BoardMessageListRef } from './parts/BoardMessageList';

export interface BoardDetailsRef {
  refresh(): void;
}

interface P {
  messageListRef: React.RefObject<BoardMessageListRef>;
  boardId: BoardId | undefined;
  user: UserEntity | undefined;
  onClick(photoURL: URL): void;
  onReply(boardId: BoardId, boardMessageId: BoardMessageId): void;
  emitAction(action: BoardAction): void;
}

const BoardDetails: React.ForwardRefExoticComponent<P & React.RefAttributes<BoardDetailsRef>> = React.forwardRef<
  BoardDetailsRef,
  P
>((props, detailsRef) => {
  const { messageListRef, boardId, user, onClick, onReply, emitAction } = props;
  const { path } = usePath();
  const loadable = useMemo(() => path === Path.BOARD_DETAILS, [path]);
  const unmountRef = useUnmountRef();
  const [showDescriptions, setShowDescriptions] = useSafeState<boolean>(unmountRef, false);
  const [board, setBoard] = useSafeState<BoardEntity | undefined>(unmountRef);

  const loadBoard = useSafeCallback(async (): Promise<void> => {
    if (!boardId) return;

    const request = builder<FetchBoardRequest>().boardId(boardId).build();
    const response = await BoardRequest.fetchBoard(request);

    setBoard(response.board);
  }, [boardId, setBoard]);

  useEffect(() => {
    if (!loadable) return;
    loadBoard();
  }, [loadable, loadBoard]);

  const refresh = useSafeCallback(() => {
    if (!loadable) return;
    loadBoard();
  }, [loadable, loadBoard]);

  useImperativeHandle(detailsRef, () => ({
    refresh: () => refresh()
  }));

  const handleActionEmitted = useSafeCallback(
    (action: BoardAction): void => {
      switch (action) {
        case BoardAction.SHOW_BOARD_DESCRIPTIONS:
          setShowDescriptions(show => !show);
          break;
      }

      emitAction(action);
    },
    [setShowDescriptions, emitAction]
  );

  return (
    <Component className='board-details'>
      <Container>
        <Scrollable showScrollbar>
          <PolaroidWrapper>
            <BoardDetailsPolaroid
              showDescriptions={showDescriptions}
              showSettingIcon
              rotate='left'
              board={board}
              onClick={handleActionEmitted}
            />
          </PolaroidWrapper>

          {!!boardId && (
            <ListWrapper>
              <BoardMessageList
                ref={messageListRef}
                loadable={loadable}
                boardId={boardId}
                user={user}
                onClickPhoto={onClick}
                onClickReply={boardMessageId => onReply(boardId, boardMessageId)}
              />
            </ListWrapper>
          )}
        </Scrollable>
      </Container>
    </Component>
  );
});

export default BoardDetails;

const Container = styled.div`
  width: 100%;
  height: calc(100vh - ${HEADER_HEIGHT}px);
  display: flex;
  margin-top: ${HEADER_HEIGHT}px;
`;

const PolaroidWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
`;

const ListWrapper = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: ${theme.mixins.spacing * 10}px;
`;
