import {
  Button,
  Component,
  InputBox,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { BCC_EMAIL, Email, EMAIL_CONST, EMPTY, Message } from '@atomica.co/utils';
import { DOMAINS, ErrorCodeEnum, ERROR_MESSAGES } from '@atomica.co/yosemal-v2';
import { Typography } from '@material-ui/core';
import MailIcon from '@material-ui/icons/Mail';
import firebase from 'firebase';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { CASHED_URL } from '../../../../../constants/common-constants';
import env from '../../../../../env/env';
import { auth } from '../../../../../firebase';
import useCashedURL from '../../../../../redux/hooks/useCashedURL';
import { Path } from '../../../../../router/Routes';

interface P {
  onSendEmail(email: Email): void;
}

const EmailSignUp: React.FC<P> = React.memo(props => {
  const { onSendEmail } = props;
  const { cashedURL } = useCashedURL();
  const unmountRef = useUnmountRef();
  const [disabledSendButton, setDisabledSendButton] = useSafeState<boolean>(unmountRef, true);
  const [email, setEmail] = useSafeState<Email>(unmountRef, EMPTY);
  const [errorMessage, setErrorMessage] = useSafeState<Message>(unmountRef, EMPTY);

  useEffect(() => {
    setDisabledSendButton(!email);
  }, [setDisabledSendButton, email]);

  const constructErrorMessage = useSafeCallback(
    (code: ErrorCodeEnum): void => {
      if (!code) return;
      setErrorMessage(ERROR_MESSAGES[code]);
    },
    [setErrorMessage]
  );

  const sendSignInLinkToEmail = useSafeCallback((): void => {
    if (!email) {
      constructErrorMessage(ErrorCodeEnum.INVALID_EMAIL);
      return;
    }

    setDisabledSendButton(true);
    const settings: firebase.auth.ActionCodeSettings = {
      handleCodeInApp: true,
      url: `https://${DOMAINS[env]}${Path.REGISTER_ACCOUNT}?${EMAIL_CONST}=${email}&${CASHED_URL}=${cashedURL}`
    };

    auth
      .sendSignInLinkToEmail(email, settings)
      .then(() => {
        auth.sendSignInLinkToEmail(BCC_EMAIL, settings);
        onSendEmail(email);
      })
      .catch(() => {
        setDisabledSendButton(false);
        constructErrorMessage(ErrorCodeEnum.INVALID_EMAIL);
      });
  }, [constructErrorMessage, setDisabledSendButton, email, cashedURL, onSendEmail]);

  return (
    <Component className='email-sign-up'>
      <ContentWrapper>
        <InputWrapper>
          <InputBox hasError={!!errorMessage} type='email' label='メールアドレス' text={email} onChange={setEmail} />
        </InputWrapper>

        {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

        <ButtonWrapper>
          <Button type='primary' disabled={disabledSendButton} onClick={sendSignInLinkToEmail}>
            <IconWrapper>
              <MailIcon fontSize='small' />
            </IconWrapper>

            <Label>メールアドレスで登録</Label>
          </Button>
        </ButtonWrapper>
      </ContentWrapper>
    </Component>
  );
});

export default EmailSignUp;

const ContentWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
`;

const InputWrapper = styled.div`
  padding: ${theme.mixins.spacing * 2}px;
`;

const ErrorMessage = styled(Typography)`
  width: 100%;
  height: auto;
  color: ${theme.mixins.typography.fontColor.pink};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px !important;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  text-align: center;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 4}px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: ${theme.mixins.spacing / 2}px;
`;

const IconWrapper = styled.div`
  width: 48px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Label = styled(Typography)`
  width: 200px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
