import { combineReducers } from 'redux';
import cashedURLReducer from './cashed-url-reducer';
import processReducer from './process-reducer';
import userReducer from './user-reducer';

export default combineReducers({
  process: processReducer,
  cashedURL: cashedURLReducer,
  user: userReducer
});
