import {
  Button,
  Component,
  DateBox,
  Scrollable,
  styleForFullExpansion,
  theme,
  ToggleBox,
  useSafeCallback
} from '@atomica.co/components';
import { builder, EMPTY, URL } from '@atomica.co/utils';
import {
  BoardEntity,
  BoardMemberRole,
  SaveBoardDueDateRequest,
  SaveBoardIsRequiredNewPhotoRequest,
  UserId
} from '@atomica.co/yosemal-v2';
import Icon from '@material-ui/icons/Close';
import { default as React } from 'react';
import styled from 'styled-components';
import { ArrowForwardButton } from '../../../../../components/buttons/ArrowForwardButton';
import BoardPhotoChanger from '../../../../../components/polaroid/BoardPhotoChanger';
import BoardRequest from '../../../../../requests/board-request';
import BoardSettingsHeader from '../../../board-header/BoardSettingsHeader';
import { BoardSettingsIndex } from '../BoardSettings';

interface P {
  role: BoardMemberRole;
  userId: UserId;
  dueDate: Date | undefined;
  board: BoardEntity | undefined;
  onClick(index: BoardSettingsIndex): void;
  onSaveBoardPhoto(photoURL: URL): void;
  onSaveDueDate(dueDate: Date): void;
  onSaveIsRequiredNewPhoto(isRequiredNewPhoto: boolean): void;
  onClose(): void;
}

const BoardSettingsMain: React.FC<P> = React.memo(props => {
  const { role, userId, dueDate, board, onClick, onSaveBoardPhoto, onSaveDueDate, onSaveIsRequiredNewPhoto, onClose } =
    props;

  const saveDueDate = useSafeCallback(
    async (dueDate: Date): Promise<void> => {
      if (!board) return;

      const request = builder<SaveBoardDueDateRequest>().boardId(board.boardId).dueDate(dueDate).build();

      await BoardRequest.saveDueDate(request);
      onSaveDueDate(dueDate);
    },
    [board, onSaveDueDate]
  );

  const saveIsRequiredNewPhoto = useSafeCallback(
    async (isRequiredNewPhoto: boolean): Promise<void> => {
      if (!board) return;

      const request = builder<SaveBoardIsRequiredNewPhotoRequest>()
        .boardId(board.boardId)
        .isRequiredNewPhoto(isRequiredNewPhoto)
        .build();

      await BoardRequest.saveIsRequiredNewPhoto(request);
      onSaveIsRequiredNewPhoto(isRequiredNewPhoto);
    },
    [board, onSaveIsRequiredNewPhoto]
  );

  return (
    <Component style={styleForFullExpansion} className='board-settings-main'>
      <Header>
        <UpperHeader>
          <UpperButtonArea>
            <Button onClick={onClose}>
              <CloseIcon />
            </Button>
          </UpperButtonArea>

          <UpperLabelArea>
            <UpperLabel>{!!board ? board.name : undefined}</UpperLabel>
          </UpperLabelArea>

          <UpperButtonArea />
        </UpperHeader>

        <LowerHeader>
          <BoardSettingsHeader role={role} userId={userId} board={board} onClickIcon={onClick} />
        </LowerHeader>
      </Header>

      <Body>
        <Scrollable>
          <BoardPhotoChanger role={role} board={board} onChange={onSaveBoardPhoto} />

          {(role === BoardMemberRole.OWNER || role === BoardMemberRole.ADMIN) && (
            <LowerWrapper>
              <ArrowForwardButton
                shape='rect'
                text='フレームを選ぶ'
                onClick={() => onClick(BoardSettingsIndex.SELECT_BOARD_FRAME)}
              />
            </LowerWrapper>
          )}

          {(role === BoardMemberRole.OWNER || role === BoardMemberRole.ADMIN) && (
            <UpperWrapper>
              <ArrowForwardButton
                shape='rect'
                label='ボード名'
                text={!!board ? board.name : EMPTY}
                onClick={() => onClick(BoardSettingsIndex.INPUT_BOARD_NAME)}
              />
            </UpperWrapper>
          )}

          {(role === BoardMemberRole.OWNER || role === BoardMemberRole.ADMIN) && (
            <LowerWrapper>
              <ArrowForwardButton
                shape='rect'
                label='説明'
                text={!!board && !!board.description ? board.description : EMPTY}
                onClick={() => onClick(BoardSettingsIndex.INPUT_BOARD_DESCRIPTION)}
              />
            </LowerWrapper>
          )}

          {(role === BoardMemberRole.OWNER || role === BoardMemberRole.ADMIN) && !!dueDate && (
            <Wrapper>
              <DateBox shape='rect' label='公開日の設定' minDate={new Date()} value={dueDate} onChange={saveDueDate} />
            </Wrapper>
          )}

          {(role === BoardMemberRole.OWNER || role === BoardMemberRole.ADMIN) && !!board && (
            <UpperWrapper>
              <ToggleBox
                checked={board.isRequiredNewPhoto}
                text='写真の変更を必須にする'
                onClick={saveIsRequiredNewPhoto}
              />
            </UpperWrapper>
          )}

          {(role === BoardMemberRole.OWNER || role === BoardMemberRole.ADMIN) && (
            <LowerWrapper>
              <ArrowForwardButton
                shape='rect'
                label='希望する写真'
                text={!!board && !!board.requestForNewPhoto ? board.requestForNewPhoto : EMPTY}
                onClick={() => onClick(BoardSettingsIndex.INPUT_REQUEST_FOR_NEW_PHOTO)}
              />
            </LowerWrapper>
          )}

          {/* <BoxWrapper>
            <ToggleBox
              checked
              text="知り合いに自動追加する"
              onClick={noop}
            />
          </BoxWrapper> */}

          {/* <RemarksWrapper>
            <Remarks>
              招待した知り合いはグループに自動で追加されます。グループに参加するか知り合いに選んでもらうには、この設定をオフにします。
            </Remarks>
          </RemarksWrapper> */}

          <Bottom />
        </Scrollable>
      </Body>
    </Component>
  );
});

export default BoardSettingsMain;

const Header = styled.div`
  width: 100%;
  height: auto;
`;

const UpperHeader = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.mixins.spacing}px;
`;

const UpperButtonArea = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

const CloseIcon = styled(Icon)`
  color: ${theme.mixins.typography.fontColor.lightGray};
`;

const UpperLabelArea = styled.div`
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px ${theme.mixins.spacing * 2}px;
`;

const UpperLabel = styled.div`
  width: 100%;
  height: 100%;
  color: ${theme.mixins.typography.fontColor.lightGray};
  font-size: ${theme.mixins.typography.fontSize.sixteen};
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  display: flex;
  align-items: center;
  justify-content: center;
  ${theme.mixins.textAbbreviated};
`;

const LowerHeader = styled.div`
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: ${theme.mixins.spacing / 2}px;
`;

const Body = styled.div`
  width: 100%;
  height: calc(100% - 112px);
  background: ${theme.mixins.background.lightGray};
`;

const Wrapper = styled.div`
  padding: ${theme.mixins.spacing * 2}px;
`;

const UpperWrapper = styled.div`
  padding: ${theme.mixins.spacing * 2}px ${theme.mixins.spacing * 2}px ${theme.mixins.spacing / 4}px;
`;

const LowerWrapper = styled.div`
  padding: ${theme.mixins.spacing / 4}px ${theme.mixins.spacing * 2}px ${theme.mixins.spacing * 2}px;
`;

const Bottom = styled.div`
  margin-bottom: ${theme.mixins.spacing * 20}px;
`;

// const RemarksWrapper = styled.div`
//   padding: ${theme.mixins.spacing / 2}px ${theme.mixins.spacing * 3}px;
// `;

// const Remarks = styled(Typography)`
//   color: ${theme.mixins.typography.fontColor.gray};
//   font-size: ${theme.mixins.typography.fontSize.twelve}px;
//   font-weight: ${theme.mixins.typography.fontWeight.fourHundreds} !important;
//   font-family: ${theme.mixins.typography.fontFamily};
// `;
