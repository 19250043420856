import { Component, Icon, theme } from '@atomica.co/components';
import { Label } from '@atomica.co/utils';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import React from 'react';
import styled from 'styled-components';
import Header from '../../../components/header/Header';
import { AccountAction } from '../AccountScreen';
import arrow_left from './../../../assets/icon/icon_arrow_left.png';

interface P {
  label: Label;
  emitAction(action: AccountAction): void;
}

const BoardListHeader: React.FC<P> = React.memo(props => {
  const { label, emitAction } = props;

  return (
    <Component className='board-list-header'>
      <Header
        label={label}
        leftChild={
          <LeftArea>
            <IconWrapper onClick={() => emitAction(AccountAction.BACK_TO_PROFILE)}>
              <Icon style={styleForIcon} src={arrow_left} />
            </IconWrapper>
          </LeftArea>
        }
        rightChild={<RightArea />}
      />
    </Component>
  );
});

export default BoardListHeader;

const LeftArea = styled.div`
  width: 48px;
  height: auto;
  padding-left: ${theme.mixins.spacing}px;
`;

const RightArea = styled.div`
  width: 48px;
  height: auto;
  padding-right: ${theme.mixins.spacing}px;
`;

const IconWrapper = styled.div`
  width: 40px;
  height: auto;
  padding: ${theme.mixins.spacing}px;
`;

const styleForIcon: CSSProperties = {
  width: 24,
  height: 24
};
