import { convert, RequestType } from '@atomica.co/utils';
import {
  FetchBoardMemberByUserIdRequest,
  FetchBoardMemberByUserIdResponse,
  DeleteBoardMemberRequest,
  DeleteBoardMemberResponse,
  DELETE_BOARD_MEMBER,
  FetchBoardMembersRequest,
  FetchBoardMembersResponse,
  FetchNonBoardMembersRequest,
  FetchNonBoardMembersResponse,
  FETCH_BOARD_MEMBERS,
  FETCH_NON_BOARD_MEMBERS,
  FETCH_BOARD_MEMBER_BY_USER_ID,
  InviteNewBoardMembersRequest,
  InviteNewBoardMembersResponse,
  INVITE_NEW_BOARD_MEMBERS,
  SaveNewBoardMemberRequest,
  SaveNewBoardMemberResponse,
  SAVE_NEW_BOARD_MEMBER,
  toFuncName
} from '@atomica.co/yosemal-v2';
import { functions } from '../firebase';

export default class BoardMemberRequest {
  public static saveNewMember = async (request: SaveNewBoardMemberRequest): Promise<SaveNewBoardMemberResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_NEW_BOARD_MEMBER));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static fetchMembers = async (request: FetchBoardMembersRequest): Promise<FetchBoardMembersResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(FETCH_BOARD_MEMBERS));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static fetchNonMembers = async (
    request: FetchNonBoardMembersRequest
  ): Promise<FetchNonBoardMembersResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(FETCH_NON_BOARD_MEMBERS));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static fetchMemberByUserId = async (
    request: FetchBoardMemberByUserIdRequest
  ): Promise<FetchBoardMemberByUserIdResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(FETCH_BOARD_MEMBER_BY_USER_ID));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };
  public static deleteMember = async (request: DeleteBoardMemberRequest): Promise<DeleteBoardMemberResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(DELETE_BOARD_MEMBER));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static inviteNewMembers = async (
    request: InviteNewBoardMembersRequest
  ): Promise<InviteNewBoardMembersResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(INVITE_NEW_BOARD_MEMBERS));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };
}
