import { Component, HeaderArea, theme } from '@atomica.co/components';
import { Label as LabelVo } from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import React from 'react';
import styled from 'styled-components';

interface P {
  label?: LabelVo;
  leftChild?: React.ReactNode;
  rightChild?: React.ReactNode;
}

const Header: React.FC<P> = React.memo(props => {
  const { label, leftChild, rightChild } = props;

  return (
    <Component className='header'>
      <HeaderArea style={styleForHeader}>
        <Container>
          <Child>{leftChild}</Child>

          <Label>{label}</Label>

          <Child>{rightChild}</Child>
        </Container>
      </HeaderArea>
    </Component>
  );
});

export default Header;

const styleForHeader: CSSProperties = {
  background: theme.mixins.background.white,
  boxShadow: theme.mixins.shadow.light
};

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${theme.mixins.userSelectDisabled};
`;

const Child = styled.div``;

const Label = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.lightGray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds} !important;
  font-family: ${theme.mixins.typography.fontFamily};
  ${theme.mixins.textAbbreviated};
`;
