import { theme, TransitionGroup, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { builder, Email, EMAIL_CONST, EMPTY, URL } from '@atomica.co/utils';
import { CollegeDto, FrameId, ProviderId, SaveNewUserRequest } from '@atomica.co/yosemal-v2';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import Header from '../../components/header/Header';
import Screen from '../../components/screen/Screen';
import { CASHED_URL, HEADER_HEIGHT } from '../../constants/common-constants';
import { DEFAULT_USER_PHOTO } from '../../constants/user-const';
import { analytics, auth } from '../../firebase';
import useCashedURL from '../../redux/hooks/useCashedURL';
import usePath from '../../redux/hooks/usePath';
import useUser from '../../redux/hooks/useUser';
import { persistor } from '../../redux/store';
import UserRequest from '../../requests/user-request';
import { Path } from '../../router/Routes';
import InputAccountInfo from './input-account-info/InputAccountInfo';
import SelectUserFrame from './select-user-frame/SelectUserFrame';

const INIT_COLLEGE = {
  collegeId: '873BE428-3566-44F2-A006-E160725DBDE7',
  code: 'handai',
  name: '大阪大学'
} as CollegeDto;

export enum RegisterIndex {
  INPUT_ACCOUNT_INFO,
  SELECT_USER_FRAME
}

interface P {}

const RegisterScreen: React.FC<P> = React.memo(() => {
  const { queryParams, openPath } = usePath();
  const { getFirebase, getUser } = useUser();
  const { saveCashedURL, hasCashedURL, openCashedURL } = useCashedURL();
  const unmountRef = useUnmountRef();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [index, setIndex] = useSafeState<RegisterIndex>(unmountRef, RegisterIndex.INPUT_ACCOUNT_INFO);
  const [email, setEmail] = useSafeState<Email>(unmountRef);
  const [request, setRequest] = useSafeState<SaveNewUserRequest>(unmountRef);

  const cashedURLFromQueryParam = useMemo((): URL => {
    return queryParams[CASHED_URL];
  }, [queryParams]);

  const emailFromQueryParam = useMemo((): Email => {
    return queryParams[EMAIL_CONST];
  }, [queryParams]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    if (!!cashedURLFromQueryParam) {
      saveCashedURL(cashedURLFromQueryParam);
    }

    if (!!emailFromQueryParam) {
      setEmail(emailFromQueryParam);
      setLoaded(true);
      return;
    }

    const [firebase, user] = await Promise.all([getFirebase(), getUser()]);

    if (!firebase) {
      await auth.signOut();
      await persistor.purge();
      openPath(Path.SIGN_IN);
      return;
    }

    const providerData = firebase.providerData.find(data => !!data && !!data.email);
    const email = !!providerData ? providerData.email : firebase.email;

    if (!email) {
      await auth.signOut();
      await persistor.purge();
      openPath(Path.SIGN_IN);
      return;
    }

    const initRequest = builder<SaveNewUserRequest>()
      .isActivated(true)
      .userId(!!user ? user.userId : EMPTY)
      .email(!!user ? user.email : EMPTY)
      .frameId(FrameId.NONE)
      .photoURL(!!user ? user.photoURL : EMPTY)
      .college(INIT_COLLEGE) // FIXME
      .build();

    setEmail(email);
    setRequest(initRequest);
    setLoaded(true);
  }, [
    cashedURLFromQueryParam,
    saveCashedURL,
    emailFromQueryParam,
    getFirebase,
    getUser,
    openPath,
    setEmail,
    setRequest,
    setLoaded
  ]);

  useEffect(() => {
    initialize();
    analytics.setCurrentScreen('アカウント登録画面');
  }, [initialize]);

  const handleUserFrameSelected = useSafeCallback(
    (request: SaveNewUserRequest): void => {
      setRequest(request);
      setIndex(RegisterIndex.INPUT_ACCOUNT_INFO);
    },
    [setRequest, setIndex]
  );

  const saveNewUser = useSafeCallback(
    async (request: SaveNewUserRequest): Promise<void> => {
      const firebase = await getFirebase();
      const providerId = !!emailFromQueryParam ? ProviderId.EMAIL : ProviderId.GOOGLE;
      const requestToSave = Object.assign(request, {
        externalId: firebase!.uid,
        email,
        providerId,
        photoURL: !!request.photoURL ? request.photoURL : DEFAULT_USER_PHOTO
      });
      await UserRequest.saveNewUser(requestToSave);
      hasCashedURL() ? openCashedURL() : openPath(Path.BOARD_LIST);
    },
    [getFirebase, emailFromQueryParam, email, hasCashedURL, openCashedURL, openPath]
  );

  return (
    <Screen loading={!loaded} className='register-screen'>
      <Header label='アカウントの作成' />

      <TransitionWrapper>
        <TransitionGroup
          index={index}
          components={[
            <InputAccountInfo
              isSignedUpByEmail={!!emailFromQueryParam}
              email={email}
              request={request}
              onChange={setRequest}
              onSave={saveNewUser}
              goTo={setIndex}
            />,
            <SelectUserFrame request={request} onSelect={handleUserFrameSelected} />
          ]}
        />
      </TransitionWrapper>
    </Screen>
  );
});

export default RegisterScreen;

const TransitionWrapper = styled.div`
  margin-top: ${HEADER_HEIGHT}px;
  ${theme.mixins.userSelectDisabled};
`;
