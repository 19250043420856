import { Button, Component, Icon, theme, useSafeCallback } from '@atomica.co/components';
import { embedIdInPath } from '@atomica.co/utils';
import { BoardId, BoardMessageEntity } from '@atomica.co/yosemal-v2';
import { Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import BoardMessagePolaroid from '../../../../components/polaroid/BoardMessagePolaroid';
import usePath from '../../../../redux/hooks/usePath';
import { Path, PATH_IDS } from '../../../../router/Routes';
import BoardMessageWrittenHeader from '../../board-header/BoardMessageWrittenHeader';
import poppers from './../../../../assets/icon/icon_poppers.png';

const BUTTON_LABEL = 'いま書き込んだ\nボードを開く';

interface P {
  boardId: BoardId | undefined;
  newlyWrittenMessage: BoardMessageEntity | undefined;
}

const BoardMessageWritten: React.FC<P> = React.memo(props => {
  const { boardId, newlyWrittenMessage } = props;
  const { openPath } = usePath();

  const openBoardDetailsScreen = useSafeCallback((): void => {
    !!boardId && openPath(embedIdInPath(Path.BOARD_DETAILS, PATH_IDS, [boardId]));
  }, [boardId, openPath]);

  return (
    <Component style={{ width: '100%' }} className='board-message-written'>
      <BoardMessageWrittenHeader />

      <Container>
        <PolaroidWrapper>
          <BoardMessagePolaroid size='medium' rotate='none' message={newlyWrittenMessage} />

          <IconWrapper>
            <Icon size='xlarge' src={poppers} />
          </IconWrapper>
        </PolaroidWrapper>

        <ButtonArea>
          <Button type='primary' onClick={openBoardDetailsScreen}>
            <ButtonLabel>{BUTTON_LABEL}</ButtonLabel>
          </Button>
        </ButtonArea>
      </Container>
    </Component>
  );
});

export default BoardMessageWritten;

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  padding-top: ${theme.mixins.spacing}px;
`;

const PolaroidWrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  padding: ${theme.mixins.spacing}px;
`;

const IconWrapper = styled.div`
  width: 96px;
  height: 96px;
  position: absolute;
  right: 16px;
  bottom: -80px;
`;

const ButtonArea = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: ${theme.mixins.spacing * 12}px ${theme.mixins.spacing}px;
`;

const ButtonLabel = styled(Typography)`
  width: 144px;
  height: 32px;
  color: ${theme.mixins.typography.fontColor.white};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: pre-wrap;
`;
