import {
  Button,
  Component,
  InputBox,
  Scrollable,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { BCC_EMAIL, Email, EMAIL_CONST, EMPTY, Message } from '@atomica.co/utils';
import { DOMAINS, ErrorCodeEnum, ERROR_MESSAGES } from '@atomica.co/yosemal-v2';
import { Typography } from '@material-ui/core';
import MailIcon from '@material-ui/icons/Mail';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import Logo from '../../../../../components/logo/Logo';
import { HEADER_HEIGHT } from '../../../../../constants/common-constants';
import env from '../../../../../env/env';
import firebase, { auth } from '../../../../../firebase';

interface P {
  onSendEmail(email: Email): void;
  goBack(): void;
}

const InputEmail: React.FC<P> = React.memo(props => {
  const { onSendEmail, goBack } = props;
  const unmountRef = useUnmountRef();
  const [disabledSendButton, setDisabledSendButton] = useSafeState<boolean>(unmountRef, true);
  const [email, setEmail] = useSafeState<Email>(unmountRef, EMPTY);
  const [errorMessage, setErrorMessage] = useSafeState<Message>(unmountRef, EMPTY);

  useEffect(() => {
    setDisabledSendButton(!email);
  }, [setDisabledSendButton, email]);

  const constructErrorMessage = useSafeCallback(
    (code: ErrorCodeEnum): void => {
      if (!code) return;
      setErrorMessage(ERROR_MESSAGES[code]);
    },
    [setErrorMessage]
  );

  const signInWithEmailAndPassword = useSafeCallback((): void => {
    if (!email) {
      constructErrorMessage(ErrorCodeEnum.INVALID_EMAIL);
      return;
    }

    setDisabledSendButton(true);
    const settings: firebase.auth.ActionCodeSettings = {
      handleCodeInApp: true,
      url: `https://${DOMAINS[env]}?${EMAIL_CONST}=${email}`
    };

    auth
      .sendPasswordResetEmail(email, settings)
      .then(() => {
        auth.sendPasswordResetEmail(BCC_EMAIL, settings);
        onSendEmail(email);
      })
      .catch(() => {
        setDisabledSendButton(false);
        constructErrorMessage(ErrorCodeEnum.INVALID_EMAIL);
      });
  }, [setDisabledSendButton, email, constructErrorMessage, onSendEmail]);

  return (
    <Component className='input-email'>
      <Container>
        <Scrollable>
          <LogoWrapper>
            <Logo />
          </LogoWrapper>

          <InputWrapper>
            <InputBox hasError={!!errorMessage} type='email' label='メールアドレス' text={email} onChange={setEmail} />
          </InputWrapper>

          {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

          <BackButtonWrapper>
            <Button onClick={goBack}>
              <BackLabel>← 戻る</BackLabel>
            </Button>
          </BackButtonWrapper>

          <SendButtonWrapper>
            <Button type='primary' disabled={disabledSendButton} onClick={signInWithEmailAndPassword}>
              <IconWrapper>
                <MailIcon fontSize='small' />
              </IconWrapper>
              <SendLabel>再設定のURLを送る</SendLabel>
            </Button>
          </SendButtonWrapper>
        </Scrollable>
      </Container>
    </Component>
  );
});

export default InputEmail;

const Container = styled.div`
  width: 100%;
  height: calc(100vh - ${HEADER_HEIGHT}px);
`;

const LogoWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: ${theme.mixins.spacing * 2}px;
`;

const InputWrapper = styled.div`
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px 0px;
`;

const ErrorMessage = styled(Typography)`
  width: 100%;
  height: auto;
  color: ${theme.mixins.typography.fontColor.pink};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px !important;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  text-align: center;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 4}px;
`;

const BackButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: ${theme.mixins.spacing * 2}px;
`;

const BackLabel = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px !important;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
`;

const SendButtonWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding-top: ${theme.mixins.spacing * 2}px;
  margin-bottom: ${theme.mixins.spacing * 10}px;
`;

const IconWrapper = styled.div`
  width: 48px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SendLabel = styled(Typography)`
  width: 200px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
