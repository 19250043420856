import { useSafeCallback } from '@atomica.co/components';
import { BoardId, UserId } from '@atomica.co/yosemal-v2';
import React from 'react';
import FullModal from '../../../components/modal/FullModal';
import { BoardAction } from '../BoardScreen';
import BoardSettings from './board-settings/BoardSettings';
import CreateBoard from './create-board/CreateBoard';

interface P {
  isModalOpen: boolean;
  action: BoardAction;
  userId: UserId;
  boardId: BoardId | undefined;
  emitAction(action: BoardAction, id?: BoardId): void;
  onClose(): void;
}

const BoardFullModal: React.FC<P> = React.memo(props => {
  const { isModalOpen, action, userId, boardId, emitAction, onClose } = props;

  const handleBoardSaved = useSafeCallback(
    (boardId: BoardId): void => {
      onClose();
      emitAction(BoardAction.REFRESH_BOARD_LIST);
      setTimeout(() => emitAction(BoardAction.OPEN_BOARD_DETAILS, boardId), 1000);
    },
    [onClose, emitAction]
  );

  return (
    <FullModal isOpen={isModalOpen} onClose={onClose}>
      {action === BoardAction.CREATE_BOARD && (
        <CreateBoard userId={userId} onSaveBoard={handleBoardSaved} onClose={onClose} />
      )}

      {action === BoardAction.SHOW_BOARD_SETTINGS && (
        <BoardSettings
          userId={userId}
          boardId={boardId}
          onClose={() => {
            emitAction(BoardAction.REFRESH_BOARD_DETAILS);
            onClose();
          }}
        />
      )}
    </FullModal>
  );
});

export default BoardFullModal;
