import {
  Button,
  Component,
  styleForFullExpansion,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { builder, EMPTY } from '@atomica.co/utils';
import { BoardEntity, DeleteBoardMemberRequest, UserId } from '@atomica.co/yosemal-v2';
import { Typography } from '@material-ui/core';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import { default as React } from 'react';
import styled from 'styled-components';
import usePath from '../../../../../redux/hooks/usePath';
import BoardMemberRequest from '../../../../../requests/board-member-request';
import { Path } from '../../../../../router/Routes';

interface P {
  userId: UserId;
  board: BoardEntity | undefined;
  goBack(): void;
}

const LeaveBoard: React.FC<P> = React.memo(props => {
  const { userId, board, goBack } = props;
  const { openPath } = usePath();
  const unmountRef = useUnmountRef();
  const [disabledSaveButton, setDisabledSaveButton] = useSafeState<boolean>(unmountRef, false);

  const saveBoardName = useSafeCallback(async (): Promise<void> => {
    if (!board) return;
    setDisabledSaveButton(true);

    try {
      const request = builder<DeleteBoardMemberRequest>().boardId(board.boardId).userId(userId).build();

      await BoardMemberRequest.deleteMember(request);
      openPath(Path.BOARD_LIST);
    } catch (e) {
      setDisabledSaveButton(false);
    }
  }, [board, setDisabledSaveButton, userId, openPath]);

  return (
    <Component style={styleForFullExpansion} className='leave-board'>
      <Header>
        <HeaderButton>
          <Button onClick={goBack}>
            <BackIcon />
          </Button>
        </HeaderButton>

        <HeaderLabel>{!!board ? board.name : EMPTY}</HeaderLabel>

        <HeaderButton />
      </Header>

      <Body>
        <Message>ボードから退会してよろしいですか</Message>

        <ButtonArea>
          <Button disabled={disabledSaveButton} type='primary' onClick={saveBoardName}>
            <Label>退会</Label>
          </Button>
        </ButtonArea>
      </Body>
    </Component>
  );
});

export default LeaveBoard;

const Header = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.mixins.spacing}px;
`;

const HeaderButton = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

const BackIcon = styled(ArrowBackIosOutlinedIcon)`
  color: ${theme.mixins.typography.fontColor.lightGray};
`;

const HeaderLabel = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.lightGray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds} !important;
  font-family: ${theme.mixins.typography.fontFamily};
`;

const Body = styled.div`
  width: 100%;
  height: calc(100% - 48px);
  background: ${theme.mixins.background.lightGray};
`;

const Message = styled.div`
  width: 100%;
  height: auto;
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds} !important;
  font-family: ${theme.mixins.typography.fontFamily};
  text-align: center;
  padding: ${theme.mixins.spacing * 6}px ${theme.mixins.spacing * 4}px ${theme.mixins.spacing * 2}px;
`;

const ButtonArea = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: ${theme.mixins.spacing * 4}px;
`;

const Label = styled(Typography)`
  width: 80px;
  height: auto;
`;
