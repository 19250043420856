import { Button, Scrollable, theme, useSafeState, useSafeCallback, useUnmountRef } from '@atomica.co/components';
import { Typography } from '@material-ui/core';
import Icon from '@material-ui/icons/Close';
import styled from 'styled-components';
import { URL, Width, Height, Aspect, findMin } from '@atomica.co/utils';
import React, { useRef } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop, PixelCrop, PercentCrop } from 'react-image-crop';
import '../../../node_modules/react-image-crop/dist/ReactCrop.css';
import { cropImg } from '../../utils/image-util';
import FullModal from '../../components/modal/FullModal';
import PhotoRequest from '../../requests/photo-request';
const TITLE = 'トリミングする';
const aspect = 1;

function cropCenterAspect(mediaWidth: Width, mediaHeight: Height, aspect: Aspect): PixelCrop | PercentCrop {
  const crop = centerCrop(
    makeAspectCrop(
      {
        unit: 'px',
        width: findMin(mediaWidth, mediaHeight)
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
  return crop;
}

interface P {
  isDialogOpen: boolean;
  uploadedPhotoURL: URL | undefined;
  setUploadedPhotoURL(uploadedPhotoURL: URL | undefined): void;
  onUpload(uploadedPhotoURL: URL): void;
  onClose(): void;
}
const TrimmingDialog: React.FC<P> = React.memo(props => {
  const { isDialogOpen, uploadedPhotoURL, setUploadedPhotoURL, onUpload, onClose } = props;
  const imgRef = useRef<HTMLImageElement>(null);
  const unmountRef = useUnmountRef();
  const [crop, setCrop] = useSafeState<PixelCrop | PercentCrop>(unmountRef);
  const [imgToUpload, setImgToUpload] = useSafeState<File>(unmountRef);

  const createImgToUpload = useSafeCallback(
    async (c: PixelCrop | PercentCrop): Promise<void> => {
      if (!imgRef.current) return;
      const croppedImg = await cropImg(imgRef.current, c);
      if (!croppedImg) return;
      setImgToUpload(croppedImg);
    },
    [setImgToUpload]
  );

  const handleBoardPhotoCropped = useSafeCallback(async (): Promise<void> => {
    if (!imgToUpload) return;
    const uploadedPhotoURL = await PhotoRequest.uploadBoardPhoto(imgToUpload);
    if (!uploadedPhotoURL) return;
    setUploadedPhotoURL(uploadedPhotoURL);
    onUpload(uploadedPhotoURL);
    onClose();
  }, [imgToUpload, onClose, setUploadedPhotoURL, onUpload]);

  const onImageLoad = useSafeCallback(
    async (e: React.SyntheticEvent<HTMLImageElement>): Promise<void> => {
      if (!aspect) return;
      const { width, height } = e.currentTarget;
      const centerCrop = cropCenterAspect(width, height, aspect);
      setCrop(centerCrop);
      createImgToUpload(centerCrop);
    },
    [setCrop, createImgToUpload]
  );

  return (
    <FullModal isOpen={isDialogOpen} onClose={onClose}>
      <Header>
        <ButtonLeft>
          <Button
            onClick={() => {
              setUploadedPhotoURL(undefined);
              onClose();
            }}
          >
            <CloseIcon />
          </Button>
        </ButtonLeft>

        <LabelArea>
          <HeaderLabel>{TITLE}</HeaderLabel>
        </LabelArea>

        <ButtonRight>
          <Button type='primary-outlined' shape='rect' onClick={handleBoardPhotoCropped}>
            確定する
          </Button>
        </ButtonRight>
      </Header>
      <Body>
        <Scrollable>
          <Container>
            <Wrapper>
              <ReactCrop crop={crop} onChange={c => setCrop(c)} onComplete={c => createImgToUpload(c)} aspect={aspect}>
                <CroppedPhoto
                  ref={imgRef}
                  alt='トリミングする写真を選択してください'
                  src={uploadedPhotoURL}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
            </Wrapper>

            <ButtonWrapper>
              <Button type='primary' onClick={handleBoardPhotoCropped}>
                <ButtonLabel>確定する</ButtonLabel>
              </Button>
            </ButtonWrapper>
          </Container>
        </Scrollable>
      </Body>
    </FullModal>
  );
});

export default TrimmingDialog;
const Header = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.mixins.spacing}px 0px;
`;

const ButtonLeft = styled.div`
  min-width: 80px;
  height: 64px;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
`;

const ButtonRight = styled.div`
  min-width: 80px;
  height: 64px;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  justify-content: center;
  margin-right: ${theme.mixins.spacing}px;
`;

const CloseIcon = styled(Icon)`
  color: ${theme.mixins.typography.fontColor.lightGray};
`;

const LabelArea = styled.div`
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px ${theme.mixins.spacing * 2}px;
`;

const HeaderLabel = styled.div`
  width: 100%;
  height: 100%;
  color: ${theme.mixins.typography.fontColor.lightGray};
  font-size: ${theme.mixins.typography.fontSize.sixteen};
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: pre;
  text-align: center;
`;

const Body = styled.div`
  width: 100%;
  height: calc(100% - 48px);
  background: ${theme.mixins.background.yellow};
`;

const Container = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: ${theme.mixins.spacing * 20}px;
`;

const Wrapper = styled.div`
  padding: ${theme.mixins.spacing * 2}px;
`;

const CroppedPhoto = styled.img``;

const ButtonWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding-top: ${theme.mixins.spacing * 2}px;
`;

const ButtonLabel = styled(Typography)`
  width: 96px;
  height: 32px;
  color: ${theme.mixins.typography.fontColor.white};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  display: flex;
  align-items: center;
  justify-content: center;
`;
