import { useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { embedIdInPath } from '@atomica.co/utils';
import { BoardId, UserEntity, UserId, USER_ID } from '@atomica.co/yosemal-v2';
import React, { useEffect, useMemo, useRef } from 'react';
import Screen from '../../components/screen/Screen';
import usePath from '../../redux/hooks/usePath';
import useUser from '../../redux/hooks/useUser';
import { Path, PATH_IDS } from '../../router/Routes';
import AccountFullModal from './account-full-modal/AccountFullModal';
import AccountPartialModal from './account-partial-modal/AccountPartialModal';
import CommonBoardList from './board-list/CommonBoardList';
import CommonConnectionList from './connection-list/CommonConnectionList';
import ConnectionList from './connection-list/ConnectionList';
import Profile, { ProfileRef } from './profile/Profile';

export enum AccountAction {
  OPEN_COMMON_BOARD_LIST = 'open_common_board_list',
  OPEN_COMMON_CONNECTION_LIST = 'open_common_connection_list',
  OPEN_CONNECTION_LIST = 'open_connection_list',
  EDIT_PROFILE = 'edit_profile',
  SHOW_QR = 'show_qr',
  REFRESH_PROFILE = 'refresh_profile',
  BACK_TO_PROFILE = 'back_to_profile'
}

export enum AccountIndex {
  PROFILE,
  COMMON_BOARD_LIST,
  COMMON_CONNECTION_LIST,
  CONNECTION_LIST,
  OUT_OF_TARGET
}

interface P {}

const AccountScreen: React.FC<P> = React.memo(() => {
  const { path, params, openPath } = usePath();
  const { getUser } = useUser();
  const profileRef = useRef<ProfileRef>(null);
  const unmountRef = useUnmountRef();
  const isAccountScreenOpen = useMemo<boolean>(() => path === Path.ACCOUNT, [path]);
  const [isFullModalOpen, setIsFullModalOpen] = useSafeState<boolean>(unmountRef, false);
  const [isPartialModalOpen, setIsPartialModalOpen] = useSafeState<boolean>(unmountRef, false);
  const [index, setIndex] = useSafeState<AccountIndex>(unmountRef, AccountIndex.PROFILE);
  const [userIdToDisplay, setUserIdToDisplay] = useSafeState<UserId | undefined>(unmountRef, params[USER_ID]);
  const [signInUser, setSignInUser] = useSafeState<UserEntity | undefined>(unmountRef);

  const initialize = useSafeCallback(async (): Promise<void> => {
    setUserIdToDisplay(params[USER_ID]);
    if (!isAccountScreenOpen) return;
    const signInUser = await getUser();
    setSignInUser(signInUser);
  }, [setUserIdToDisplay, params, isAccountScreenOpen, getUser, setSignInUser]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const handleActionEmitted = useSafeCallback(
    (action: AccountAction): void => {
      switch (action) {
        case AccountAction.OPEN_CONNECTION_LIST:
          setIndex(AccountIndex.CONNECTION_LIST);
          return;

        case AccountAction.BACK_TO_PROFILE:
          setIndex(AccountIndex.PROFILE);
          return;

        case AccountAction.OPEN_COMMON_CONNECTION_LIST:
          setIndex(AccountIndex.COMMON_CONNECTION_LIST);
          return;

        case AccountAction.OPEN_COMMON_BOARD_LIST:
          setIndex(AccountIndex.COMMON_BOARD_LIST);
          return;

        case AccountAction.EDIT_PROFILE:
          setIsFullModalOpen(true);
          return;

        case AccountAction.REFRESH_PROFILE:
          !!profileRef.current && profileRef.current.refresh();
          setIsFullModalOpen(false);
          return;

        case AccountAction.SHOW_QR:
          setIsPartialModalOpen(true);
          return;

        default:
          throw new Error(`${action} is out of target.`);
      }
    },
    [setIndex, setIsFullModalOpen, setIsPartialModalOpen]
  );

  const handleConnectionClicked = useSafeCallback(
    (userId: UserId): void => {
      setUserIdToDisplay(userId);
      setIndex(AccountIndex.PROFILE);
    },
    [setUserIdToDisplay, setIndex]
  );

  const handleBoardClicked = useSafeCallback(
    (boardId: BoardId): void => {
      setUserIdToDisplay(undefined);
      setIndex(AccountIndex.PROFILE);
      openPath(embedIdInPath(Path.BOARD_DETAILS, PATH_IDS, [boardId]));
    },
    [setUserIdToDisplay, setIndex, openPath]
  );

  return (
    <Screen className='account-screen'>
      {index === AccountIndex.PROFILE && (
        <Profile
          ref={profileRef}
          userIdToDisplay={userIdToDisplay}
          browsingUser={signInUser}
          emitAction={handleActionEmitted}
        />
      )}

      {index === AccountIndex.COMMON_BOARD_LIST && (
        <CommonBoardList
          userId={userIdToDisplay}
          signInUser={signInUser}
          onClickBoard={handleBoardClicked}
          emitAction={handleActionEmitted}
        />
      )}

      {index === AccountIndex.COMMON_CONNECTION_LIST && (
        <CommonConnectionList
          userId={userIdToDisplay}
          signInUser={signInUser}
          onClickConnection={handleConnectionClicked}
          emitAction={handleActionEmitted}
        />
      )}

      {index === AccountIndex.CONNECTION_LIST && (
        <ConnectionList
          userId={userIdToDisplay}
          onClickConnection={handleConnectionClicked}
          emitAction={handleActionEmitted}
        />
      )}

      <AccountFullModal
        isModalOpen={isFullModalOpen}
        userId={userIdToDisplay}
        signInUser={signInUser}
        emitAction={handleActionEmitted}
        onClose={() => setIsFullModalOpen(false)}
      />

      <AccountPartialModal
        userId={userIdToDisplay}
        isModalOpen={isPartialModalOpen}
        onClose={() => setIsPartialModalOpen(false)}
      />
    </Screen>
  );
});

export default AccountScreen;
