import { Count } from '@atomica.co/utils';
import { UserEntity } from '@atomica.co/yosemal-v2';
import { StyleSheet, View } from '@react-pdf/renderer';
import React, { useMemo } from 'react';
import { Styles } from '../BoardPagesPdf';
import UserPolaroidOne from './UserPolaroidOne';
import UserPolaroidsFive from './UserPolaroidsFive';
import UserPolaroidsFour from './UserPolaroidsFour';
import UserPolaroidsThree from './UserPolaroidsThree';
import UserPolaroidsTwo from './UserPolaroidsTwo';

export type PageSide = 'right' | 'left';

const styles: Styles = StyleSheet.create({
  container: { width: '100%', height: '100%' }
});

interface P {
  users: UserEntity[];
}

const UserPolaroids: React.FC<P> = React.memo(props => {
  const { users } = props;
  const userCount = useMemo<Count>(() => users.length, [users]);

  return (
    <View style={styles.container}>
      {userCount === 1 && <UserPolaroidOne users={users} />}
      {userCount === 2 && <UserPolaroidsTwo users={users} />}
      {userCount === 3 && <UserPolaroidsThree users={users} />}
      {userCount === 4 && <UserPolaroidsFour users={users} />}
      {userCount === 5 && <UserPolaroidsFive users={users} />}
    </View>
  );
});

export default UserPolaroids;
