import { ID } from '@atomica.co/utils';
import { BOARD_ID, BOARD_MESSAGE_ID, USER_ID } from '@atomica.co/yosemal-v2';

export const PATH_IDS: ID[] = [USER_ID, BOARD_ID, BOARD_MESSAGE_ID];

export enum Path {
  /** sign-up */
  SIGN_UP = '/signup',

  /** sign-in */
  SIGN_IN = '/signin',

  /** sign-out */
  SIGN_OUT = '/signout',

  /** liff */
  LIFF = '/liff',

  /** board */
  BOARD_LIST = '/',
  BOARD_DETAILS = '/board/:boardId',
  BOARD_MEMBER = '/board/:boardId/member/new',
  BOARD_MESSAGE = '/board/:boardId/message/new',
  BOARD_THREAD = '/board/:boardId/message/:boardMessageId/thread/new',
  BOARD_EXPORT = '/board/:boardId/export',

  /** connections */
  CONNECTIONS = '/connections',

  /** notifications */
  NOTIFICATIONS = '/notifications',

  /** account */
  REGISTER_ACCOUNT = '/account/new',
  ACCOUNT = '/:userId'
}
