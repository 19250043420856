import { Component, SingleLineText, Skeleton, theme } from '@atomica.co/components';
import { noop } from '@atomica.co/utils';
import { UserEntity } from '@atomica.co/yosemal-v2';
import { CSSProperties } from '@material-ui/styles';
import React from 'react';
import styled from 'styled-components';
import { toFullName } from '../../utils/user-util';

export const CARD_HEIGHT = 72;

interface P {
  user: UserEntity;
  onClick?(user: UserEntity): void;
}

const UserCard: React.FC<P> = React.memo(props => {
  const { user, onClick = noop } = props;

  return (
    <Component className='user-card'>
      <Box onClick={() => onClick(user)}>
        <PhotoArea>
          <Skeleton animation='wave' variant='circle' style={styleForUserPhoto} src={user.photoURL} />
        </PhotoArea>

        <TextArea>
          <Name>
            <TextWrapperForName>
              <SingleLineText style={styleForName} text={toFullName(user)} />
            </TextWrapperForName>
          </Name>

          <Comment>
            <TextWrapperForComment>
              <SingleLineText style={styleForComment} text={user.selfIntroduction} />
            </TextWrapperForComment>
          </Comment>
        </TextArea>
      </Box>
    </Component>
  );
});

export default UserCard;

const Box = styled.div`
  width: 100%;
  height: auto;
  background: ${theme.mixins.background.white};
  border: 1px solid ${theme.mixins.border.lightGray};
  border-radius: 16px;
  display: flex;
`;

const PhotoArea = styled.div`
  width: ${CARD_HEIGHT}px;
  min-width: ${CARD_HEIGHT}px;
  height: ${CARD_HEIGHT}px;
  border-radius: 16px 0px 0px 16px;
`;

const styleForUserPhoto: CSSProperties = {
  width: CARD_HEIGHT,
  height: CARD_HEIGHT,
  objectFit: 'contain',
  borderRadius: '15px 0px 0px 15px'
};

const TextArea = styled.div`
  width: calc(100% - ${CARD_HEIGHT}px);
  height: auto;
  padding: 0px ${theme.mixins.spacing}px;
  ${theme.mixins.userSelectDisabled};
`;

const Name = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: solid 1px ${theme.mixins.border.lightGray};
`;

const TextWrapperForName = styled.div`
  width: 100%;
  height: 24px;
  padding: 0px ${theme.mixins.spacing}px;
`;

const styleForName: CSSProperties = {
  width: '100%',
  fontSize: theme.mixins.typography.fontSize.twenty,
  fontWeight: theme.mixins.typography.fontWeight.sevenHundreds,
  fontFamily: theme.mixins.typography.fontFamily,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis'
};

const Comment = styled.div`
  width: 100%;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TextWrapperForComment = styled.div`
  width: 100%;
  height: 16px;
  padding: 0px ${theme.mixins.spacing}px;
  ${theme.mixins.textAbbreviated};
`;

const styleForComment: CSSProperties = {
  width: '100%',
  color: theme.mixins.typography.fontColor.gray,
  fontSize: theme.mixins.typography.fontSize.twelve,
  fontWeight: theme.mixins.typography.fontWeight.fourHundreds,
  fontFamily: theme.mixins.typography.fontFamily,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis'
};
