import {
  Button,
  Component,
  Scrollable,
  styleForFullExpansion,
  theme,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { builder, URL } from '@atomica.co/utils';
import { BoardEntity, FrameId } from '@atomica.co/yosemal-v2';
import { default as React } from 'react';
import styled from 'styled-components';
import BoardFrameSelecter from '../../../../components/polaroid/BoardFrameSelecter';
import BoardMessageFrameHeader from '../../board-header/BoardMessageFrameHeader';
import { BoardMessageIndex } from '../BoardMessage';

interface P {
  photoURL: URL | undefined;
  frameId: FrameId | undefined;
  onClickSaveButton(frameId: FrameId): void;
  goTo(index: BoardMessageIndex): void;
}

const SelectMessageFrame: React.FC<P> = React.memo(props => {
  const { photoURL, frameId: initFrameId, onClickSaveButton, goTo } = props;
  const unmountRef = useUnmountRef();
  const [frameIdToSave, setFrameIdToSave] = useSafeState<FrameId>(unmountRef, initFrameId);

  return (
    <Component style={styleForFullExpansion} className='select-message-frame'>
      <BoardMessageFrameHeader label='フレームを選ぶ' goBack={() => goTo(BoardMessageIndex.BOARD_MESSAGE_MAIN)} />

      <Scrollable>
        <Container>
          <BoardFrameSelecter
            selectedFrameId={frameIdToSave}
            board={builder<BoardEntity>().photoURL(photoURL!).frameId(frameIdToSave).build()}
            onChange={setFrameIdToSave}
          />

          <ButtonArea>
            <Button type='primary' onClick={() => onClickSaveButton(frameIdToSave)}>
              <Label>保存</Label>
            </Button>
          </ButtonArea>
        </Container>
      </Scrollable>
    </Component>
  );
});

export default SelectMessageFrame;

const Container = styled.div`
  width: 100%;
  height: auto;
  background: ${theme.mixins.background.yellow};
  margin-bottom: ${theme.mixins.spacing * 20}px;
`;

const ButtonArea = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  position: fixed;
  left: 0px;
  bottom: 16px;
`;

const Label = styled.div`
  width: 80px;
  height: auto;
`;
