import { Button, Component, Icon, theme } from '@atomica.co/components';
import { CSSProperties } from '@material-ui/styles';
import React from 'react';
import styled from 'styled-components';
import Header from '../../../components/header/Header';
import usePath from '../../../redux/hooks/usePath';
import { BoardMessageIndex } from '../board-message/BoardMessage';
import arrow_left from './../../../assets/icon/icon_arrow_left.png';

interface P {
  onClickWriteButton(index: BoardMessageIndex): void;
}

const BoardMemberHeader: React.FC<P> = React.memo(props => {
  const { onClickWriteButton } = props;
  const { goBack } = usePath();

  return (
    <Component className='board-member-header'>
      <Header
        leftChild={
          <IconsWrapper>
            <IconWrapper onClick={goBack}>
              <Icon style={styleForIcon} src={arrow_left} />
            </IconWrapper>
          </IconsWrapper>
        }
        rightChild={
          <ButtonWrapper>
            <Button
              type='primary'
              shape='rect'
              onClick={() => onClickWriteButton(BoardMessageIndex.BOARD_MESSAGE_MAIN)}
            >
              イベントに参加する
            </Button>
          </ButtonWrapper>
        }
      />
    </Component>
  );
});

export default BoardMemberHeader;

const IconsWrapper = styled.div`
  width: 168px;
  height: auto;
  display: flex;
  justify-content: flex-start;
  padding: ${theme.mixins.spacing}px;
`;

const IconWrapper = styled.div`
  width: 40px;
  height: auto;
  padding: ${theme.mixins.spacing}px;
`;

const styleForIcon: CSSProperties = {
  width: 24,
  height: 24
};

const ButtonWrapper = styled.div`
  width: 168px;
  height: auto;
  display: flex;
  justify-content: flex-end;
  padding: ${theme.mixins.spacing}px;
`;
