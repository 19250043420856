import {
  BoxFrame,
  Component,
  MultiLineText,
  Scrollable,
  SingleLineText,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { builder, Index, isMoreThanZero, toFacebookURL, toInstagramURL, toTwitterURL, ZERO } from '@atomica.co/utils';
import {
  BoardEntity,
  FetchUserProfileRequest,
  FetchUserRequest,
  ProfileEntity,
  UserDiv,
  UserEntity,
  UserId
} from '@atomica.co/yosemal-v2';
import { Typography } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useSnackbar } from 'notistack';
import React, { useEffect, useImperativeHandle, useMemo } from 'react';
import styled from 'styled-components';
import CommonPolaroid from '../../../components/polaroid/CommonPolaroid';
import UserPolaroid from '../../../components/polaroid/UserPolaroid';
import { HEADER_HEIGHT } from '../../../constants/common-constants';
import { COPY_EMAIL, SUCCESS } from '../../../constants/snackbar-const';
import { analytics } from '../../../firebase';
import UserRequest from '../../../requests/user-request';
import { COLLEGE_YEAR_LABELS } from '../../../texts/user-text';
import { toFullName } from '../../../utils/user-util';
import ProfileHeader from '../account-header/ProfileHeader';
import { AccountAction } from '../AccountScreen';
import facebook from './../../../assets/icon/icon_facebook.png';
import instagram from './../../../assets/icon/icon_instagram.png';
import mail from './../../../assets/icon/icon_mail.png';
import settingIcon from './../../../assets/icon/icon_setting.png';
import twitter from './../../../assets/icon/icon_twitter.png';

export interface ProfileRef {
  refresh(): void;
}

interface P {
  userIdToDisplay: UserId | undefined;
  browsingUser: UserEntity | undefined;
  emitAction(action: AccountAction): void;
}

const Profile: React.ForwardRefExoticComponent<P & React.RefAttributes<ProfileRef>> = React.forwardRef<ProfileRef, P>(
  (props, ref) => {
    const { userIdToDisplay, browsingUser, emitAction } = props;
    const { enqueueSnackbar } = useSnackbar();
    const unmountRef = useUnmountRef();
    const [userToDisplay, setUserToDisplay] = useSafeState<UserEntity | undefined>(unmountRef);
    const [displayingProfile, setDisplayingProfile] = useSafeState<ProfileEntity | undefined>(unmountRef);

    const showCompanyName = useMemo(() => !!displayingProfile && !!displayingProfile.companyName, [displayingProfile]);
    const showDepartmentName = useMemo(
      () => !!displayingProfile && !!displayingProfile.departmentName,
      [displayingProfile]
    );
    const showTitle = useMemo(() => !!displayingProfile && !!displayingProfile.title, [displayingProfile]);
    const showBeriefHistory = useMemo(
      () => !!displayingProfile && !!displayingProfile.beriefHistory,
      [displayingProfile]
    );
    const showCollege = useMemo(() => !!displayingProfile && !!displayingProfile.college, [displayingProfile]);
    const showFaculty = useMemo(() => !!displayingProfile && !!displayingProfile.faculty, [displayingProfile]);
    const showDepartment = useMemo(() => !!displayingProfile && !!displayingProfile.department, [displayingProfile]);
    const showMajor = useMemo(() => !!displayingProfile && !!displayingProfile.major, [displayingProfile]);
    const showCollegeYear = useMemo(() => !!displayingProfile && !!displayingProfile.collegeYear, [displayingProfile]);
    const showResearchSubject = useMemo(
      () => !!displayingProfile && !!displayingProfile.researchSubject,
      [displayingProfile]
    );
    const showSelfIntroduction = useMemo(
      () => !!displayingProfile && !!displayingProfile.selfIntroduction,
      [displayingProfile]
    );
    const showEmailToContactURL = useMemo(
      () => !!displayingProfile && !!displayingProfile.emailToContact,
      [displayingProfile]
    );
    const showTwitterURL = useMemo(() => !!displayingProfile && !!displayingProfile.twitterURL, [displayingProfile]);
    const showInstagramURL = useMemo(
      () => !!displayingProfile && !!displayingProfile.instagramURL,
      [displayingProfile]
    );
    const showFacebookURL = useMemo(() => !!displayingProfile && !!displayingProfile.facebookURL, [displayingProfile]);
    const showContacts = useMemo(
      () => !!showEmailToContactURL || !!showTwitterURL || !!showInstagramURL || !!showFacebookURL,
      [showEmailToContactURL, showTwitterURL, showInstagramURL, showFacebookURL]
    );
    const showCommonConnections = useMemo(
      () =>
        !!displayingProfile &&
        !!displayingProfile.commonConnections &&
        isMoreThanZero(displayingProfile.commonConnections.length),
      [displayingProfile]
    );
    const showConnections = useMemo(
      () =>
        !!displayingProfile &&
        !!displayingProfile.allConnections &&
        isMoreThanZero(displayingProfile.allConnections.length),
      [displayingProfile]
    );
    const showCommonBoards = useMemo(
      () =>
        !!displayingProfile &&
        !!displayingProfile.commonBoards &&
        isMoreThanZero(displayingProfile.commonBoards.length),
      [displayingProfile]
    );

    const isMyAccount = useMemo<boolean>(() => {
      return !!userToDisplay && !!browsingUser && userToDisplay.userId === browsingUser.userId;
    }, [userToDisplay, browsingUser]);

    const initialize = useSafeCallback(async (): Promise<void> => {
      if (!userIdToDisplay || !browsingUser) return;

      const userRequest = builder<FetchUserRequest>().userId(userIdToDisplay).build();

      const profileRequest = builder<FetchUserProfileRequest>()
        .userIdToDisplay(userIdToDisplay)
        .requestedUserId(browsingUser.userId)
        .build();

      const [userResponse, profileResponse] = await Promise.all([
        UserRequest.fetchUser(userRequest),
        UserRequest.fetchProfile(profileRequest)
      ]);

      setUserToDisplay(userResponse.user);
      setDisplayingProfile(profileResponse.profile);
    }, [browsingUser, userIdToDisplay, setUserToDisplay, setDisplayingProfile]);

    const reset = useSafeCallback((): void => {
      setUserToDisplay(undefined);
      setDisplayingProfile(undefined);
    }, [setUserToDisplay, setDisplayingProfile]);

    useEffect(() => {
      initialize();
      analytics.setCurrentScreen('アカウント画面');
      return reset;
    }, [initialize, reset]);

    const openMailer = useSafeCallback(
      async (profile: ProfileEntity | undefined): Promise<void> => {
        if (!profile || !profile.emailToContact) return;
        // window.open(`mailto:${profile.email}`, 'newtab');
        await navigator.clipboard.writeText(profile.emailToContact);
        enqueueSnackbar(COPY_EMAIL, { variant: SUCCESS });
      },
      [enqueueSnackbar]
    );

    const openTwitterURL = useSafeCallback((profile: ProfileEntity | undefined): void => {
      if (!profile || !profile.twitterURL) return;
      window.open(toTwitterURL(profile.twitterURL), 'newtab');
    }, []);

    const openInstagramURL = useSafeCallback((profile: ProfileEntity | undefined): void => {
      if (!profile || !profile.instagramURL) return;
      window.open(toInstagramURL(profile.instagramURL), 'newtab');
    }, []);

    const openFacebookURL = useSafeCallback((profile: ProfileEntity | undefined): void => {
      if (!profile || !profile.facebookURL) return;
      window.open(toFacebookURL(profile.facebookURL), 'newtab');
    }, []);

    useImperativeHandle(ref, () => ({
      refresh: () => initialize()
    }));

    return (
      <Component className='profile'>
        <HeaderArea>
          <ProfileHeader isMyAccount={isMyAccount} user={userToDisplay} emitAction={emitAction} />
        </HeaderArea>

        <Container>
          <Scrollable>
            <PolaroidWrapper>
              <UserPolaroid
                size='large'
                rotate='none'
                frameId={!!userToDisplay ? userToDisplay.frameId : undefined}
                user={userToDisplay}
              />

              {isMyAccount && (
                <IconWrapper>
                  <SettingIcon src={settingIcon} onClick={() => emitAction(AccountAction.EDIT_PROFILE)} />
                </IconWrapper>
              )}
            </PolaroidWrapper>

            {!!displayingProfile && displayingProfile.userDiv === UserDiv.WORKER && (
              <>
                <BoxWrapper>
                  <BoxFrame
                    title={<Title>法人名</Title>}
                    content={
                      <ContentArea loaded={showCompanyName}>
                        <SingleLineText
                          style={styleForContent}
                          text={!!displayingProfile ? displayingProfile.companyName : undefined}
                        />
                      </ContentArea>
                    }
                  />
                </BoxWrapper>

                <BoxWrapper>
                  <BoxFrame
                    title={<Title>部署名</Title>}
                    content={
                      <ContentArea loaded={showDepartmentName}>
                        <SingleLineText
                          style={styleForContent}
                          text={!!displayingProfile ? displayingProfile.departmentName : undefined}
                        />
                      </ContentArea>
                    }
                  />
                </BoxWrapper>

                <BoxWrapper>
                  <BoxFrame
                    title={<Title>肩書き</Title>}
                    content={
                      <ContentArea loaded={showTitle}>
                        <SingleLineText
                          style={styleForContent}
                          text={!!displayingProfile ? displayingProfile.title : undefined}
                        />
                      </ContentArea>
                    }
                  />
                </BoxWrapper>

                <BoxWrapper>
                  <BoxFrame
                    title={<Title>略歴</Title>}
                    content={
                      <ContentArea loaded={showBeriefHistory}>
                        <MultiLineText
                          style={styleForContent}
                          text={!!displayingProfile ? displayingProfile.beriefHistory : undefined}
                        />
                      </ContentArea>
                    }
                  />
                </BoxWrapper>
              </>
            )}

            {!!displayingProfile && displayingProfile.userDiv === UserDiv.STUDENT && (
              <>
                <BoxWrapper>
                  <BoxFrame
                    title={<Title>大学名</Title>}
                    content={
                      <ContentArea loaded={showCollege}>
                        <SingleLineText
                          style={styleForContent}
                          text={
                            !!displayingProfile && !!displayingProfile.college
                              ? displayingProfile.college.name
                              : undefined
                          }
                        />
                      </ContentArea>
                    }
                  />
                </BoxWrapper>

                <BoxWrapper>
                  <BoxFrame
                    title={<Title>学年</Title>}
                    content={
                      <ContentArea loaded={showCollegeYear}>
                        <SingleLineText
                          style={styleForContent}
                          text={
                            !!displayingProfile && !!displayingProfile.collegeYear
                              ? COLLEGE_YEAR_LABELS[displayingProfile.collegeYear]
                              : undefined
                          }
                        />
                      </ContentArea>
                    }
                  />
                </BoxWrapper>

                <BoxWrapper>
                  <BoxFrame
                    title={<Title>学部 / 研究科</Title>}
                    content={
                      <ContentArea loaded={showFaculty}>
                        <SingleLineText
                          style={styleForContent}
                          text={!!displayingProfile ? displayingProfile.faculty : undefined}
                        />
                      </ContentArea>
                    }
                  />
                </BoxWrapper>

                <BoxWrapper>
                  <BoxFrame
                    title={<Title>学科 / 専攻</Title>}
                    content={
                      <ContentArea loaded={showDepartment}>
                        <SingleLineText
                          style={styleForContent}
                          text={!!displayingProfile ? displayingProfile.department : undefined}
                        />
                      </ContentArea>
                    }
                  />
                </BoxWrapper>

                <BoxWrapper>
                  <BoxFrame
                    title={<Title>専攻 / コース</Title>}
                    content={
                      <ContentArea loaded={showMajor}>
                        <SingleLineText
                          style={styleForContent}
                          text={!!displayingProfile ? displayingProfile.major : undefined}
                        />
                      </ContentArea>
                    }
                  />
                </BoxWrapper>

                <BoxWrapper>
                  <BoxFrame
                    title={<Title>研究テーマ</Title>}
                    content={
                      <ContentArea loaded={showResearchSubject}>
                        <MultiLineText
                          style={styleForContent}
                          text={!!displayingProfile ? displayingProfile.researchSubject : undefined}
                        />
                      </ContentArea>
                    }
                  />
                </BoxWrapper>

                <BoxWrapper>
                  <BoxFrame
                    title={<Title>自己PR</Title>}
                    content={
                      <ContentArea loaded={showSelfIntroduction}>
                        <MultiLineText
                          style={styleForContent}
                          text={!!displayingProfile ? displayingProfile.selfIntroduction : undefined}
                        />
                      </ContentArea>
                    }
                  />
                </BoxWrapper>
              </>
            )}

            {showContacts && (
              <BoxWrapper>
                <BoxFrame
                  title={<Title>連絡先</Title>}
                  content={
                    <IconsArea>
                      {showEmailToContactURL && <SnsIcon src={mail} onClick={() => openMailer(displayingProfile)} />}

                      {showTwitterURL && <SnsIcon src={twitter} onClick={() => openTwitterURL(displayingProfile)} />}

                      {showInstagramURL && (
                        <SnsIcon src={instagram} onClick={() => openInstagramURL(displayingProfile)} />
                      )}

                      {showFacebookURL && <SnsIcon src={facebook} onClick={() => openFacebookURL(displayingProfile)} />}
                    </IconsArea>
                  }
                />
              </BoxWrapper>
            )}

            {/* <BoxWrapper>
              <BoxFrame
                title={<Title>名刺</Title>}
                content={
                  <CardsArea
                    loaded={!!profile && !!profile.cards && !!profile.cards.length}
                  >
                    {!!profile &&
                    !!profile.cards &&
                    profile.cards.map((card: BusinessCard, index: Index) =>
                      <Card
                        key={index}
                        src={card.photoURL}
                      />
                    )}
                  </CardsArea>
                }
              />
            </BoxWrapper> */}

            {!isMyAccount && (
              <BoxWrapper onClick={() => emitAction(AccountAction.OPEN_COMMON_BOARD_LIST)}>
                <BoxFrame
                  title={
                    <TitleArea>
                      <Title>
                        共通のイベント
                        <Count>{showCommonBoards ? displayingProfile!.commonBoardCount : ZERO}枚</Count>
                      </Title>

                      <ArrowIcon fontSize='small' />
                    </TitleArea>
                  }
                  content={
                    <PolaroidsArea loaded={showCommonBoards}>
                      {!!displayingProfile &&
                        !!displayingProfile.commonBoards &&
                        displayingProfile.commonBoards.map((board: BoardEntity, index: Index) => (
                          <CommonPolaroid
                            key={index}
                            frameId={board.frameId}
                            photoURL={board.photoURL}
                            title={board.name}
                          />
                        ))}
                    </PolaroidsArea>
                  }
                />
              </BoxWrapper>
            )}

            {!isMyAccount && (
              <BoxWrapper onClick={() => emitAction(AccountAction.OPEN_COMMON_CONNECTION_LIST)}>
                <BoxFrame
                  title={
                    <TitleArea>
                      <Title>
                        共通の知り合い
                        <Count>{showCommonConnections ? displayingProfile!.commonConnectionCount : ZERO}人</Count>
                      </Title>

                      <ArrowIcon fontSize='small' />
                    </TitleArea>
                  }
                  content={
                    <PolaroidsArea loaded={showCommonConnections}>
                      {!!displayingProfile &&
                        !!displayingProfile.commonConnections &&
                        displayingProfile.commonConnections.map((connection: UserEntity, index: Index) => (
                          <CommonPolaroid
                            key={index}
                            frameId={connection.frameId}
                            photoURL={connection.photoURL}
                            title={toFullName(connection)}
                          />
                        ))}
                    </PolaroidsArea>
                  }
                />
              </BoxWrapper>
            )}

            <BoxWrapper onClick={() => emitAction(AccountAction.OPEN_CONNECTION_LIST)}>
              <BoxFrame
                title={
                  <TitleArea>
                    <Title>
                      知り合い
                      <Count>{showConnections ? displayingProfile!.allConnectionCount : ZERO}人</Count>
                    </Title>

                    <ArrowIcon fontSize='small' />
                  </TitleArea>
                }
                content={
                  <PolaroidsArea loaded={showConnections}>
                    {!!displayingProfile &&
                      displayingProfile.allConnections.map((connection: UserEntity, index: Index) => (
                        <CommonPolaroid
                          key={index}
                          frameId={connection.frameId}
                          photoURL={connection.photoURL}
                          title={toFullName(connection)}
                        />
                      ))}
                  </PolaroidsArea>
                }
              />
            </BoxWrapper>

            <FooterArea />
          </Scrollable>
        </Container>
      </Component>
    );
  }
);

export default Profile;

const HeaderArea = styled.div`
  width: 100%;
  height: ${HEADER_HEIGHT}px;
`;

const Container = styled.div`
  width: 100%;
  height: ${window.innerHeight - HEADER_HEIGHT}px;
`;

const PolaroidWrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  padding: ${theme.mixins.spacing}px;
`;

const IconWrapper = styled.div`
  width: 40px;
  height: 32px;
  position: absolute;
  right: 16px;
  bottom: 28px;
  ${theme.mixins.userSelectDisabled};
`;

const SettingIcon = styled.img`
  width: 100%;
  height: auto;
`;

const BoxWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing / 2}px ${theme.mixins.spacing}px;
`;

const TitleArea = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
`;

const Title = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.black};
  font-size: ${theme.mixins.typography.fontSize.twelve}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  display: flex;
  ${theme.mixins.userSelectDisabled};
`;

const Count = styled.span`
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.twelve}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  padding-left: ${theme.mixins.spacing}px;
`;

const ArrowIcon = styled(ArrowForwardIosIcon)`
  color: ${theme.mixins.typography.fontColor.lightGray};
`;

const ContentArea = styled.div<{ loaded: boolean }>`
  width: 100%;
  height: ${props => (props.loaded ? 'auto' : '72px')};
`;

const styleForContent: CSSProperties = {
  width: '100%',
  height: 'auto',
  color: theme.mixins.typography.fontColor.black,
  fontSize: theme.mixins.typography.fontSize.sixteen,
  fontWeight: theme.mixins.typography.fontWeight.fourHundreds,
  fontFamily: theme.mixins.typography.fontFamily,
  lineHeight: 1.5,
  wordBreak: 'break-all'
};

const IconsArea = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  ${theme.mixins.userSelectDisabled};
`;

const SnsIcon = styled.img`
  width: 48px;
  height: auto;
`;

// const CardsArea = styled.div<{ loaded: boolean }>`
//   width: 100%;
//   height: ${props => props.loaded ? 'auto' : '72px'};
//   display: flex;
//   justify-content: space-around;
//   padding: ${theme.mixins.spacing}px 0px;
// `;

// const Card = styled.img`
//   width: 40%;
//   height: auto;
// `;

const PolaroidsArea = styled.div<{ loaded: boolean }>`
  width: 100%;
  height: ${props => (props.loaded ? 'auto' : '72px')};
  display: flex;
  justify-content: space-around;
  padding: ${theme.mixins.spacing}px 0px;
`;

const FooterArea = styled.div`
  width: 100%;
  height: 240px;
`;
