import { Component, Scrollable, theme } from '@atomica.co/components';
import { BoardEntity } from '@atomica.co/yosemal-v2';
import React from 'react';
import styled from 'styled-components';
import BoardDetailsPolaroid from '../../../../components/polaroid/BoardDetailsPolaroid';
import BoardMemberHeader from '../../board-header/BoardMemberHeader';
import BoardMembers from './BoardMembers';

interface P {
  board: BoardEntity | undefined;
  onClickWriteButton(): void;
}

const JoinBoard: React.FC<P> = React.memo(props => {
  const { board, onClickWriteButton } = props;

  return (
    <Component style={{ width: '100%' }} className='join-board'>
      <BoardMemberHeader onClickWriteButton={onClickWriteButton} />

      <Scrollable showScrollbar>
        <Container>
          <PolaroidWrapper>
            <BoardDetailsPolaroid rotate='none' board={board} />
          </PolaroidWrapper>

          <CardListWrapper>
            <BoardMembers boardId={!!board ? board.boardId : undefined} />
          </CardListWrapper>
        </Container>
      </Scrollable>
    </Component>
  );
});

export default JoinBoard;

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding-top: ${theme.mixins.spacing}px;
`;

const PolaroidWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
`;

const CardListWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
`;
