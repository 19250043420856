import {
  Button,
  Component,
  SelectBox,
  styleForFullExpansion,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { builder } from '@atomica.co/utils';
import { CollegeYear, SaveUserCollegeYearRequest, UserId } from '@atomica.co/yosemal-v2';
import { default as React } from 'react';
import styled from 'styled-components';
import { HEADER_HEIGHT } from '../../../../../constants/common-constants';
import UserRequest from '../../../../../requests/user-request';
import { COLLEGE_YEAR_LABELS } from '../../../../../texts/user-text';
import InputProfileHeader from '../../../account-header/InputProfileHeader';
import { EditProfileIndex } from '../EditProfile';

interface P {
  userId: UserId | undefined;
  collegeYear: CollegeYear | undefined;
  onSave(collegeYear: CollegeYear): void;
  goTo(index: EditProfileIndex): void;
}

const SelectCollegeYear: React.FC<P> = React.memo(props => {
  const { userId, collegeYear: initCollegeYear, onSave, goTo } = props;

  const unmountRef = useUnmountRef();
  const [disabledSaveButton, setDisabledSaveButton] = useSafeState<boolean>(unmountRef, false);
  const [collegeYearToSave, setCollegeYearToSave] = useSafeState<CollegeYear>(unmountRef, initCollegeYear);

  const saveCollegeYear = useSafeCallback(async (): Promise<void> => {
    if (!userId) return;
    setDisabledSaveButton(true);

    const request = builder<SaveUserCollegeYearRequest>().userId(userId).collegeYear(collegeYearToSave).build();
    await UserRequest.saveCollegeYear(request);

    onSave(collegeYearToSave);
  }, [setDisabledSaveButton, userId, collegeYearToSave, onSave]);

  return (
    <Component style={styleForFullExpansion} className='select-college-year'>
      <HeaderArea>
        <InputProfileHeader label='学年' onClick={goTo} />
      </HeaderArea>

      <Body>
        <InputWrapper>
          <SelectBox
            options={Object.values(CollegeYear)}
            labels={COLLEGE_YEAR_LABELS}
            value={collegeYearToSave}
            onChange={setCollegeYearToSave}
          />
        </InputWrapper>

        <ButtonArea>
          <Button type='primary' disabled={disabledSaveButton} onClick={saveCollegeYear}>
            <StyledLabel>保存</StyledLabel>
          </Button>
        </ButtonArea>
      </Body>
    </Component>
  );
});

export default SelectCollegeYear;

const HeaderArea = styled.div`
  width: 100%;
  height: ${HEADER_HEIGHT}px;
`;

const Body = styled.div`
  width: 100%;
  height: calc(100% - 48px);
`;

const InputWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing * 4}px ${theme.mixins.spacing * 2}px;
`;

const ButtonArea = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
`;

const StyledLabel = styled.div`
  width: 80px;
  height: auto;
`;
