import { Component, Scrollable, theme, useSafeCallback } from '@atomica.co/components';
import { BeriefHistory, Email, EMPTY, Name, noop, SelfIntroduction, Subject, URL } from '@atomica.co/utils';
import { CollegeDto, CollegeYear, FrameId, ProfileEntity, UserDiv } from '@atomica.co/yosemal-v2';
import { Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { ArrowForwardButton } from '../../../../../components/buttons/ArrowForwardButton';
import UserPhotoUploader from '../../../../../components/polaroid/UserPhotoUploader';
import { HEADER_HEIGHT } from '../../../../../constants/common-constants';
import { COLLEGE_YEAR_LABELS } from '../../../../../texts/user-text';
import { toFullName } from '../../../../../utils/user-util';
import EditProfileHeader from '../../../account-header/EditProfileHeader';
import { AccountAction } from '../../../AccountScreen';
import { EditProfileIndex } from '../EditProfile';
import addPhoto from './../../../../../assets/icon/icon_photo_add.png';

const ACCOUNT_DELETION_APPLICATION_FORM = 'https://forms.gle/udy8eC1wg93NRyZ27';

interface P {
  userDiv: UserDiv | undefined;
  frameId: FrameId | undefined;
  companyName: Name;
  departmentName: Name;
  title: Name;
  beriefHistory: BeriefHistory;
  college: CollegeDto | undefined;
  collegeYear: CollegeYear | undefined;
  faculty: Name; // FIXME
  department: Name; // FIXME
  major: Name; // FIXME
  researchSubject: Subject;
  selfIntroduction: SelfIntroduction;
  emailToContact: Email;
  twitterURL: URL;
  facebookURL: URL;
  instagramURL: URL;
  profile: ProfileEntity | undefined;
  onUploadPhoto(photoURL: URL): void;
  onClick(index: EditProfileIndex): void;
  emitAction(action: AccountAction.BACK_TO_PROFILE);
}

const EditProfileMain: React.FC<P> = React.memo(props => {
  const {
    frameId,
    userDiv,
    companyName,
    departmentName,
    title,
    beriefHistory,
    college,
    collegeYear,
    faculty,
    department,
    major,
    researchSubject,
    selfIntroduction,
    emailToContact,
    twitterURL,
    facebookURL,
    instagramURL,
    profile,
    onUploadPhoto: onChangePhoto,
    onClick,
    emitAction
  } = props;

  const deleteAccount = useSafeCallback((): void => {
    window.open(ACCOUNT_DELETION_APPLICATION_FORM, '_blank');
  }, []);

  return (
    <Component className='edit-profile-main'>
      <HeaderArea>
        <EditProfileHeader emitAction={emitAction} />
      </HeaderArea>

      <Container>
        <Scrollable>
          <PolaroidWrapper>
            {!!profile && !!frameId && (
              <UserPhotoUploader
                initPhotoURL={profile.photoURL}
                frameId={frameId}
                name={toFullName(profile)}
                dateOfBirth={profile.dateOfBirth}
                onUpload={onChangePhoto}
              />
            )}

            <AddPhotoButton>
              <AddPhotoIcon src={addPhoto} />
            </AddPhotoButton>
          </PolaroidWrapper>

          <LowerWrapper>
            <ArrowForwardButton
              shape='rect'
              text='フレームを選択する'
              onClick={() => onClick(EditProfileIndex.SELECT_USER_FRAME)}
            />
          </LowerWrapper>

          {userDiv === UserDiv.WORKER && (
            <>
              <UpperWrapper>
                <ArrowForwardButton
                  shape='rect'
                  label='法人名'
                  text={companyName}
                  onClick={() => onClick(EditProfileIndex.INPUT_COMPANY_NAME)}
                />
              </UpperWrapper>

              <MiddleWrapper>
                <ArrowForwardButton
                  shape='rect'
                  label='部署名'
                  text={departmentName}
                  onClick={() => onClick(EditProfileIndex.INPUT_DEPARTMENT_NAME)}
                />
              </MiddleWrapper>

              <MiddleWrapper>
                <ArrowForwardButton
                  shape='rect'
                  label='肩書き'
                  text={title}
                  onClick={() => onClick(EditProfileIndex.INPUT_TITLE)}
                />
              </MiddleWrapper>

              <LowerWrapper>
                <ArrowForwardButton
                  shape='rect'
                  label='略歴'
                  text={beriefHistory}
                  onClick={() => onClick(EditProfileIndex.INPUT_BERIEF_HISTORY)}
                />
              </LowerWrapper>
            </>
          )}

          {userDiv === UserDiv.STUDENT && (
            <>
              <UpperWrapper>
                <ArrowForwardButton
                  shape='rect'
                  label='大学名'
                  text={!!college ? college.name : EMPTY}
                  onClick={noop}
                />
              </UpperWrapper>

              <MiddleWrapper>
                <ArrowForwardButton
                  shape='rect'
                  label='学年'
                  text={!!collegeYear ? COLLEGE_YEAR_LABELS[collegeYear] : EMPTY}
                  onClick={() => onClick(EditProfileIndex.SELECT_COLLGE_YEAR)}
                />
              </MiddleWrapper>

              <MiddleWrapper>
                <ArrowForwardButton
                  shape='rect'
                  label='学部 / 研究科'
                  text={faculty}
                  onClick={() => onClick(EditProfileIndex.SELECT_FACULTY)}
                />
              </MiddleWrapper>

              <MiddleWrapper>
                <ArrowForwardButton
                  shape='rect'
                  label='学科 / 専攻'
                  text={department}
                  onClick={() => onClick(EditProfileIndex.SELECT_DEPARTMENT)}
                />
              </MiddleWrapper>

              <MiddleWrapper>
                <ArrowForwardButton
                  shape='rect'
                  label='専攻 / コース'
                  text={major}
                  onClick={() => onClick(EditProfileIndex.SELECT_MAJOR)}
                />
              </MiddleWrapper>

              <MiddleWrapper>
                <ArrowForwardButton
                  shape='rect'
                  label='研究テーマ'
                  text={researchSubject}
                  onClick={() => onClick(EditProfileIndex.INPUT_RESEARCH_SUBJECT)}
                />
              </MiddleWrapper>

              <LowerWrapper>
                <ArrowForwardButton
                  shape='rect'
                  label='自己PR'
                  text={selfIntroduction}
                  onClick={() => onClick(EditProfileIndex.INPUT_SELF_INTRODUCTION)}
                />
              </LowerWrapper>
            </>
          )}

          <UpperWrapper>
            <ArrowForwardButton
              shape='rect'
              label='メールアドレス'
              text={emailToContact}
              onClick={() => onClick(EditProfileIndex.INPUT_EMAIL_TO_CONTACT)}
            />
          </UpperWrapper>

          <MiddleWrapper>
            <ArrowForwardButton
              shape='rect'
              label='Twiiter / @以降'
              text={twitterURL}
              onClick={() => onClick(EditProfileIndex.INPUT_TWITTER_URL)}
            />
          </MiddleWrapper>

          <MiddleWrapper>
            <ArrowForwardButton
              shape='rect'
              label='Facebook / プロフィールのリンク'
              text={facebookURL}
              onClick={() => onClick(EditProfileIndex.INPUT_FACEBOOK_URL)}
            />
          </MiddleWrapper>

          <LowerWrapper>
            <ArrowForwardButton
              shape='rect'
              label='Instagram / プロフィールのリンク'
              text={instagramURL}
              onClick={() => onClick(EditProfileIndex.INPUT_INSTAGRAM_URL)}
            />
          </LowerWrapper>

          <ButtonArea>
            <Button onClick={deleteAccount}>アカウント削除はこちら</Button>
            {/* <Button onClick={emitAction()}>アカウント削除はこちら</Button> */}
          </ButtonArea>

          <FooterArea />
        </Scrollable>
      </Container>
    </Component>
  );
});

export default EditProfileMain;

const HeaderArea = styled.div`
  width: 100%;
  height: ${HEADER_HEIGHT}px;
`;

const Container = styled.div`
  width: 100%;
  height: ${window.innerHeight - HEADER_HEIGHT}px;
`;

const PolaroidWrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing}px ${theme.mixins.spacing / 4}px;
`;

const AddPhotoButton = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background: ${theme.mixins.background.black};
  opacity: 0.6;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 20px;
  bottom: 64px;
`;

const AddPhotoIcon = styled.img`
  width: 40px;
  height: 40px;
`;

const UpperWrapper = styled.div`
  padding: ${theme.mixins.spacing * 2}px ${theme.mixins.spacing}px ${theme.mixins.spacing / 4}px;
`;

const MiddleWrapper = styled.div`
  padding: ${theme.mixins.spacing / 4}px ${theme.mixins.spacing}px;
`;

const LowerWrapper = styled.div`
  padding: ${theme.mixins.spacing / 4}px ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const ButtonArea = styled.div`
  width: 210px;
  height: auto;
  margin-right: 0;
  margin-left: auto;
`;

const Button = styled(Typography)`
  width: 210px;
  height: 20px;
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  display: flex;
  align-items: center;
  justify-content: center;
`;
const FooterArea = styled.div`
  width: 100%;
  height: 240px;
`;
