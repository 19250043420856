import { EMPTY, URL } from '@atomica.co/utils';
import { CashedURLActionEnum } from '../../enums/action-enum';
import { CashedURLAction } from '../../models/action-model';

const cashedURLReducer = (cashedURL: URL, action: CashedURLAction): URL => {
  if (cashedURL === undefined) return EMPTY;

  switch (action.type) {
    case CashedURLActionEnum.SET:
      return action.cashedURL;

    case CashedURLActionEnum.PURGE:
      return EMPTY;

    default:
      return cashedURL;
  }
};

export default cashedURLReducer;
