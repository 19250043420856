import { convert, RequestType } from '@atomica.co/utils';
import {
  FetchBoardMessageByUserIdRequest,
  FetchBoardMessageByUserIdResponse,
  FetchBoardMessageRequest,
  FetchBoardMessageResponse,
  FetchBoardMessagesRequest,
  FetchBoardMessagesResponse,
  FETCH_BOARD_MESSAGE,
  FETCH_BOARD_MESSAGES,
  FETCH_BOARD_MESSAGE_BY_USER_ID,
  SaveBoardMessageRequest,
  SaveBoardMessageResponse,
  SAVE_BOARD_MESSAGE,
  toFuncName
} from '@atomica.co/yosemal-v2';
import { functions } from '../firebase';

export default class BoardMessageRequest {
  public static saveNewMessage = async (request: SaveBoardMessageRequest): Promise<SaveBoardMessageResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_BOARD_MESSAGE));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static fetchMessage = async (request: FetchBoardMessageRequest): Promise<FetchBoardMessageResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(FETCH_BOARD_MESSAGE));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static fetchMessageByUserId = async (
    request: FetchBoardMessageByUserIdRequest
  ): Promise<FetchBoardMessageByUserIdResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(FETCH_BOARD_MESSAGE_BY_USER_ID));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static fetchMessages = async (request: FetchBoardMessagesRequest): Promise<FetchBoardMessagesResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(FETCH_BOARD_MESSAGES));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };
}
