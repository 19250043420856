import { TransitionGroup, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { embedIdInPath, EMPTY, URL } from '@atomica.co/utils';
import {
  BoardEntity,
  BoardId,
  BoardMemberRole,
  BoardMemberStatus,
  BoardMessageId,
  CONFIRMED,
  toNewMessageNotificationsPath,
  UserEntity
} from '@atomica.co/yosemal-v2';
import React, { useEffect, useRef } from 'react';
import Screen from '../../components/screen/Screen';
import { toBoardIndex } from '../../converters/path-converter';
import { analytics, database } from '../../firebase';
import usePath from '../../redux/hooks/usePath';
import useUser from '../../redux/hooks/useUser';
import { Path, PATH_IDS } from '../../router/Routes';
import BoardDetails, { BoardDetailsRef } from './board-details/BoardDetails';
import { BoardMessageListRef } from './board-details/parts/BoardMessageList';
import { BoardThreadRef } from './board-details/parts/BoardThreadList';
import BoardFixedModal from './board-fixed-modal/BoardFixedModal';
import BoardFooterInput from './board-footer-input/BoardFooterInput';
import BoardFullModal from './board-full-modal/BoardFullModal';
import BoardHeader from './board-header/BoardHeader';
import BoardList, { BoardListRef } from './board-list/BoardList';
import BoardMember from './board-member/BoardMember';
import BoardMessage, { BoardMessageRef } from './board-message/BoardMessage';
import BoardPartialModal from './board-partial-modal/BoardPartialModal';
import BoardThread from './board-thread/BoardThread';

export enum BoardIndex {
  BOARD_LIST,
  BOARD_DETAILS,
  BOARD_MEMBER,
  BOARD_MESSAGE,
  BOARD_REPLY,
  OUT_OF_TARGET
}

export enum BoardAction {
  CREATE_BOARD = 'create_board',
  OPEN_BOARD_DETAILS = 'open_board',
  OPEN_BOARD_MESSAGE = 'open_board_message',
  INPUT_BOARD_MESSAGE = 'input_board_message',
  BOARD_MESSAGE_WRITTEN = 'board_message_written',
  SHOW_BOARD_DESCRIPTIONS = 'show_board_descriptions',
  SHOW_BOARD_SETTINGS = 'show_board_settings',
  OPEN_WISH = 'open_wish',
  INVITE_NEW_BOARD_MEMBERS = 'invite_new_board_members',
  SHOW_BOARD_INVITATION_QR = 'show_board_invitation_qr',
  REFRESH_BOARD_LIST = 'refresh_board_list',
  REFRESH_BOARD_DETAILS = 'refresh_board_details',
  REFRESH_BOARD_THREADS = 'refresh_board_threads'
}

interface P {}

const HomeScreen: React.FC<P> = React.memo(() => {
  const { getUser } = useUser();
  const { path, params, openPath } = usePath();
  const boardListRef = useRef<BoardListRef>(null);
  const boardDetailsRef = useRef<BoardDetailsRef>(null);
  const boardMessageListRef = useRef<BoardMessageListRef>(null);
  const boardMessageRef = useRef<BoardMessageRef>(null);
  const boardThreadRef = useRef<BoardThreadRef>(null);
  const unmountRef = useUnmountRef();
  const [isModalOpen, setIsModalOpen] = useSafeState<boolean>(unmountRef, false);
  const [isFullModalOpen, setIsFullModalOpen] = useSafeState<boolean>(unmountRef, false);
  const [isPartialModalOpen, setIsPartialModalOpen] = useSafeState<boolean>(unmountRef, false);
  const [index, setIndex] = useSafeState<BoardIndex>(unmountRef, toBoardIndex(path));
  const [action, setAction] = useSafeState<BoardAction>(unmountRef, EMPTY as BoardAction);
  const [user, setUser] = useSafeState<UserEntity | undefined>(unmountRef);
  const [clickedBoardId, setClickedBoardId] = useSafeState<BoardId | undefined>(unmountRef, params.boardId);
  const [clickedBoardMessageId, setClickedBoardMessageId] = useSafeState<BoardMessageId | undefined>(
    unmountRef,
    params.boardMessageId
  );
  const [clickedPhotoURL, setClickedPhotoURL] = useSafeState<URL>(unmountRef, EMPTY);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const user = await getUser();
    setUser(user);
  }, [getUser, setUser]);

  useEffect(() => {
    initialize();
    analytics.setCurrentScreen('ホーム画面');
  }, [initialize]);

  useEffect(() => {
    setIndex(toBoardIndex(path));
    setClickedBoardId(params.boardId);
    setClickedBoardMessageId(params.boardMessageId);
  }, [setIndex, path, setClickedBoardId, setClickedBoardMessageId, params]);

  const openBoardDetailsScreen = useSafeCallback(
    (boardId: BoardId): void => {
      openPath(embedIdInPath(Path.BOARD_DETAILS, PATH_IDS, [boardId]));
      setClickedBoardId(boardId);
      !!user && database.ref(toNewMessageNotificationsPath(user.userId, boardId)).set(CONFIRMED);
    },
    [openPath, setClickedBoardId, user]
  );

  const openBoardMemberScreen = useSafeCallback(
    (boardId: BoardId): void => {
      openPath(embedIdInPath(Path.BOARD_MEMBER, PATH_IDS, [boardId]));
      setClickedBoardId(boardId);
    },
    [openPath, setClickedBoardId]
  );

  const openBoardMessageScreen = useSafeCallback(
    (boardId: BoardId): void => {
      openPath(embedIdInPath(Path.BOARD_MESSAGE, PATH_IDS, [boardId]));
      setClickedBoardId(boardId);
    },
    [openPath, setClickedBoardId]
  );

  const openBoardReplyScreen = useSafeCallback(
    (boardId: BoardId, boardMessageId: BoardMessageId): void => {
      openPath(embedIdInPath(Path.BOARD_THREAD, PATH_IDS, [boardId, boardMessageId]));
      setClickedBoardId(boardId);
      setClickedBoardMessageId(boardMessageId);
    },
    [openPath, setClickedBoardId, setClickedBoardMessageId]
  );

  const openBoardFullModalScreen = useSafeCallback(
    (action: BoardAction): void => {
      setAction(action);
      setIsFullModalOpen(true);
    },
    [setAction, setIsFullModalOpen]
  );

  const openBoardPartialModalScreen = useSafeCallback(
    (action: BoardAction): void => {
      setAction(action);
      setIsPartialModalOpen(true);
    },
    [setAction, setIsPartialModalOpen]
  );

  const openModalScreen = useSafeCallback(
    (photoURL: URL): void => {
      setClickedPhotoURL(photoURL);
      setIsModalOpen(true);
    },
    [setClickedPhotoURL, setIsModalOpen]
  );

  const handleActionEmitted = useSafeCallback(
    (action: BoardAction, id?: BoardId): void => {
      switch (action) {
        case BoardAction.OPEN_WISH:
          return;

        case BoardAction.OPEN_BOARD_DETAILS:
          !!id && openBoardDetailsScreen(id);
          return;

        case BoardAction.OPEN_BOARD_MESSAGE:
          !!id && openBoardMessageScreen(id);
          return;

        case BoardAction.CREATE_BOARD:
        case BoardAction.SHOW_BOARD_SETTINGS:
          openBoardFullModalScreen(action);
          return;

        case BoardAction.INVITE_NEW_BOARD_MEMBERS:
        case BoardAction.SHOW_BOARD_INVITATION_QR:
          openBoardPartialModalScreen(action);
          return;

        case BoardAction.REFRESH_BOARD_LIST:
          !!boardListRef.current && boardListRef.current.refresh();
          return;

        case BoardAction.REFRESH_BOARD_DETAILS:
          !!boardMessageRef.current && boardMessageRef.current.refresh();
          !!boardDetailsRef.current && boardDetailsRef.current.refresh();
          return;

        case BoardAction.REFRESH_BOARD_THREADS:
          !!boardThreadRef.current && boardThreadRef.current.refresh();
          return;
      }
    },
    [openBoardDetailsScreen, openBoardMessageScreen, openBoardFullModalScreen, openBoardPartialModalScreen]
  );

  const handleBoardClicked = useSafeCallback(
    (board: BoardEntity) => {
      if (board.status === BoardMemberStatus.JOINED) {
        openBoardDetailsScreen(board.boardId);
        return;
      }

      switch (board.role) {
        case BoardMemberRole.OWNER:
        case BoardMemberRole.ADMIN:
          openBoardMemberScreen(board.boardId);
          break;
        case BoardMemberRole.MEMBER:
          openBoardMessageScreen(board.boardId);
          break;
      }
    },
    [openBoardDetailsScreen, openBoardMemberScreen, openBoardMessageScreen]
  );

  return (
    <Screen className='home-screen'>
      <BoardHeader boardId={clickedBoardId} emitAction={handleActionEmitted} />

      <TransitionGroup
        index={index}
        components={[
          <BoardList
            ref={boardListRef}
            user={user}
            onClickBoard={handleBoardClicked}
            emitAction={handleActionEmitted}
          />,
          <BoardDetails
            ref={boardDetailsRef}
            messageListRef={boardMessageListRef}
            boardId={clickedBoardId}
            user={user}
            onClick={openModalScreen}
            onReply={openBoardReplyScreen}
            emitAction={handleActionEmitted}
          />,
          <BoardMember boardId={clickedBoardId} user={user} />,
          <BoardMessage ref={boardMessageRef} boardId={clickedBoardId} user={user} />,
          <BoardThread ref={boardThreadRef} boardMessageId={clickedBoardMessageId} />
        ]}
      />

      {!!user && (
        <BoardFullModal
          isModalOpen={isFullModalOpen}
          action={action}
          userId={user.userId}
          boardId={clickedBoardId}
          emitAction={handleActionEmitted}
          onClose={() => setIsFullModalOpen(false)}
        />
      )}

      {!!user && (
        <BoardPartialModal
          isModalOpen={isPartialModalOpen}
          action={action}
          boardId={clickedBoardId}
          userId={user.userId}
          onClose={() => setIsPartialModalOpen(false)}
        />
      )}

      {!!user && (
        <BoardFixedModal isModalOpen={isModalOpen} photoURL={clickedPhotoURL} onClose={() => setIsModalOpen(false)} />
      )}

      {!!user && index === BoardIndex.BOARD_REPLY && (
        <BoardFooterInput
          boardMessageId={clickedBoardMessageId}
          userId={user.userId}
          emitAction={handleActionEmitted}
        />
      )}
    </Screen>
  );
});

export default HomeScreen;
