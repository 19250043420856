import {Component, theme} from '@atomica.co/components';
import {Email as EmailVo} from '@atomica.co/utils';
import {Typography} from '@material-ui/core';
import MailOutline from '@material-ui/icons/MailOutline';
import React from 'react';
import styled from 'styled-components';

export const CARD_HEIGHT = 72;

interface P {
  email: EmailVo;
}

const EmailCard: React.FC<P> = React.memo(props => {
  const { email } = props;

  return (
    <Component className='email-card'>
      <Box>
        <PhotoArea>
          <MailOutline />
        </PhotoArea>

        <EmailArea>{email}</EmailArea>
      </Box>
    </Component>
  );
});

export default EmailCard;

const Box = styled.div`
  width: 100%;
  height: auto;
  background: ${theme.mixins.background.white};
  border: 1px solid ${theme.mixins.border.lightGray};
  border-radius: 16px;
  display: flex;
`;

const PhotoArea = styled.div`
  width: ${CARD_HEIGHT}px;
  min-width: ${CARD_HEIGHT}px;
  height: ${(CARD_HEIGHT * 2) / 3}px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px 0px 0px 16px;
  background: ${theme.mixins.background.lightGray};
`;

const EmailArea = styled(Typography)`
  width: 100%;
  height: ${(CARD_HEIGHT * 2) / 3}px;
  display: flex;
  align-items: center;
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  padding: 8px;
  ${theme.mixins.textAbbreviated};
`;
