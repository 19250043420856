import { ProcessActionEnum } from '../../enums/action-enum';
import { Process } from '../../enums/common-enum';
import { ProcessAction } from '../../models/action-model';

export const toProcessAction = (process: Process): ProcessAction => {
  return {
    type: ProcessActionEnum.SET,
    process
  };
};
