import { CheckButton, Component, theme } from '@atomica.co/components';
import { noop } from '@atomica.co/utils';
import { UserEntity } from '@atomica.co/yosemal-v2';
import React from 'react';
import styled from 'styled-components';
import UserCard, { CARD_HEIGHT } from './UserCard';

interface P {
  selected: boolean;
  user: UserEntity;
  onClick(user: UserEntity): void;
}

const SelectableUserCard: React.FC<P> = React.memo(props => {
  const { selected, user, onClick } = props;

  return (
    <Component className='selectable-user-card'>
      <Container onClick={() => onClick(user)}>
        <CardWrapper>
          <UserCard user={user} />
        </CardWrapper>

        <ButtonWrapper>
          <CheckButton size='xsmall' checked={selected} onClick={noop} />
        </ButtonWrapper>
      </Container>
    </Component>
  );
});

export default SelectableUserCard;

const Container = styled.div`
  width: 100%;
  height: ${CARD_HEIGHT}px;
  position: relative;
`;

const CardWrapper = styled.div`
  width: 100%;
  height: auto;
`;

const ButtonWrapper = styled.div`
  width: 48px;
  min-width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: ${(CARD_HEIGHT - 48) / 2}px;
  right: 1px;
  background: ${theme.mixins.background.white};
`;
