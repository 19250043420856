import {
  Button,
  Component,
  Icon,
  MainTab,
  styleForFullExpansion,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { BoardId, UserId } from '@atomica.co/yosemal-v2';
import React, { useRef } from 'react';
import styled from 'styled-components';
import close from './../../../../assets/icon/icon_close.png';
import send from './../../../../assets/icon/icon_send.png';
import { InputEmailsRef } from './InputEmailsToInvite';
import SelectConnectionsToInvite, { SelectUsersRef } from './SelectConnectionsToInvite';

export enum InviteBoardMembersTabEnum {
  CONNECTION,
  EMAIL
}

const TABS: MainTab[] = [
  {
    id: InviteBoardMembersTabEnum.CONNECTION,
    label: '知り合い'
  },
  {
    id: InviteBoardMembersTabEnum.EMAIL,
    label: 'メール'
  }
];

const toDefaultTab = (): MainTab => {
  return TABS.find(tab => tab.id === InviteBoardMembersTabEnum.CONNECTION)!;
};

interface P {
  boardId: BoardId | undefined;
  userId: UserId;
  onClose(): void;
}

const InviteNewMembers: React.FC<P> = React.memo(props => {
  const { boardId, userId, onClose } = props;
  const selectUsersRef = useRef<SelectUsersRef>(null);
  const inputEmailsRef = useRef<InputEmailsRef>(null);
  const unmountRef = useUnmountRef();
  const [disabledSendButton, setDisabledSendButton] = useSafeState<boolean>(unmountRef, false);
  const [selectedTab, setSelectedTab] = useSafeState<MainTab>(unmountRef, toDefaultTab());

  const setInitialValues = useSafeCallback((): void => {
    setDisabledSendButton(false);
    setSelectedTab(toDefaultTab());
  }, [setDisabledSendButton, setSelectedTab]);

  const handleModalClosed = useSafeCallback((): void => {
    setInitialValues();

    !!selectUsersRef.current && selectUsersRef.current.clearSelectedConnections();
    !!inputEmailsRef.current && inputEmailsRef.current.clearEmails();

    onClose();
  }, [setInitialValues, onClose]);

  const sendBoardInvitations = useSafeCallback(async (): Promise<void> => {
    setDisabledSendButton(true);

    !!selectUsersRef.current && (await selectUsersRef.current.sendInvitations());
    !!inputEmailsRef.current && (await inputEmailsRef.current.sendInvitations());

    setDisabledSendButton(false);
    handleModalClosed();
  }, [setDisabledSendButton, handleModalClosed]);

  return (
    <Component className='invite-new-members' style={styleForFullExpansion}>
      <Header>
        <ButtonArea>
          <Button onClick={handleModalClosed}>
            <Icon size='small' src={close} />
          </Button>
        </ButtonArea>

        {/* <TabArea>
          <MainTabs selected={selectedTab} tabs={TABS} onChange={setSelectedTab} />
        </TabArea> */}

        <ButtonArea>
          <Button
            onClick={() => {
              if (disabledSendButton) return;
              sendBoardInvitations();
            }}
          >
            <Icon size='small' src={send} />
          </Button>
        </ButtonArea>
      </Header>

      <Body>
        {selectedTab.id === InviteBoardMembersTabEnum.CONNECTION && (
          <SelectConnectionsToInvite ref={selectUsersRef} boardId={boardId} userId={userId} />
        )}

        {/* {selectedTab.id === InviteBoardMembersTabEnum.EMAIL && (
          <InputEmailsToInvite ref={inputEmailsRef} boardId={boardId} userId={userId} />
        )} */}
      </Body>
    </Component>
  );
});

export default InviteNewMembers;

const Header = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.mixins.spacing}px;
`;

const ButtonArea = styled.div`
  width: 64px;
  height: 40px;
`;

// const TabArea = styled.div`
//   width: 240px;
//   height: 40px;
// `;

const Body = styled.div`
  width: 100%;
  height: calc(100% - 56px);
  padding: ${theme.mixins.spacing}px;
`;
