import { isEven } from '@atomica.co/utils';
import { BoardMessagePdfEntity } from '@atomica.co/yosemal-v2';
import { StyleSheet, View } from '@react-pdf/renderer';
import React, { useMemo } from 'react';
import { Styles } from '../BoardPagesPdf';
import { PageSide } from '../pages/BoardMessagesPage';
import BoardMessage, { BOARD_MESSAGE_HEIGHT_RATIO } from './BoardMessage';

const BOARD_MESSAGE_WIDTH = 280;

const BOARD_MESSAGE_HEIGHT = BOARD_MESSAGE_WIDTH * BOARD_MESSAGE_HEIGHT_RATIO;

const FONT_SIZE = 0.52;

const getStyles = (page: PageSide): Styles =>
  StyleSheet.create({
    container: {
      width: '100vw',
      height: '100vh'
    },
    message1: {
      position: 'absolute',
      top: 56,
      left: page === 'left' ? 304 : 16
    },
    message2: {
      position: 'absolute',
      top: 248,
      left: page === 'left' ? 304 : 16
    },
    message3: {
      position: 'absolute',
      top: 440,
      left: page === 'left' ? 304 : 16
    },
    message4: {
      position: 'absolute',
      top: 632,
      left: page === 'left' ? 304 : 16
    },
    message5: {
      position: 'absolute',
      top: 152,
      left: page === 'left' ? 16 : 304
    },
    message6: {
      position: 'absolute',
      top: 344,
      left: page === 'left' ? 16 : 304
    },
    message7: {
      position: 'absolute',
      top: 536,
      left: page === 'left' ? 16 : 304
    },
    boardMessageArea: {
      width: BOARD_MESSAGE_WIDTH,
      height: BOARD_MESSAGE_HEIGHT
    }
  });

interface P {
  side: PageSide;
  messages: BoardMessagePdfEntity[];
}

const BoardMessagesSeven: React.FC<P> = React.memo(props => {
  const { side, messages } = props;
  const styles = useMemo<Styles>(() => getStyles(side), [side]);

  return (
    <View style={styles.container}>
      {messages.map((message, index) => (
        <View key={index} style={styles[`message${index + 1}`]}>
          <View style={styles.boardMessageArea}>
            <BoardMessage
              size={FONT_SIZE}
              tilt={isEven(index) ? 'left' : 'right'}
              position={isEven(index) ? 'left' : 'right'}
              message={message}
            />
          </View>
        </View>
      ))}
    </View>
  );
});

export default BoardMessagesSeven;
