import { Component, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { builder, embedIdInPath } from '@atomica.co/utils';
import {
  BoardEntity,
  BoardId,
  FetchBoardMemberByUserIdRequest,
  FetchBoardRequest,
  SaveNewBoardMemberRequest,
  UserEntity
} from '@atomica.co/yosemal-v2';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { HEADER_HEIGHT, ROLE } from '../../../constants/common-constants';
import { toRole } from '../../../converters/board-member-converter';
import useCashedURL from '../../../redux/hooks/useCashedURL';
import usePath from '../../../redux/hooks/usePath';
import useUser from '../../../redux/hooks/useUser';
import BoardMemberRequest from '../../../requests/board-member-request';
import BoardRequest from '../../../requests/board-request';
import { Path, PATH_IDS } from '../../../router/Routes';
import JoinBoard from './parts/JoinBoard';

interface P {
  boardId: BoardId | undefined;
  user: UserEntity | undefined;
}

const BoardMember: React.FC<P> = React.memo(props => {
  const { boardId, user } = props;
  const { getUser } = useUser();
  const { saveCurrentURL } = useCashedURL();
  const { path, queryParams, openPath } = usePath();
  const { getFirebase } = useUser();
  const isOpen = useMemo(() => path === Path.BOARD_MEMBER, [path]);
  const role = useMemo(() => toRole(queryParams[ROLE]), [queryParams]);
  const unmountRef = useUnmountRef();
  const [board, setBoard] = useSafeState<BoardEntity | undefined>(unmountRef);

  const isMemberExisted = useSafeCallback(
    async (user: UserEntity | undefined): Promise<boolean> => {
      if (!boardId || !user) return false;

      const request = builder<FetchBoardMemberByUserIdRequest>().boardId(boardId).userId(user.userId).build();
      const response = await BoardMemberRequest.fetchMemberByUserId(request);
      return !!response.member;
    },
    [boardId]
  );

  const loadBoard = useSafeCallback(async (): Promise<void> => {
    if (!boardId) return;
    const request = builder<FetchBoardRequest>().boardId(boardId).build();
    const response = await BoardRequest.fetchBoard(request);
    setBoard(response.board);
  }, [boardId, setBoard]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    if (!isOpen) return;

    const user = await getUser();
    const hasJoined = await isMemberExisted(user);

    if (hasJoined) {
      openPath(embedIdInPath(Path.BOARD_DETAILS, PATH_IDS, [boardId!]));
      return;
    }

    loadBoard();
  }, [isOpen, getUser, isMemberExisted, openPath, boardId, loadBoard]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const saveNewMember = useSafeCallback(async (): Promise<void> => {
    if (!board || !user) return;
    const request = builder<SaveNewBoardMemberRequest>().boardId(board.boardId).userId(user.userId).role(role).build();
    await BoardMemberRequest.saveNewMember(request);
  }, [board, user, role]);

  const handleWriteButtonClicked = useSafeCallback(async (): Promise<void> => {
    if (!board) return;
    const firebase = await getFirebase();

    if (!firebase) {
      saveCurrentURL();
      openPath(Path.SIGN_UP);
      return;
    }

    await saveNewMember();
    openPath(embedIdInPath(Path.BOARD_DETAILS, PATH_IDS, [board.boardId]));
  }, [getFirebase, saveCurrentURL, openPath, saveNewMember, board]);

  return (
    <Component className='board-member'>
      <Container>
        <JoinBoard board={board} onClickWriteButton={handleWriteButtonClicked} />
      </Container>
    </Component>
  );
});

export default BoardMember;

const Container = styled.div`
  width: 100%;
  height: calc(100vh - ${HEADER_HEIGHT}px);
  display: flex;
  margin-top: ${HEADER_HEIGHT}px;
`;
