import { createStore, Store } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import { PersistConfig } from 'redux-persist/es/types';
import storage from 'redux-persist/lib/storage';
import rootReducer from '../reducers/index';

const persistConfig: PersistConfig<any> = {
  key: 'yosemal',
  storage
  //   whitelist: [],
  //   blacklist: [],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store: Store = createStore(persistedReducer);

export const persistor = persistStore(store);

export default store;
