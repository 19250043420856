import { EnvDef, uuid } from '@atomica.co/utils';
import { AxiosRequestConfig } from 'axios';
import querystring from 'querystring';

export const YOSEMAL_CLIENT_ID = 1656282585;

export const YOSEMAL_CLIENT_SECRET = 'f8f2138f8e47eb250a13131331eb0055';

export const NONCE = '09876xyz';

export const LINE_AUTHORIZE_URL = 'https://access.line.me/oauth2/v2.1/authorize';

export const LINE_AUTHORIZE_PARAMS: querystring.ParsedUrlQueryInput = {
  response_type: 'code',
  client_id: YOSEMAL_CLIENT_ID,
  state: uuid(),
  scope: 'profile openid email',
  nonce: NONCE,
  prompt: 'consent',
  max_age: 120,
  bot_prompt: 'normal'
};

export const LINE_TOKEN_URL = 'https://api.line.me/oauth2/v2.1/token';

export const LINE_TOKEN_CONFIG: AxiosRequestConfig = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
};

export const DEVELOPMENT_LIFF_ID = '1656282585-1RKRm3lR';

export const STAGING_LIFF_ID = '1656282585-oMX6vy86';

export const PRODUCTION_LIFF_ID = '1656282585-X7Aw0Kpw';

export const LIFF_IDS = {
  [EnvDef.LOCAL]: DEVELOPMENT_LIFF_ID,
  [EnvDef.DEVELOPMENT]: DEVELOPMENT_LIFF_ID,
  [EnvDef.STAGING]: STAGING_LIFF_ID,
  [EnvDef.PRODUCTION]: PRODUCTION_LIFF_ID
};

const DEVELOPMENT_LIFF_URL = `line://app/${DEVELOPMENT_LIFF_ID}`;
// const DEVELOPMENT_LIFF_URL = `https://liff.line.me/${DEVELOPMENT_LIFF_ID}`;

const STAGING_LIFF_URL = `line://app/${STAGING_LIFF_ID}`;
// const STAGING_LIFF_URL = `https://liff.line.me/${STAGING_LIFF_ID}`;

const PRODUCTION_LIFF_URL = `line://app/${PRODUCTION_LIFF_ID}`;
// const PRODUCTION_LIFF_URL = `https://liff.line.me/${PRODUCTION_LIFF_ID}`;

export const LIFF_URLS = {
  [EnvDef.LOCAL]: DEVELOPMENT_LIFF_URL,
  [EnvDef.DEVELOPMENT]: DEVELOPMENT_LIFF_URL,
  [EnvDef.STAGING]: STAGING_LIFF_URL,
  [EnvDef.PRODUCTION]: PRODUCTION_LIFF_URL
};
