import { Component, Icon, theme } from '@atomica.co/components';
import { UserEntity } from '@atomica.co/yosemal-v2';
import { Typography } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import Close from '@material-ui/icons/Close';
import React from 'react';
import styled from 'styled-components';
import Header from '../../../components/header/Header';
import board from './../../../assets/icon/icon_board_add.png';
import trash from './../../../assets/icon/icon_trash.png';

interface P {
  selected: UserEntity[];
  onClickCloseButton(): void;
  onClickCreateButton(): void;
  onClickDeleteButton(): void;
}

const SelectableConnectionHeader: React.FC<P> = React.memo(props => {
  const { selected, onClickCloseButton, onClickCreateButton, onClickDeleteButton } = props;

  return (
    <Component className='selectable-connection-header'>
      <Header
        leftChild={
          <LeftArea>
            <Button onClick={onClickCloseButton}>
              <CloseIcon />
            </Button>

            <Count>{selected.length}人</Count>
          </LeftArea>
        }
        rightChild={
          <RightArea>
            <Button onClick={onClickCreateButton}>
              <Icon size='xsmall' style={styleForIcon} src={board} />
            </Button>

            <Button onClick={onClickDeleteButton}>
              <Icon size='xsmall' style={styleForIcon} src={trash} />
            </Button>
          </RightArea>
        }
      />
    </Component>
  );
});

export default SelectableConnectionHeader;

const LeftArea = styled.div`
  display: flex;
  align-items: center;
  padding-left: ${theme.mixins.spacing}px;
`;

const Button = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CloseIcon = styled(Close)`
  color: ${theme.mixins.typography.fontColor.lightGray};
`;

const Count = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.lightGray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
`;

const RightArea = styled.div`
  display: flex;
  align-items: center;
  padding-right: ${theme.mixins.spacing}px;
`;

const styleForIcon: CSSProperties = {
  color: theme.mixins.typography.fontColor.lightGray
};
