import { Button, Component, Scrollable, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { builder } from '@atomica.co/utils';
import { FrameId, SaveUserFrameIdRequest, UserEntity } from '@atomica.co/yosemal-v2';
import { default as React } from 'react';
import styled from 'styled-components';
import UserFrameSelecter from '../../../../../components/polaroid/UserFrameSelecter';
import { HEADER_HEIGHT } from '../../../../../constants/common-constants';
import UserRequest from '../../../../../requests/user-request';
import InputProfileHeader from '../../../account-header/InputProfileHeader';
import { EditProfileIndex } from '../EditProfile';

interface P {
  frameId: FrameId | undefined;
  user: UserEntity | undefined;
  onSave(frameId: FrameId): void;
  goTo(index: EditProfileIndex): void;
}

const SelectUserFrame: React.FC<P> = React.memo(props => {
  const { frameId: initFrameId, user, onSave, goTo } = props;

  const unmountRef = useUnmountRef();
  const [disabledSaveButton, setDisabledSaveButton] = useSafeState<boolean>(unmountRef, false);
  const [frameIdToSave, setFrameIdToSave] = useSafeState<FrameId>(unmountRef, initFrameId);

  const saveFrameId = useSafeCallback(async (): Promise<void> => {
    if (!user) return;
    setDisabledSaveButton(true);

    const request = builder<SaveUserFrameIdRequest>().userId(user.userId).frameId(frameIdToSave).build();

    await UserRequest.saveUserFrameId(request);
    onSave(frameIdToSave);
  }, [user, setDisabledSaveButton, frameIdToSave, onSave]);

  return (
    <Component className='select-user-frame'>
      <HeaderArea>
        <InputProfileHeader label='フレームを選ぶ' onClick={goTo} />
      </HeaderArea>

      <Body>
        <Scrollable>
          <UserFrameSelecter user={user} onChange={setFrameIdToSave} />

          <ButtonArea>
            <Button type='primary' disabled={disabledSaveButton} onClick={saveFrameId}>
              <Label>保存</Label>
            </Button>
          </ButtonArea>
        </Scrollable>
      </Body>
    </Component>
  );
});

export default SelectUserFrame;

const HeaderArea = styled.div`
  width: 100%;
  height: ${HEADER_HEIGHT}px;
`;

const Body = styled.div`
  width: 100%;
  height: ${window.innerHeight - HEADER_HEIGHT}px;
`;

const ButtonArea = styled.div`
  width: 100%;
  height: auto;
  position: fixed;
  left: 0px;
  bottom: 16px;
  display: flex;
  justify-content: center;
`;

const Label = styled.div`
  width: 80px;
  height: auto;
`;
