import { Component, SubTab, SubTabs, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import React from 'react';
import styled from 'styled-components';

export enum ShareTabEnum {
  INVITE_ADMIN,
  INVITE_MEMBER
}

const tabs: SubTab[] = [
  {
    id: ShareTabEnum.INVITE_MEMBER,
    label: 'イベントの参加者'
  },
  {
    id: ShareTabEnum.INVITE_ADMIN,
    label: 'イベントの管理者'
  }
];

interface P {
  initTab: ShareTabEnum;
  onChange(tab: ShareTabEnum): void;
}

const ShareTabs: React.FC<P> = React.memo(props => {
  const { initTab, onChange } = props;
  const unmountRef = useUnmountRef();
  const [selected, setSelected] = useSafeState<SubTab>(
    unmountRef,
    tabs.find(tab => tab.id === initTab)
  );

  const handleTabChanged = useSafeCallback(
    (tab: SubTab): void => {
      setSelected(tab);
      onChange(tab.id);
    },
    [setSelected, onChange]
  );

  return (
    <Component style={{ width: '100%' }} className='share-tabs'>
      <Contanier>
        <Content>
          <SubTabs selected={selected} tabs={tabs} onChange={handleTabChanged} />
        </Content>
      </Contanier>
    </Component>
  );
});

export default ShareTabs;

const Contanier = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 288px;
  height: 54px;
`;
