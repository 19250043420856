import {
  Button,
  Component,
  DateBox,
  Scrollable,
  styleForFullExpansion,
  theme,
  ToggleBox
} from '@atomica.co/components';
import { Description, Email, Name, Request, URL } from '@atomica.co/utils';
import { FrameId, UserEntity } from '@atomica.co/yosemal-v2';
import { Typography } from '@material-ui/core';
import Icon from '@material-ui/icons/Close';
import { default as React, useMemo } from 'react';
import styled from 'styled-components';
import { ArrowForwardButton } from '../../../../../components/buttons/ArrowForwardButton';
import BoardPhotoUploader from '../../../../../components/polaroid/BoardPhotoUploader';
import { CreateBoardIndex } from '../CreateBoard';

const TITLE = 'イベントボードの作成';

interface P {
  name: Name;
  description: Description | undefined;
  photoURL: URL | undefined;
  frameId: FrameId | undefined;
  isRequiredNewPhoto: boolean;
  requestForNewPhoto: Request | undefined;
  dueDate: Date;
  enableReminderTwentyFourHoursBefore: boolean;
  enableReminderOneWeekBefore: boolean;
  allowToInviteByMembers: boolean;
  adminUsersToInvite: UserEntity[];
  adminEmailsToInvite: Email[];
  memberUsersToInvite: UserEntity[];
  memberEmailsToInvite: Email[];
  onChangePhoto(photoURL: URL): void;
  onChangeIsRequiredNewPhoto(isRequiredNewPhoto: boolean): void;
  onChangeDueDate(dueDate: Date): void;
  onChangeEnableReminderTwentyFourHoursBefore(enableReminderTwentyFourHoursBefore: boolean): void;
  onChangeEnableReminderOneWeekBefore(enableReminderOneWeekBefore: boolean): void;
  onChangeAllowToInviteByMembers(allowToInviteByMembers: boolean): void;
  goTo(index: CreateBoardIndex): void;
  onClose(): void;
}

const CreateBoardMain: React.FC<P> = React.memo(props => {
  const {
    name,
    description,
    photoURL,
    frameId,
    isRequiredNewPhoto,
    requestForNewPhoto,
    dueDate,
    // enableReminderTwentyFourHoursBefore,
    // enableReminderOneWeekBefore,
    // allowToInviteByMembers,
    adminUsersToInvite,
    // adminEmailsToInvite,
    memberUsersToInvite,
    // memberEmailsToInvite,
    onChangePhoto,
    onChangeIsRequiredNewPhoto,
    onChangeDueDate,
    // onChangeEnableReminderTwentyFourHoursBefore,
    // onChangeEnableReminderOneWeekBefore,
    // onChangeAllowToInviteByMembers,
    goTo,
    onClose
  } = props;

  const disabledWriteButton = useMemo<boolean>(() => {
    return !name || !dueDate;
  }, [name, dueDate]);

  return (
    <Component style={styleForFullExpansion} className='create-board-main'>
      <Header>
        <ButtonLeft>
          <Button onClick={onClose}>
            <CloseIcon />
          </Button>
        </ButtonLeft>

        <LabelArea>
          <HeaderLabel>{TITLE}</HeaderLabel>
        </LabelArea>

        <ButtonRight>
          <Button
            disabled={disabledWriteButton}
            type='primary-outlined'
            shape='rect'
            onClick={() => goTo(CreateBoardIndex.CONFIRM_BOARD_PREVIEW)}
          >
            確認する
          </Button>
        </ButtonRight>
      </Header>

      <Body>
        <Scrollable>
          <Container>
            <UpperWrapper>
              <BoardPhotoUploader
                name={name}
                photoURL={photoURL}
                frameId={frameId}
                dueDate={dueDate}
                onUpload={onChangePhoto}
              />
            </UpperWrapper>

            <LowerWrapper>
              <ArrowForwardButton
                shape='rect'
                text='フレームを選ぶ'
                onClick={() => goTo(CreateBoardIndex.SELECT_BOARD_FRAME)}
              />
            </LowerWrapper>

            <UpperWrapper>
              <ArrowForwardButton
                shape='rect'
                label={!!name ? 'ボード名*' : undefined}
                text={!!name ? name : 'ボード名*'}
                onClick={() => goTo(CreateBoardIndex.INPUT_BOARD_NAME)}
              />
            </UpperWrapper>

            <LowerWrapper>
              <ArrowForwardButton
                shape='rect'
                label={!!description ? '説明' : undefined}
                text={!!description ? description : '説明'}
                onClick={() => goTo(CreateBoardIndex.INPUT_BOARD_DESCRIPTION)}
              />
            </LowerWrapper>

            {/* <UpperWrapper>
              <MemberLabel>イベント管理者</MemberLabel>
            </UpperWrapper> */}

            <UpperWrapper>
              <ArrowForwardButton
                shape='rect'
                icon={<Count>{adminUsersToInvite.length}</Count>}
                text='イベント管理者を招待する'
                onClick={() => goTo(CreateBoardIndex.SELECT_ADMIN_USERS_TO_INVITE)}
              />
            </UpperWrapper>

            {/* <LowerWrapper>
              <ArrowForwardButton
                shape='rect'
                icon={<Count>{adminEmailsToInvite.length}</Count>}
                text='メールアドレスで招待する'
                onClick={() => goTo(CreateBoardIndex.INPUT_ADMIN_EMAILS_TO_INVITE)}
              />
            </LowerWrapper> */}

            {/* <UpperWrapper>
              <MemberLabel>イベント参加者</MemberLabel>
            </UpperWrapper> */}

            <LowerWrapper>
              <ArrowForwardButton
                shape='rect'
                icon={<Count>{memberUsersToInvite.length}</Count>}
                text='イベント参加者を招待する'
                onClick={() => goTo(CreateBoardIndex.SELECT_MEMBER_USERS_TO_INVITE)}
              />
            </LowerWrapper>

            {/* <LowerWrapper>
              <ArrowForwardButton
                shape='rect'
                icon={<Count>{memberEmailsToInvite.length}</Count>}
                text='メールアドレスで招待する'
                onClick={() => goTo(CreateBoardIndex.INPUT_MEMBER_EMAILS_TO_INVITE)}
              />
            </LowerWrapper> */}

            {/* <MiddleWrapper>
              <ToggleBox
                checked={allowToInviteByMembers}
                text='参加者による追加招待'
                onClick={onChangeAllowToInviteByMembers}
              />
            </MiddleWrapper> */}

            {/* <LowerWrapper>
              <Remarks>
                参加した人による知り合い招待を可能にします。イベント作成者が招待相手を制限したい場合は、この設定をオフにします。
              </Remarks>
            </LowerWrapper> */}

            <Wrapper>
              <DateBox
                shape='rect'
                label='公開日の設定*'
                minDate={new Date()}
                value={dueDate}
                onChange={onChangeDueDate}
              />
            </Wrapper>

            {/* <MiddleWrapper>
              <ToggleBox
                checked={enableReminderTwentyFourHoursBefore}
                text='24時間前にリマインドする'
                onClick={onChangeEnableReminderTwentyFourHoursBefore}
              />
            </MiddleWrapper> */}

            {/* <MiddleWrapper>
              <ToggleBox
                checked={enableReminderOneWeekBefore}
                text='1週間前にリマインドする'
                onClick={onChangeEnableReminderOneWeekBefore}
              />
            </MiddleWrapper> */}

            {/* <LowerWrapper>
              <Remarks>
                未回答者の人がいた場合、自動で記載するようリマインドします。<Attraction>メールアドレスもしくはヨセマル上で招待した場合のみ有効</Attraction>です（URL共有のみだと無効）
              </Remarks>
            </LowerWrapper> */}

            <UpperWrapper>
              <ToggleBox
                checked={isRequiredNewPhoto}
                text='写真の変更を必須にする'
                onClick={onChangeIsRequiredNewPhoto}
              />
            </UpperWrapper>

            <MiddleWrapper>
              <ArrowForwardButton
                shape='rect'
                label={!!requestForNewPhoto ? '希望する写真' : undefined}
                text={!!requestForNewPhoto ? requestForNewPhoto : '希望する写真'}
                onClick={() => goTo(CreateBoardIndex.INPUT_REQUEST_FOR_NEW_PHOTO)}
              />
            </MiddleWrapper>

            <LowerWrapper>
              <Remarks>
                このイベント専用の写真の投稿を必須にするかどうかを選ぶことができます。標準プロフィール写真で問題ない場合はオフにします。
              </Remarks>
            </LowerWrapper>

            <ButtonWrapper>
              <Button
                disabled={disabledWriteButton}
                type='primary'
                onClick={() => goTo(CreateBoardIndex.CONFIRM_BOARD_PREVIEW)}
              >
                <ButtonLabel>確認する</ButtonLabel>
              </Button>
            </ButtonWrapper>
          </Container>
        </Scrollable>
      </Body>
    </Component>
  );
});

export default CreateBoardMain;

const Header = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.mixins.spacing}px 0px;
`;

const ButtonLeft = styled.div`
  min-width: 80px;
  height: 64px;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
`;

const ButtonRight = styled.div`
  min-width: 80px;
  height: 64px;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  justify-content: center;
  margin-right: ${theme.mixins.spacing}px;
`;

const CloseIcon = styled(Icon)`
  color: ${theme.mixins.typography.fontColor.lightGray};
`;

const LabelArea = styled.div`
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px ${theme.mixins.spacing * 2}px;
`;

const HeaderLabel = styled.div`
  width: 100%;
  height: 100%;
  color: ${theme.mixins.typography.fontColor.lightGray};
  font-size: ${theme.mixins.typography.fontSize.sixteen};
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: pre;
  text-align: center;
`;

const Body = styled.div`
  width: 100%;
  height: calc(100% - 48px);
  background: ${theme.mixins.background.yellow};
`;

const Container = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: ${theme.mixins.spacing * 20}px;
`;

const Wrapper = styled.div`
  padding: ${theme.mixins.spacing * 2}px;
`;

const UpperWrapper = styled.div`
  padding: ${theme.mixins.spacing * 2}px ${theme.mixins.spacing * 2}px ${theme.mixins.spacing / 4}px;
`;

const MiddleWrapper = styled.div`
  padding: ${theme.mixins.spacing / 4}px ${theme.mixins.spacing * 2}px;
`;

const LowerWrapper = styled.div`
  padding: ${theme.mixins.spacing / 4}px ${theme.mixins.spacing * 2}px ${theme.mixins.spacing * 2}px;
`;

// const MemberLabel = styled(Typography)`
//   color: ${theme.mixins.typography.fontColor.black};
//   font-size: ${theme.mixins.typography.fontSize.sixteen}px;
//   font-weight: ${theme.mixins.typography.fontWeight.fourHundreds} !important;
//   font-family: ${theme.mixins.typography.fontFamily};
//   padding: 0px ${theme.mixins.spacing}px;
// `;

const Remarks = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.twelve}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds} !important;
  font-family: ${theme.mixins.typography.fontFamily};
  padding: 0px ${theme.mixins.spacing * 2}px;
`;

// const Attraction = styled.span`
//   color: ${theme.mixins.typography.fontColor.pink};
//   font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds} !important;
// `;

const Count = styled.div`
  width: 24px;
  height: 24px;
  color: ${theme.mixins.typography.fontColor.black};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px !important;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds} !important;
  font-family: ${theme.mixins.typography.fontFamily} !important;
  background: ${theme.mixins.background.white};
  border: 2px solid ${theme.mixins.border.black};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 0px;
  left: 5px;
  z-index: 10;
  padding-bottom: ${theme.mixins.spacing / 4}px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding-top: ${theme.mixins.spacing * 2}px;
`;

const ButtonLabel = styled(Typography)`
  width: 96px;
  height: 32px;
  color: ${theme.mixins.typography.fontColor.white};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  display: flex;
  align-items: center;
  justify-content: center;
`;
