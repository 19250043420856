import { Component, theme, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { BeriefHistory, builder, Email, EMPTY, Name, SelfIntroduction, Subject, URL } from '@atomica.co/utils';
import {
  CollegeDto,
  CollegeYear,
  FetchUserProfileRequest,
  FrameId,
  ProfileEntity,
  SaveUserPhotoRequest,
  UserDiv,
  UserEntity,
  UserId
} from '@atomica.co/yosemal-v2';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import UserRequest from '../../../../requests/user-request';
import { AccountAction } from '../../AccountScreen';
import EditProfileMain from './parts/EditProfileMain';
import InputBeriefHistory from './parts/InputBeriefHistory';
import InputCompanyName from './parts/InputCompanyName';
import InputDepartmentName from './parts/InputDepartmentName';
import InputEmailToContact from './parts/InputEmailToContact';
import InputFacebookURL from './parts/InputFacebookURL';
import InputInstagramURL from './parts/InputInstagramURL';
import InputResearchSubject from './parts/InputResearchSubject';
import InputSelfIntroduction from './parts/InputSelfIntroduction';
import InputTitle from './parts/InputTitle';
import InputTwitterURL from './parts/InputTwitterURL';
import SelectCollegeYear from './parts/SelectCollegeYear';
import SelectDepartment from './parts/SelectDepartment';
import SelectFaculty from './parts/SelectFaculty';
import SelectMajor from './parts/SelectMajor';
import SelectUserFrame from './parts/SelectUserFrame';

export enum EditProfileIndex {
  EDIT_PROFILE_MAIN = 'edit_profile_main',
  SELECT_USER_FRAME = 'select_user_frame',
  INPUT_COMPANY_NAME = 'input_company_name',
  INPUT_DEPARTMENT_NAME = 'input_department_name',
  INPUT_TITLE = 'input_title',
  INPUT_BERIEF_HISTORY = 'input_berief_history',
  SELECT_COLLEGE = 'select_collge',
  SELECT_FACULTY = 'select_faculty',
  SELECT_DEPARTMENT = 'select_department',
  SELECT_MAJOR = 'select_major',
  SELECT_COLLGE_YEAR = 'select_collge_year',
  INPUT_RESEARCH_SUBJECT = 'input_research_subject',
  INPUT_SELF_INTRODUCTION = 'input_self_introduction',
  INPUT_EMAIL_TO_CONTACT = 'input_email_to_contact',
  INPUT_TWITTER_URL = 'input_twitter_url',
  INPUT_FACEBOOK_URL = 'input_facebook_url',
  INPUT_INSTAGRAM_URL = 'input_instagram_url'
}

interface P {
  userId: UserId | undefined;
  signInUser: UserEntity | undefined;
  emitAction(action: AccountAction): void;
}

const EditProfile: React.FC<P> = React.memo(props => {
  const { userId, signInUser, emitAction } = props;
  const unmountRef = useUnmountRef();
  const [index, setIndex] = useSafeState<EditProfileIndex>(unmountRef, EditProfileIndex.EDIT_PROFILE_MAIN);
  const [prevProfile, setPrevProfile] = useSafeState<ProfileEntity | undefined>(unmountRef);

  const [savedFrameId, setSavedFrameId] = useSafeState<FrameId | undefined>(unmountRef);
  const [savedPhotoURL, setSavedPhotoURL] = useSafeState<URL>(unmountRef);
  const [savedCompanyName, setSavedCompanyName] = useSafeState<Name>(unmountRef);
  const [savedDepartmentName, setSavedDepartmentName] = useSafeState<Name>(unmountRef);
  const [savedTitle, setSavedTitle] = useSafeState<Name>(unmountRef);
  const [savedBeriefHistory, setSavedBeriefHistory] = useSafeState<BeriefHistory>(unmountRef);
  const [savedCollege, setSavedCollege] = useSafeState<CollegeDto | undefined>(unmountRef);
  const [savedCollegeYear, setSavedCollegeYear] = useSafeState<CollegeYear | undefined>(unmountRef);
  const [savedFaculty, setSavedFaculty] = useSafeState<Name>(unmountRef); // FIXME
  const [savedDepartment, setSavedDepartment] = useSafeState<Name>(unmountRef); // FIXME
  const [savedMajor, setSavedMajor] = useSafeState<Name>(unmountRef); // FIXME
  const [savedResearchSubject, setSavedResearchSubject] = useSafeState<Subject>(unmountRef);
  const [savedSelfIntroduction, setSavedSelfIntroduction] = useSafeState<SelfIntroduction>(unmountRef);
  const [savedEmailToContact, setSavedEmailToContact] = useSafeState<Email>(unmountRef);
  const [savedTwitterURL, setSavedTwitterURL] = useSafeState<URL>(unmountRef);
  const [savedFacebookURL, setSavedFacebookURL] = useSafeState<URL>(unmountRef);
  const [savedInstagramURL, setSavedInstagramURL] = useSafeState<URL>(unmountRef);

  const initialize = useSafeCallback(async (): Promise<void> => {
    if (!userId || !signInUser || userId !== signInUser.userId) {
      return;
    }

    const signInUserId = signInUser.userId;
    const profileRequest = builder<FetchUserProfileRequest>()
      .userIdToDisplay(signInUserId)
      .requestedUserId(signInUserId)
      .build();

    const { profile } = await UserRequest.fetchProfile(profileRequest);
    if (!profile) return;

    setSavedFrameId(profile.frameId);
    setSavedPhotoURL(profile.photoURL);
    setSavedCompanyName(!!profile.companyName ? profile.companyName : EMPTY);
    setSavedDepartmentName(!!profile.departmentName ? profile.departmentName : EMPTY);
    setSavedTitle(!!profile.title ? profile.title : EMPTY);
    setSavedBeriefHistory(!!profile.beriefHistory ? profile.beriefHistory : EMPTY);
    setSavedCollege(!!profile.college ? profile.college : undefined);
    setSavedCollegeYear(!!profile.collegeYear ? profile.collegeYear : undefined);
    setSavedFaculty(!!profile.faculty ? profile.faculty : EMPTY);
    setSavedDepartment(!!profile.department ? profile.department : EMPTY);
    setSavedMajor(!!profile.major ? profile.major : EMPTY);
    setSavedResearchSubject(!!profile.researchSubject ? profile.researchSubject : EMPTY);
    setSavedSelfIntroduction(!!profile.selfIntroduction ? profile.selfIntroduction : EMPTY);
    setSavedEmailToContact(!!profile.emailToContact ? profile.emailToContact : EMPTY);
    setSavedTwitterURL(!!profile.twitterURL ? profile.twitterURL : EMPTY);
    setSavedFacebookURL(!!profile.facebookURL ? profile.facebookURL : EMPTY);
    setSavedInstagramURL(!!profile.instagramURL ? profile.instagramURL : EMPTY);
    setPrevProfile(profile);
  }, [
    signInUser,
    userId,
    setSavedFrameId,
    setSavedPhotoURL,
    setSavedCompanyName,
    setSavedDepartmentName,
    setSavedTitle,
    setSavedBeriefHistory,
    setSavedCollege,
    setSavedCollegeYear,
    setSavedFaculty,
    setSavedDepartment,
    setSavedMajor,
    setSavedResearchSubject,
    setSavedSelfIntroduction,
    setSavedEmailToContact,
    setSavedTwitterURL,
    setSavedFacebookURL,
    setSavedInstagramURL,
    setPrevProfile
  ]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const saveUserPhoto = useSafeCallback(
    async (photoURL: URL): Promise<void> => {
      if (!userId) return;

      const request = builder<SaveUserPhotoRequest>().userId(userId).photoURL(photoURL).build();
      await UserRequest.saveUserPhoto(request);

      setSavedPhotoURL(photoURL);
    },
    [userId, setSavedPhotoURL]
  );

  return (
    <Component className='edit-profile'>
      <Container>
        {index === EditProfileIndex.EDIT_PROFILE_MAIN && (
          <EditProfileMain
            userDiv={!!prevProfile ? prevProfile.userDiv : undefined}
            frameId={savedFrameId}
            companyName={savedCompanyName}
            departmentName={savedDepartmentName}
            title={savedTitle}
            beriefHistory={savedBeriefHistory}
            college={savedCollege}
            collegeYear={savedCollegeYear}
            faculty={savedFaculty}
            department={savedDepartment}
            major={savedMajor}
            researchSubject={savedResearchSubject}
            selfIntroduction={savedSelfIntroduction}
            emailToContact={savedEmailToContact}
            twitterURL={savedTwitterURL}
            facebookURL={savedFacebookURL}
            instagramURL={savedInstagramURL}
            profile={prevProfile}
            onUploadPhoto={saveUserPhoto}
            onClick={setIndex}
            emitAction={emitAction}
          />
        )}

        {index === EditProfileIndex.SELECT_USER_FRAME && (
          <SelectUserFrame
            frameId={savedFrameId}
            user={builder<UserEntity>()
              .userId(!!prevProfile ? prevProfile.userId : EMPTY)
              .photoURL(savedPhotoURL)
              .frameId(savedFrameId as FrameId)
              .familyName(!!prevProfile ? prevProfile.familyName : EMPTY)
              .firstName(!!prevProfile ? prevProfile.firstName : EMPTY)
              .dateOfBirth(!!prevProfile ? prevProfile.dateOfBirth : new Date())
              .build()}
            onSave={frameId => {
              setSavedFrameId(frameId);
              setIndex(EditProfileIndex.EDIT_PROFILE_MAIN);
            }}
            goTo={setIndex}
          />
        )}

        {!!prevProfile && prevProfile.userDiv === UserDiv.WORKER && (
          <>
            {index === EditProfileIndex.INPUT_COMPANY_NAME && (
              <InputCompanyName
                userId={userId}
                companyName={savedCompanyName}
                onSave={companyName => {
                  setSavedCompanyName(companyName);
                  setIndex(EditProfileIndex.EDIT_PROFILE_MAIN);
                }}
                goTo={setIndex}
              />
            )}

            {index === EditProfileIndex.INPUT_DEPARTMENT_NAME && (
              <InputDepartmentName
                userId={userId}
                departmentName={savedDepartmentName}
                onSave={departmentName => {
                  setSavedDepartmentName(departmentName);
                  setIndex(EditProfileIndex.EDIT_PROFILE_MAIN);
                }}
                goTo={setIndex}
              />
            )}

            {index === EditProfileIndex.INPUT_TITLE && (
              <InputTitle
                userId={userId}
                title={savedTitle}
                onSave={title => {
                  setSavedTitle(title);
                  setIndex(EditProfileIndex.EDIT_PROFILE_MAIN);
                }}
                goTo={setIndex}
              />
            )}

            {index === EditProfileIndex.INPUT_BERIEF_HISTORY && (
              <InputBeriefHistory
                userId={userId}
                beriefHistory={savedBeriefHistory}
                onSave={beriefHistory => {
                  setSavedBeriefHistory(beriefHistory);
                  setIndex(EditProfileIndex.EDIT_PROFILE_MAIN);
                }}
                goTo={setIndex}
              />
            )}
          </>
        )}

        {!!prevProfile && prevProfile.userDiv === UserDiv.STUDENT && (
          <>
            {index === EditProfileIndex.SELECT_COLLGE_YEAR && (
              <SelectCollegeYear
                userId={userId}
                collegeYear={savedCollegeYear}
                onSave={collegeYear => {
                  setSavedCollegeYear(collegeYear);
                  setIndex(EditProfileIndex.EDIT_PROFILE_MAIN);
                }}
                goTo={setIndex}
              />
            )}

            {index === EditProfileIndex.SELECT_FACULTY && (
              <SelectFaculty
                userId={userId}
                faculty={savedFaculty}
                onSave={faculty => {
                  setSavedFaculty(faculty);
                  setIndex(EditProfileIndex.EDIT_PROFILE_MAIN);
                }}
                goTo={setIndex}
              />
            )}

            {index === EditProfileIndex.SELECT_DEPARTMENT && (
              <SelectDepartment
                userId={userId}
                department={savedDepartment}
                onSave={department => {
                  setSavedDepartment(department);
                  setIndex(EditProfileIndex.EDIT_PROFILE_MAIN);
                }}
                goTo={setIndex}
              />
            )}

            {index === EditProfileIndex.SELECT_MAJOR && (
              <SelectMajor
                userId={userId}
                major={savedMajor}
                onSave={major => {
                  setSavedMajor(major);
                  setIndex(EditProfileIndex.EDIT_PROFILE_MAIN);
                }}
                goTo={setIndex}
              />
            )}

            {index === EditProfileIndex.INPUT_RESEARCH_SUBJECT && (
              <InputResearchSubject
                userId={userId}
                researchSubject={savedResearchSubject}
                onSave={researchSubject => {
                  setSavedResearchSubject(researchSubject);
                  setIndex(EditProfileIndex.EDIT_PROFILE_MAIN);
                }}
                goTo={setIndex}
              />
            )}

            {index === EditProfileIndex.INPUT_SELF_INTRODUCTION && (
              <InputSelfIntroduction
                userId={userId}
                selfIntroduction={savedSelfIntroduction}
                onSave={selfIntroduction => {
                  setSavedSelfIntroduction(selfIntroduction);
                  setIndex(EditProfileIndex.EDIT_PROFILE_MAIN);
                }}
                goTo={setIndex}
              />
            )}
          </>
        )}

        {index === EditProfileIndex.INPUT_EMAIL_TO_CONTACT && (
          <InputEmailToContact
            userId={userId}
            email={savedEmailToContact}
            onSave={email => {
              setSavedEmailToContact(email);
              setIndex(EditProfileIndex.EDIT_PROFILE_MAIN);
            }}
            goTo={setIndex}
          />
        )}

        {index === EditProfileIndex.INPUT_TWITTER_URL && (
          <InputTwitterURL
            userId={userId}
            twitterURL={savedTwitterURL}
            onSave={twitterURL => {
              setSavedTwitterURL(twitterURL);
              setIndex(EditProfileIndex.EDIT_PROFILE_MAIN);
            }}
            goTo={setIndex}
          />
        )}

        {index === EditProfileIndex.INPUT_FACEBOOK_URL && (
          <InputFacebookURL
            userId={userId}
            facebookURL={savedFacebookURL}
            onSave={facebookURL => {
              setSavedFacebookURL(facebookURL);
              setIndex(EditProfileIndex.EDIT_PROFILE_MAIN);
            }}
            goTo={setIndex}
          />
        )}

        {index === EditProfileIndex.INPUT_INSTAGRAM_URL && (
          <InputInstagramURL
            userId={userId}
            instagramURL={savedInstagramURL}
            onSave={instagramURL => {
              setSavedInstagramURL(instagramURL);
              setIndex(EditProfileIndex.EDIT_PROFILE_MAIN);
            }}
            goTo={setIndex}
          />
        )}
      </Container>
    </Component>
  );
});

export default EditProfile;

const Container = styled.div`
  width: 100%;
  height: auto;
  min-height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 10000;
  background: ${theme.mixins.background.lightGray};
`;
