import { Component, SubTab, SubTabs, theme, useSafeCallback } from '@atomica.co/components';
import { Index } from '@atomica.co/utils';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { fromSignUpInTab, toSignUpInTab } from '../../../converters/path-converter';
import usePath from '../../../redux/hooks/usePath';

export enum SignUpInTabEnum {
  SIGN_IN,
  SIGN_UP,
  OUT_OF_TARGET
}

const tabs: SubTab[] = [
  {
    id: SignUpInTabEnum.SIGN_IN,
    label: 'ログイン'
  },
  {
    id: SignUpInTabEnum.SIGN_UP,
    label: '新規登録'
  }
];

interface P {}

const SignUpInTabs: React.FC<P> = React.memo(() => {
  const { path, openPath } = usePath();
  const index = useMemo<Index>(() => toSignUpInTab(path), [path]);

  const handleTabChanged = useSafeCallback(
    (tab: SubTab): void => {
      openPath(fromSignUpInTab(tab.id)!);
    },
    [openPath]
  );

  return (
    <Component style={{ width: '100%' }} className='sign-up-in-tabs'>
      <Contanier>
        <Content>
          <SubTabs
            background={theme.mixins.background.lightYellow}
            selected={tabs.find(t => t.id === index)!}
            tabs={tabs}
            onChange={handleTabChanged}
          />
        </Content>
      </Contanier>
    </Component>
  );
});

export default SignUpInTabs;

const Contanier = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 288px;
  height: 40px;
`;
