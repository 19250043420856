import {
  CheckButton,
  Component,
  getMobileWidth,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { Index, noop, Width } from '@atomica.co/utils';
import { FrameId, UserEntity } from '@atomica.co/yosemal-v2';
import { default as React, useMemo } from 'react';
import styled from 'styled-components';
import UserPolaroid from './UserPolaroid';

interface P {
  user: UserEntity | undefined;
  onChange(frameId: FrameId): void;
}

const UserFrameSelecter: React.FC<P> = React.memo(props => {
  const { user, onChange } = props;
  const unmountRef = useUnmountRef();
  const width = useMemo(() => getMobileWidth(), []);
  const [selectedFrameId, setSelectedFrameId] = useSafeState<FrameId | undefined>(
    unmountRef,
    !!user ? user.frameId : FrameId.CHEERS_BLACK
  );

  const handleFrameSelected = useSafeCallback(
    (frameId: FrameId): void => {
      setSelectedFrameId(frameId);
      onChange(frameId);
    },
    [setSelectedFrameId, onChange]
  );

  return (
    <Component className='board-frame-selecter'>
      {Object.values(FrameId).map((frameId: FrameId, index: Index) => (
        <PolaroidWrapper key={index} width={width} onClick={() => handleFrameSelected(frameId)}>
          <UserPolaroid size='large' rotate='none' frameId={frameId} user={user} />

          <ButtonWrapper width={width}>
            <CheckButton checked={frameId === selectedFrameId} onClick={noop} />
          </ButtonWrapper>
        </PolaroidWrapper>
      ))}
    </Component>
  );
});

export default UserFrameSelecter;

const PolaroidWrapper = styled.div<{ width: Width }>`
  width: ${props => props.width}px;
  height: ${props => props.width + theme.mixins.spacing * 9}px;
  position: relative;
  padding: ${theme.mixins.spacing}px;
`;

const ButtonWrapper = styled.div<{ width: Width }>`
  width: 40px;
  height: auto;
  position: absolute;
  top: ${props => props.width / 2 + theme.mixins.spacing * 2}px;
  left: ${props => props.width / 2 - theme.mixins.spacing * 4}px;
`;
