import { Button, Component, Icon, theme, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { copyURL, embedIdInPath, EMPTY, EQUAL, QUESTION_MARK, REDIRECT_PATH, toDataURL, URL } from '@atomica.co/utils';
import { LINE_URLS, UserId } from '@atomica.co/yosemal-v2';
import { Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { COPY_URL, SUCCESS } from '../../../../constants/snackbar-const';
import env from '../../../../env/env';
import { Path, PATH_IDS } from '../../../../router/Routes';
import share from './../../../../assets/icon/icon_share.png';

const MESSAGE = '他の人がこのQRコードをスキャンすると\n知り合いに追加されます。';

interface P {
  userId: UserId | undefined;
  onClose(): void;
}

const ShareAccount: React.FC<P> = React.memo(props => {
  const { userId, onClose } = props;
  const { enqueueSnackbar } = useSnackbar();
  const unmountRef = useUnmountRef();
  const [dataURL, setDataURL] = useSafeState<URL>(unmountRef);

  const urlToShare = useMemo<URL>(
    () =>
      !!userId
        ? `${LINE_URLS[env]}${QUESTION_MARK}${REDIRECT_PATH}${EQUAL}${embedIdInPath(Path.ACCOUNT, PATH_IDS, [userId])}`
        : EMPTY,
    [userId]
  );

  const initialize = useSafeCallback(async (): Promise<void> => {
    const dataURL = await toDataURL(urlToShare);
    setDataURL(dataURL);
  }, [setDataURL, urlToShare]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const handleShareButtonClicked = useSafeCallback((): void => {
    if (!!navigator.share) {
      navigator.share({ text: urlToShare });
      return;
    }

    copyURL(urlToShare);
    enqueueSnackbar(COPY_URL, { variant: SUCCESS });
  }, [urlToShare, enqueueSnackbar]);

  return (
    <Component className='share-account'>
      <Header>
        <ButtonArea>
          <Button onClick={onClose}>
            <CloseIcon />
          </Button>
        </ButtonArea>
      </Header>

      <QRCodeWrapper>
        <QRCode src={dataURL} />
      </QRCodeWrapper>

      <Message>{MESSAGE}</Message>

      <ButtonsWrapper>
        <Button onClick={handleShareButtonClicked}>
          <IconWrapper>
            <Icon size='small' src={share} />
            <LabelWrapper>送る</LabelWrapper>
          </IconWrapper>
        </Button>
      </ButtonsWrapper>
    </Component>
  );
});

export default ShareAccount;

const Header = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing}px 0px;
`;

const ButtonArea = styled.div`
  width: 40px;
  height: 40px;
`;

const QRCodeWrapper = styled.div`
  width: 100%;
  height: 176px;
  display: flex;
  justify-content: center;
  padding: ${theme.mixins.spacing}px;
`;

const QRCode = styled.img`
  width: 160px;
  height: 160px;
`;

const Message = styled.div`
  width: 100%;
  height: auto;
  color: ${theme.mixins.typography.fontColor.lightGray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  text-align: center;
  white-space: pre;
  padding: ${theme.mixins.spacing * 2}px ${theme.mixins.spacing}px;
`;
const ButtonsWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-around;
`;

const IconWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
`;

const LabelWrapper = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.lightGray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  text-align: center;
  padding-top: ${theme.mixins.spacing / 2}px;
`;
