import { Component, Icon, theme, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { BoardEntity, BoardId, FetchBoardRequest } from '@atomica.co/yosemal-v2';
import { builder, isFuture } from '@atomica.co/utils';
import { CSSProperties } from '@material-ui/styles';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import Header from '../../../components/header/Header';
import usePath from '../../../redux/hooks/usePath';
import BoardRequest from '../../../requests/board-request';
import BoardExportButton from '../../export/BoardExportButton';
import { Path } from '../../../router/Routes';
import { BoardAction } from '../BoardScreen';
import add_account from './../../../assets/icon/icon_account_add.png';
import arrow_left from './../../../assets/icon/icon_arrow_left.png';
import add_message from './../../../assets/icon/icon_comment_add.png';
import qr from './../../../assets/icon/icon_qr.png';

interface P {
  boardId: BoardId | undefined;
  emitAction(action: BoardAction, id?: BoardId): void;
}

const BoardDetailsHeader: React.FC<P> = React.memo(props => {
  const { boardId, emitAction } = props;
  const { goBack } = usePath();
  const { path } = usePath();
  const unmountRef = useUnmountRef();
  const loadable = useMemo(() => path === Path.BOARD_DETAILS, [path]);
  const [board, setBoard] = useSafeState<BoardEntity | undefined>(unmountRef);

  const loadBoard = useSafeCallback(async (): Promise<void> => {
    if (!boardId) return;

    const request = builder<FetchBoardRequest>().boardId(boardId).build();
    const response = await BoardRequest.fetchBoard(request);

    setBoard(response.board);
  }, [boardId, setBoard]);

  useEffect(() => {
    if (!loadable) return;
    loadBoard();
  }, [loadable, loadBoard]);
  const isNotOverDue = useMemo<boolean>(() => !!board && isFuture(board!.dueDate), [board]);
  return (
    <Component className='board-details-header'>
      <Header
        leftChild={
          <IconsWrapper>
            <IconWrapper onClick={goBack}>
              <Icon style={styleForIcon} src={arrow_left} />
            </IconWrapper>
          </IconsWrapper>
        }
        rightChild={
          <IconsWrapper>
            {/* <IconWrapper
              onClick={() => onClick(BoardHeaderAction.WISH)}
            >
              <Icon
                style={styleForIcon}
                src={add_comment}
              />
            </IconWrapper> */}

            {isNotOverDue && (
              <IconWrapper onClick={() => emitAction(BoardAction.OPEN_BOARD_MESSAGE, boardId)}>
                <Icon style={styleForIcon} src={add_message} />
              </IconWrapper>
            )}

            <IconWrapper onClick={() => emitAction(BoardAction.INVITE_NEW_BOARD_MEMBERS)}>
              <Icon style={styleForIcon} src={add_account} />
            </IconWrapper>

            <IconWrapper onClick={() => emitAction(BoardAction.SHOW_BOARD_INVITATION_QR)}>
              <Icon style={styleForIcon} src={qr} />
            </IconWrapper>

            <BoardExportButton />
          </IconsWrapper>
        }
      />
    </Component>
  );
});

export default BoardDetailsHeader;

const IconsWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  padding: ${theme.mixins.spacing}px;
`;

const IconWrapper = styled.div`
  width: 40px;
  height: auto;
  padding: ${theme.mixins.spacing}px;
`;

const styleForIcon: CSSProperties = {
  width: 24,
  height: 24
};
