import { useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { EMPTY, URL } from '@atomica.co/utils';
import { toCashedURLAction } from '../actions/cashed-url-action';
import store from '../store';

interface P {
  cashedURL: URL;
  saveCashedURL: (urlToCash: URL) => void;
  saveCurrentURL: () => void;
  hasCashedURL: () => boolean;
  openCashedURL: () => void;
  removeCashedURL: () => void;
}

function useCashedURL() {
  const unmountRef = useUnmountRef();
  const [cashedURL, setCashedURL] = useSafeState<URL>(unmountRef, store.getState().cashedURL);

  const saveCashedURL = useSafeCallback(
    (cashedURL: URL): void => {
      setCashedURL(cashedURL);
      const action = toCashedURLAction(cashedURL);
      store.dispatch(action);
    },
    [setCashedURL]
  );

  const saveCurrentURL = useSafeCallback((): void => {
    saveCashedURL(window.location.href);
  }, [saveCashedURL]);

  const hasCashedURL = useSafeCallback((): boolean => {
    return !!cashedURL;
  }, [cashedURL]);

  const openCashedURL = useSafeCallback((): void => {
    window.location.href = cashedURL;
  }, [cashedURL]);

  const removeCashedURL = useSafeCallback((): void => {
    setCashedURL(EMPTY);
  }, [setCashedURL]);

  return {
    cashedURL,
    saveCashedURL,
    saveCurrentURL,
    hasCashedURL,
    openCashedURL,
    removeCashedURL
  } as P;
}

export default useCashedURL;
