import { Button, Component, Icon, theme } from '@atomica.co/components';
import { isFuture } from '@atomica.co/utils';
import { BoardEntity } from '@atomica.co/yosemal-v2';
import { CSSProperties } from '@material-ui/styles';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import Header from '../../../components/header/Header';
import usePath from '../../../redux/hooks/usePath';
import { BoardMessageIndex } from '../board-message/BoardMessage';
import arrow_left from './../../../assets/icon/icon_arrow_left.png';

interface P {
  onClickWriteButton(index: BoardMessageIndex): void;
  board: BoardEntity | undefined;
}

const BoardMessageEntryHeader: React.FC<P> = React.memo(props => {
  const { onClickWriteButton, board } = props;
  const { goBack } = usePath();
  const isOverDue = useMemo<boolean>(() => !!board && !isFuture(board!.dueDate), [board]);
  return (
    <Component className='board-message-entry-header'>
      <Header
        leftChild={
          <IconsWrapper>
            <IconWrapper onClick={goBack}>
              <Icon style={styleForIcon} src={arrow_left} />
            </IconWrapper>
          </IconsWrapper>
        }
        rightChild={
          <ButtonWrapper>
            <Button
              disabled={isOverDue || !board}
              type='primary'
              shape='rect'
              onClick={() => onClickWriteButton(BoardMessageIndex.BOARD_MESSAGE_MAIN)}
            >
              コメントを書く
            </Button>
          </ButtonWrapper>
        }
      />
    </Component>
  );
});

export default BoardMessageEntryHeader;

const IconsWrapper = styled.div`
  width: 168px;
  height: auto;
  display: flex;
  justify-content: flex-start;
  padding: ${theme.mixins.spacing}px;
`;

const IconWrapper = styled.div`
  width: 40px;
  height: auto;
  padding: ${theme.mixins.spacing}px;
`;

const styleForIcon: CSSProperties = {
  width: 24,
  height: 24
};

const ButtonWrapper = styled.div`
  width: 168px;
  height: auto;
  display: flex;
  justify-content: flex-end;
  padding: ${theme.mixins.spacing}px;
`;
