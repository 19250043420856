import { convert, RequestType } from '@atomica.co/utils';
import {
  FetchBoardPdfRequest,
  FetchBoardPdfResponse,
  FetchBoardRequest,
  FetchBoardResponse,
  FetchBoardsRequest,
  FetchBoardsResponse,
  FetchCommonBoardsRequest,
  FetchCommonBoardsResponse,
  FETCH_BOARD,
  FETCH_BOARDS,
  FETCH_BOARD_PDF,
  FETCH_COMMON_BOARDS,
  SaveBoardDescriptionRequest,
  SaveBoardDescriptionResponse,
  SaveBoardDueDateRequest,
  SaveBoardDueDateResponse,
  SaveBoardIsRequiredNewPhotoRequest,
  SaveBoardIsRequiredNewPhotoResponse,
  SaveBoardNameRequest,
  SaveBoardNameResponse,
  SaveBoardNotificationStatusRequest,
  SaveBoardNotificationStatusResponse,
  SaveBoardPhotoRequest,
  SaveBoardPhotoResponse,
  SaveBoardRequestForNewPhotoRequest,
  SaveBoardRequestForNewPhotoResponse,
  SaveNewBoardRequest,
  SaveNewBoardResponse,
  SAVE_BOARD_DESCRIPTION,
  SAVE_BOARD_DUE_DATE,
  SAVE_BOARD_IS_REQUIRED_NEW_PHOTO,
  SAVE_BOARD_NAME,
  SAVE_BOARD_NOTIFICATION_STATUS,
  SAVE_BOARD_PHOTO,
  SAVE_BOARD_REQUEST_FOR_NEW_PHOTO,
  SAVE_NEW_BOARD,
  toFuncName
} from '@atomica.co/yosemal-v2';
import { functions } from '../firebase';

export default class BoardRequest {
  public static saveNewBoard = async (request: SaveNewBoardRequest): Promise<SaveNewBoardResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_NEW_BOARD));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static saveBoardName = async (request: SaveBoardNameRequest): Promise<SaveBoardNameResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_BOARD_NAME));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static saveBoardDescription = async (
    request: SaveBoardDescriptionRequest
  ): Promise<SaveBoardDescriptionResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_BOARD_DESCRIPTION));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static saveBoardPhoto = async (request: SaveBoardPhotoRequest): Promise<SaveBoardPhotoResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_BOARD_PHOTO));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static saveDueDate = async (request: SaveBoardDueDateRequest): Promise<SaveBoardDueDateResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_BOARD_DUE_DATE));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static saveIsRequiredNewPhoto = async (
    request: SaveBoardIsRequiredNewPhotoRequest
  ): Promise<SaveBoardIsRequiredNewPhotoResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_BOARD_IS_REQUIRED_NEW_PHOTO));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static saveRequestForNewPhoto = async (
    request: SaveBoardRequestForNewPhotoRequest
  ): Promise<SaveBoardRequestForNewPhotoResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_BOARD_REQUEST_FOR_NEW_PHOTO));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static saveBoardNotificationStatus = async (
    request: SaveBoardNotificationStatusRequest
  ): Promise<SaveBoardNotificationStatusResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_BOARD_NOTIFICATION_STATUS));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static fetchBoard = async (request: FetchBoardRequest): Promise<FetchBoardResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(FETCH_BOARD));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static fetchBoards = async (request: FetchBoardsRequest): Promise<FetchBoardsResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(FETCH_BOARDS));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static fetchCommonBoards = async (request: FetchCommonBoardsRequest): Promise<FetchCommonBoardsResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(FETCH_COMMON_BOARDS));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static fetchBoardPdf = async (request: FetchBoardPdfRequest): Promise<FetchBoardPdfResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(FETCH_BOARD_PDF));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };
}
