import {
  CircularLoader,
  Component,
  getMobileWidth,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { builder, Count, Index, isEven, ZERO } from '@atomica.co/utils';
import { BoardMessageId, BoardThreadEntity, FetchBoardThreadsRequest } from '@atomica.co/yosemal-v2';
import React, { useEffect, useImperativeHandle, useRef } from 'react';
import styled from 'styled-components';
import BoardThreadPolaroid from '../../../../components/polaroid/BoardThreadPolaroid';
import BoardThreadRequest from '../../../../requests/board-thread-request';

const LIMIT = 10;

const INITIAL_THREADS: any[] = [undefined, undefined];

export interface BoardThreadRef {
  refresh(): void;
}

interface P {
  loadable: boolean;
  loadAllThreads?: boolean;
  totalCount: Count;
  boardMessageId: BoardMessageId | undefined;
}

const BoardThreadList: React.ForwardRefExoticComponent<P & React.RefAttributes<BoardThreadRef>> = React.forwardRef<
  BoardThreadRef,
  P
>((props, ref) => {
  const { loadable, loadAllThreads = false, totalCount, boardMessageId } = props;
  const hasMore = useRef<boolean>(true);
  const count = useRef<Count>(ZERO);
  const unmountRef = useUnmountRef();
  const [ready, setReady] = useSafeState<boolean>(unmountRef, false);
  const [loading, setLoading] = useSafeState<boolean>(unmountRef, false);
  const [threads, setThreads] = useSafeState<BoardThreadEntity[]>(unmountRef, []);

  const loadThreads = useSafeCallback(async (): Promise<void> => {
    if (!boardMessageId || !hasMore) return;

    const requestBuilder = builder<FetchBoardThreadsRequest>().boardMessageId(boardMessageId);

    if (!loadAllThreads) {
      requestBuilder.limit(LIMIT).offset(count.current);
    }

    const request = requestBuilder.build();
    const response = await BoardThreadRequest.fetchThreads(request);
    const threadsToAdd = response.threads;

    hasMore.current = threadsToAdd.length === LIMIT;
    count.current += threadsToAdd.length;
    setThreads(threads => (loadAllThreads ? response.threads : [...threads, ...response.threads]));
    setReady(true);
    setLoading(false);
  }, [boardMessageId, loadAllThreads, setLoading, setThreads, setReady]);

  useEffect(() => {
    if (!loadable) return;
    loadThreads();
  }, [loadable, loadThreads]);

  useImperativeHandle(ref, () => ({
    refresh: () => loadThreads()
  }));

  return (
    <Component className='board-thread-list'>
      <ListWrapper>
        {(ready ? threads : INITIAL_THREADS).map((thread: BoardThreadEntity, index: Index) => (
          <PolaroidWrapper key={index}>
            <BoardThreadPolaroid rotate={isEven(index) ? 'right' : 'left'} thread={thread} />
          </PolaroidWrapper>
        ))}
      </ListWrapper>

      {!loading && totalCount > count.current && (
        <ButtonArea>
          <ButtonWrapper>
            <Button
              onClick={() => {
                setLoading(true);
                loadThreads();
              }}
            >
              さらに見る
            </Button>
          </ButtonWrapper>
        </ButtonArea>
      )}

      {loading && (
        <LoaderWrapper>
          <CircularLoader />
        </LoaderWrapper>
      )}
    </Component>
  );
});

export default BoardThreadList;

const ListWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
`;

const PolaroidWrapper = styled.div`
  width: auto;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const LoaderWrapper = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonArea = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
`;

const ButtonWrapper = styled.div`
  width: ${getMobileWidth() - theme.mixins.spacing * 4}px;
  height: auto;
  display: flex;
  justify-content: flex-end;
  padding: 0px ${theme.mixins.spacing * 2}px ${theme.mixins.spacing * 4}px;
`;

const Button = styled.div`
  width: 106px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  ${theme.mixins.userSelectDisabled};
`;
