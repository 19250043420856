import { useSafeCallback } from '@atomica.co/components';
import { builder, Email, EMPTY } from '@atomica.co/utils';
import { FetchUserByExternalIdRequest, SaveNewUserRequest, UserEntity, UserId } from '@atomica.co/yosemal-v2';
import firebase, { analytics, auth } from '../../firebase';
import UserRequest from '../../requests/user-request';
import { toProviderId } from '../../utils/user-util';

interface P {
  getFirebase: () => Promise<firebase.User | null>;
  existFirebase: () => Promise<boolean>;
  getUser: () => Promise<UserEntity | undefined>;
  existUser: () => Promise<boolean>;
  createNonActivatedUserIfNotExisted: (firebase: firebase.User, email: Email) => Promise<UserId | undefined>;
}

function useUser() {
  const getFirebase = useSafeCallback(async (): Promise<firebase.User | null> => {
    return await new Promise(resolve => {
      const unsubscribe = auth.onAuthStateChanged(firebase => {
        resolve(firebase);
        unsubscribe();
      });
    });
  }, []);

  const existFirebase = useSafeCallback(async (): Promise<boolean> => {
    const firebase = await getFirebase();
    return !!firebase && !!firebase.uid;
  }, [getFirebase]);

  const getUser = useSafeCallback(async (): Promise<UserEntity | undefined> => {
    const firebase = await getFirebase();
    if (!firebase) return undefined;

    const request = builder<FetchUserByExternalIdRequest>().externalId(firebase.uid).build();
    const response = await UserRequest.fetchUserByExternalId(request);
    const user = response.user;
    if (!user) return undefined;

    analytics.setUserId(user.userId);
    return user;
  }, [getFirebase]);

  const existUser = useSafeCallback(async (): Promise<boolean> => {
    const user = await getUser();
    return !!user && !!user.userId;
  }, [getUser]);

  const createNonActivatedUserIfNotExisted = async (
    firebase: firebase.User,
    email: Email
  ): Promise<UserId | undefined> => {
    await firebase.updateEmail(email);

    const fetchRequest = builder<FetchUserByExternalIdRequest>().externalId(firebase.uid).build();
    const fetchResponse = await UserRequest.fetchUserByExternalId(fetchRequest);
    if (!!fetchResponse.user) return fetchResponse.user.userId;

    const saveRequest = builder<SaveNewUserRequest>()
      .externalId(firebase.uid)
      .email(email)
      .isActivated(false)
      .photoURL(firebase.photoURL || EMPTY)
      .providerId(toProviderId(firebase))
      .build();

    const saveResponse = await UserRequest.saveNewUser(saveRequest);
    return saveResponse.userId;
  };

  return {
    createNonActivatedUserIfNotExisted,
    existFirebase,
    existUser,
    getFirebase,
    getUser
  } as P;
}

export default useUser;
