import {
  Button,
  CheckBox,
  CommentBox,
  Component,
  DateBox,
  InputBox,
  Scrollable,
  SelectBox,
  SingleLineText,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BeriefHistory,
  builder,
  Email,
  EMPTY,
  Name,
  Password,
  removeLinePrefix,
  SelfIntroduction,
  Subject,
  URL
} from '@atomica.co/utils';
import { CollegeDto, CollegeYear, SaveFirebaseRequest, SaveNewUserRequest, UserDiv } from '@atomica.co/yosemal-v2';
import { Typography } from '@material-ui/core';
import { CSSProperties } from '@material-ui/styles';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { ArrowForwardButton } from '../../../components/buttons/ArrowForwardButton';
import Logo from '../../../components/logo/Logo';
import UserPhotoUploader from '../../../components/polaroid/UserPhotoUploader';
import { HEADER_HEIGHT } from '../../../constants/common-constants';
import { auth } from '../../../firebase';
import UserRequest from '../../../requests/user-request';
import { COLLEGE_YEAR_LABELS, USER_DIV_LABELS } from '../../../texts/user-text';
import { RegisterIndex } from '../RegisterScreen';
import addPhoto from './../../../assets/icon/icon_photo_add.png';
import mojaco_greeting from './../../../assets/mojaco/mojaco_greeting_large.png';
import Remarks from './Remarks';

const getThirtyYearsAgo = (): Date => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 30);
  return date;
};

const MESSAGE =
  '入力してくれてありがとう！\n僕はモジャ公といいます！\nこれで最後！イベントで使うプロフィール画像を設定しよう！\n後から変更もできるよ！';

interface P {
  isSignedUpByEmail: boolean;
  email: Email;
  request: SaveNewUserRequest;
  onChange(request: SaveNewUserRequest): void;
  onSave(request: SaveNewUserRequest): void;
  goTo(index: RegisterIndex): void;
}

const InputAccountInfo: React.FC<P> = React.memo(props => {
  const { isSignedUpByEmail, email, request, onChange, onSave, goTo } = props;
  const unmountRef = useUnmountRef();
  const [saving, setSaving] = useSafeState<boolean>(unmountRef, false);
  const [isTermOfUseAgreed, setIsTermOfUseAgreed] = useSafeState<boolean>(unmountRef, false);
  const [passwordToSave, setPasswordToSave] = useSafeState<Password>(unmountRef, EMPTY);
  const [familyNameToSave, setFamilyNameToSave] = useSafeState<Name>(unmountRef, request.familyName);
  const [familyNameKanaToSave, setFamilyNameKanaToSave] = useSafeState<Name>(unmountRef, request.familyNameKana);
  const [firstNameToSave, setFirstNameToSave] = useSafeState<Name>(unmountRef, request.firstName);
  const [firstNameKanaToSave, setFirstNameKanaToSave] = useSafeState<Name>(unmountRef, request.firstNameKana);
  const [dateOfBirthToSave, setDateOfBirthToSave] = useSafeState<Date | null>(
    unmountRef,
    request.dateOfBirth || getThirtyYearsAgo()
  );
  const [userDivToSave, setUserDivToSave] = useSafeState<UserDiv>(unmountRef, request.userDiv);
  const [companyNameToSave, setCompanyNameToSave] = useSafeState<Name>(unmountRef, request.companyName);
  const [departmentNameToSave, setDepartmentNameToSave] = useSafeState<Name>(unmountRef, request.departmentName);
  const [titleToSave, setTitleToSave] = useSafeState<Name>(unmountRef, request.title);
  const [beriefHistoryToSave, setBeriefHistoryToSave] = useSafeState<BeriefHistory>(unmountRef, request.beriefHistory);
  const [collegeToSave, setCollegeToSave] = useSafeState<CollegeDto>(unmountRef, request.college);
  const [collegeYearToSave, setCollegeYearToSave] = useSafeState<CollegeYear>(unmountRef, request.collegeYear);
  const [facultyToSave, setFacultyToSave] = useSafeState<Name>(unmountRef, request.faculty); // FIXME
  const [departmentToSave, setDepartmentToSave] = useSafeState<Name>(unmountRef, request.department); // FIXME
  const [majorToSave, setMajorToSave] = useSafeState<Name>(unmountRef, request.major); // FIXME
  const [researchSubjectToSave, setResearchSubjectToSave] = useSafeState<Subject>(unmountRef, EMPTY);
  const [selfIntroductionToSave, setSelfIntroductionToSave] = useSafeState<SelfIntroduction>(
    unmountRef,
    request.selfIntroduction
  );
  const [emailToContactToSave, setEmailToContactToSave] = useSafeState<Email>(unmountRef, request.emailToContact);

  const showProfile = useMemo(() => {
    return (
      !!familyNameToSave &&
      !!familyNameKanaToSave &&
      !!firstNameToSave &&
      !!firstNameKanaToSave &&
      !!dateOfBirthToSave &&
      !!emailToContactToSave &&
      ((userDivToSave === UserDiv.WORKER && !!companyNameToSave && !!departmentNameToSave && !!titleToSave) ||
        (userDivToSave === UserDiv.STUDENT &&
          !!collegeToSave &&
          !!collegeYearToSave &&
          !!facultyToSave &&
          !!departmentToSave &&
          !!majorToSave))
    );
  }, [
    familyNameToSave,
    familyNameKanaToSave,
    firstNameToSave,
    firstNameKanaToSave,
    dateOfBirthToSave,
    emailToContactToSave,
    userDivToSave,
    companyNameToSave,
    departmentNameToSave,
    titleToSave,
    collegeToSave,
    collegeYearToSave,
    facultyToSave,
    departmentToSave,
    majorToSave
  ]);

  const enableToSave = useMemo<boolean>(() => {
    return (
      !!familyNameToSave &&
      !!familyNameKanaToSave &&
      !!firstNameToSave &&
      !!firstNameKanaToSave &&
      !!dateOfBirthToSave &&
      !!email &&
      !!emailToContactToSave &&
      ((userDivToSave === UserDiv.WORKER && !!companyNameToSave && !!departmentNameToSave && !!titleToSave) ||
        (userDivToSave === UserDiv.STUDENT &&
          !!collegeToSave &&
          !!collegeYearToSave &&
          !!facultyToSave &&
          !!departmentToSave &&
          !!majorToSave)) &&
      !!request.frameId &&
      isTermOfUseAgreed
    );
  }, [
    familyNameToSave,
    familyNameKanaToSave,
    firstNameToSave,
    firstNameKanaToSave,
    dateOfBirthToSave,
    email,
    emailToContactToSave,
    userDivToSave,
    companyNameToSave,
    departmentNameToSave,
    titleToSave,
    collegeToSave,
    collegeYearToSave,
    facultyToSave,
    departmentToSave,
    majorToSave,
    request,
    isTermOfUseAgreed
  ]);

  const handleFamilyNameChanged = useSafeCallback(
    (familyName: Name): void => {
      setFamilyNameToSave(familyName);
      onChange(Object.assign(request, { familyName }));
    },
    [setFamilyNameToSave, onChange, request]
  );

  const handleFamilyNameKanaChanged = useSafeCallback(
    (familyNameKana: Name): void => {
      setFamilyNameKanaToSave(familyNameKana);
      onChange(Object.assign(request, { familyNameKana }));
    },
    [setFamilyNameKanaToSave, onChange, request]
  );

  const handleFirstNameChanged = useSafeCallback(
    (firstName: Name): void => {
      setFirstNameToSave(firstName);
      onChange(Object.assign(request, { firstName }));
    },
    [setFirstNameToSave, onChange, request]
  );

  const handleFirstNameKanaChanged = useSafeCallback(
    (firstNameKana: Name): void => {
      setFirstNameKanaToSave(firstNameKana);
      onChange(Object.assign(request, { firstNameKana }));
    },
    [setFirstNameKanaToSave, onChange, request]
  );

  const handleDateOfBirthChanged = useSafeCallback(
    (dateOfBirth: Date): void => {
      setDateOfBirthToSave(dateOfBirth);
      onChange(Object.assign(request, { dateOfBirth }));
    },
    [setDateOfBirthToSave, onChange, request]
  );

  const handleEmailToContactChanged = useSafeCallback(
    (emailToContact: Email): void => {
      setEmailToContactToSave(emailToContact);
      onChange(Object.assign(request, { emailToContact }));
    },
    [setEmailToContactToSave, onChange, request]
  );

  const handlePhotoURLChanged = useSafeCallback(
    (photoURL: URL): void => {
      onChange(Object.assign(request, { photoURL: photoURL }));
    },
    [onChange, request]
  );

  const handleUserDivChanged = useSafeCallback(
    (userDiv: UserDiv): void => {
      setUserDivToSave(userDiv);
      onChange(Object.assign(request, { userDiv }));
    },
    [setUserDivToSave, onChange, request]
  );

  const handleCompanyNameChanged = useSafeCallback(
    (companyName: Name): void => {
      setCompanyNameToSave(companyName);
      onChange(Object.assign(request, { companyName }));
    },
    [setCompanyNameToSave, onChange, request]
  );

  const handleDepartmentNameChanged = useSafeCallback(
    (departmentName: Name): void => {
      setDepartmentNameToSave(departmentName);
      onChange(Object.assign(request, { departmentName }));
    },
    [setDepartmentNameToSave, onChange, request]
  );

  const handleTitleChanged = useSafeCallback(
    (title: Name): void => {
      setTitleToSave(title);
      onChange(Object.assign(request, { title }));
    },
    [setTitleToSave, onChange, request]
  );

  const handleBeriefHistoryChanged = useSafeCallback(
    (beriefHistory: BeriefHistory): void => {
      setBeriefHistoryToSave(beriefHistory);
      onChange(Object.assign(request, { beriefHistory }));
    },
    [setBeriefHistoryToSave, onChange, request]
  );

  const handleCollegeChanged = useSafeCallback(
    (collge: CollegeDto): void => {
      setCollegeToSave(collge);
      onChange(Object.assign(request, { collge }));
    },
    [setCollegeToSave, onChange, request]
  );

  const handleCollegeYearChanged = useSafeCallback(
    (collegeYear: CollegeYear): void => {
      setCollegeYearToSave(collegeYear);
      onChange(Object.assign(request, { collegeYear }));
    },
    [setCollegeYearToSave, onChange, request]
  );

  const handleFacultyChanged = useSafeCallback(
    (faculty: Name): void => {
      setFacultyToSave(faculty);
      onChange(Object.assign(request, { faculty }));
    },
    [setFacultyToSave, onChange, request]
  );

  const handleDepartmentChanged = useSafeCallback(
    (department: Name): void => {
      setDepartmentToSave(department);
      onChange(Object.assign(request, { department }));
    },
    [setDepartmentToSave, onChange, request]
  );

  const handleMajorChanged = useSafeCallback(
    (major: Name): void => {
      setMajorToSave(major);
      onChange(Object.assign(request, { major }));
    },
    [setMajorToSave, onChange, request]
  );

  const handleResearchSubjectChanged = useSafeCallback(
    (researchSubject: Subject): void => {
      setResearchSubjectToSave(researchSubject);
      onChange(Object.assign(request, { researchSubject }));
    },
    [setResearchSubjectToSave, onChange, request]
  );

  const handleSelfIntroductionChanged = useSafeCallback(
    (selfIntroduction: SelfIntroduction): void => {
      setSelfIntroductionToSave(selfIntroduction);
      onChange(Object.assign(request, { selfIntroduction }));
    },
    [setSelfIntroductionToSave, onChange, request]
  );

  const handleSaveButtonClicked = useSafeCallback(async (): Promise<void> => {
    setSaving(true);

    if (!isSignedUpByEmail) {
      onSave(request);
      return;
    }
    const firebaseRequest = builder<SaveFirebaseRequest>().email(email).password(passwordToSave).build();
    const firebaseResponse = await UserRequest.saveFirebase(firebaseRequest);
    const customToken = firebaseResponse.customToken;
    await auth.signInWithCustomToken(customToken!);
    onSave(request);
  }, [setSaving, isSignedUpByEmail, onSave, request, email, passwordToSave]);

  return (
    <Component loading={saving} style={styleForComponent} className='input-account-info'>
      <Container>
        <Scrollable>
          <LogoWrapper>
            <Logo />
          </LogoWrapper>

          <EmailWrapper>
            <SingleLineText style={styleForEmail} text={removeLinePrefix(email)} />
          </EmailWrapper>

          {isSignedUpByEmail && (
            <PasswordWrapper>
              <InputBox
                required
                type='password'
                label='パスワード'
                text={passwordToSave}
                onChange={setPasswordToSave}
              />
            </PasswordWrapper>
          )}

          <FullNameWrapper>
            <NameWrapper>
              <InputBox required maxLength={15} label='姓' text={familyNameToSave} onChange={handleFamilyNameChanged} />
            </NameWrapper>

            <NameWrapper>
              <InputBox required maxLength={15} label='名' text={firstNameToSave} onChange={handleFirstNameChanged} />
            </NameWrapper>
          </FullNameWrapper>

          <FullNameWrapper>
            <NameWrapper>
              <InputBox
                required
                maxLength={15}
                label='姓（ふりがな）'
                text={familyNameKanaToSave}
                onChange={handleFamilyNameKanaChanged}
              />
            </NameWrapper>

            <NameWrapper>
              <InputBox
                required
                maxLength={15}
                label='名（ふりがな）'
                text={firstNameKanaToSave}
                onChange={handleFirstNameKanaChanged}
              />
            </NameWrapper>
          </FullNameWrapper>

          <DateWrapper>
            <DateBox
              required
              label='生年月日'
              maxDate={new Date()}
              value={dateOfBirthToSave}
              onChange={handleDateOfBirthChanged}
            />
          </DateWrapper>

          <InputWrapper>
            <InputBox
              required
              label='メールアドレス'
              text={emailToContactToSave}
              onChange={handleEmailToContactChanged}
            />
          </InputWrapper>

          <SelectWrapper>
            <SelectBox
              required
              label='区分'
              options={Object.values(UserDiv)}
              labels={USER_DIV_LABELS}
              value={userDivToSave}
              onChange={handleUserDivChanged}
            />
          </SelectWrapper>

          {userDivToSave === UserDiv.WORKER && (
            <>
              <InputWrapper>
                <InputBox required label='法人名' text={companyNameToSave} onChange={handleCompanyNameChanged} />
              </InputWrapper>

              <InputWrapper>
                <InputBox required label='部署名' text={departmentNameToSave} onChange={handleDepartmentNameChanged} />
              </InputWrapper>

              <InputWrapper>
                <InputBox required label='肩書き' text={titleToSave} onChange={handleTitleChanged} />
              </InputWrapper>

              <InputWrapper>
                <InputBox
                  multiline
                  maxLength={1200}
                  label='略歴'
                  text={beriefHistoryToSave}
                  onChange={handleBeriefHistoryChanged}
                />
              </InputWrapper>
            </>
          )}

          {userDivToSave === UserDiv.STUDENT && (
            <>
              <InputWrapper>
                <InputBox
                  required
                  editable={false}
                  label='大学名'
                  text={collegeToSave.name}
                  onChange={handleCollegeChanged}
                />
              </InputWrapper>

              <SelectWrapper>
                <SelectBox
                  required
                  label='学年'
                  options={Object.values(CollegeYear)}
                  labels={COLLEGE_YEAR_LABELS}
                  value={collegeYearToSave}
                  onChange={handleCollegeYearChanged}
                />
              </SelectWrapper>

              <InputWrapper>
                <InputBox required label='学部 / 研究科名' text={facultyToSave} onChange={handleFacultyChanged} />
              </InputWrapper>

              <InputWrapper>
                <InputBox required label='学科 / 専攻名' text={departmentToSave} onChange={handleDepartmentChanged} />
              </InputWrapper>

              <InputWrapper>
                <InputBox required label='専攻 / コース' text={majorToSave} onChange={handleMajorChanged} />
              </InputWrapper>

              <InputWrapper>
                <InputBox
                  multiline
                  maxLength={1200}
                  label='研究テーマ'
                  text={researchSubjectToSave}
                  onChange={handleResearchSubjectChanged}
                />
              </InputWrapper>

              <InputWrapper>
                <InputBox
                  multiline
                  maxLength={1200}
                  label='自己PR'
                  text={selfIntroductionToSave}
                  onChange={handleSelfIntroductionChanged}
                />
              </InputWrapper>
            </>
          )}

          {showProfile && (
            <>
              <BoxWrapper>
                <CommentBox animation photoURL={mojaco_greeting}>
                  <Greeting>{MESSAGE}</Greeting>
                </CommentBox>
              </BoxWrapper>

              <UploaderWrapper>
                <UserPhotoUploader
                  initPhotoURL={request.photoURL}
                  frameId={request.frameId}
                  name={`${familyNameToSave} ${firstNameToSave}`}
                  dateOfBirth={dateOfBirthToSave!}
                  onUpload={handlePhotoURLChanged}
                />

                <AddPhotoButton>
                  <AddPhotoIcon src={addPhoto} />
                </AddPhotoButton>
              </UploaderWrapper>

              <ArrowButtonWrapper>
                <ArrowForwardButton
                  shape='rect'
                  text='フレームを選択する'
                  onClick={() => goTo(RegisterIndex.SELECT_USER_FRAME)}
                />
              </ArrowButtonWrapper>
            </>
          )}

          <Agreement>
            <CheckBox checked={isTermOfUseAgreed} onChange={() => setIsTermOfUseAgreed(checked => !checked)}>
              <Remarks />
            </CheckBox>
          </Agreement>

          <ButtonWrapper>
            <Button type='primary' disabled={!enableToSave} onClick={handleSaveButtonClicked}>
              <Label>完了</Label>
            </Button>
          </ButtonWrapper>

          <Bottom />
        </Scrollable>
      </Container>
    </Component>
  );
});

export default InputAccountInfo;

const styleForComponent: CSSProperties = {
  minHeight: 'calc(100vh - 56px)',
  display: 'flex',
  alignItems: 'center'
};

const Container = styled.div`
  width: 100%;
  height: calc(100vh - ${HEADER_HEIGHT}px);
`;

const LogoWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: ${theme.mixins.spacing * 2}px;
`;

const EmailWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const styleForEmail: CSSProperties = {
  color: theme.mixins.typography.fontColor.black,
  fontSize: theme.mixins.typography.fontSize.twenty,
  fontWeight: theme.mixins.typography.fontWeight.fourHundreds,
  fontFamily: theme.mixins.typography.fontFamily,
  textAlign: 'center'
};

const PasswordWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px;
`;

const FullNameWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  padding: ${theme.mixins.spacing}px;
`;

const NameWrapper = styled.div`
  width: calc(50% - ${theme.mixins.spacing}px);
  height: 56px;
`;

const DateWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: ${theme.mixins.spacing}px;
`;

const InputWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px;
`;

const SelectWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px;
`;

const BoxWrapper = styled.div`
  padding: ${theme.mixins.spacing * 3}px ${theme.mixins.spacing}px ${theme.mixins.spacing}px;
`;

const Greeting = styled(Typography)`
  width: 100%;
  height: auto;
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds} !important;
  font-family: ${theme.mixins.typography.fontFamily};
  margin: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
  white-space: pre-wrap;
  ${theme.mixins.underline};
`;

const UploaderWrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  padding: ${theme.mixins.spacing / 2}px ${theme.mixins.spacing}px ${theme.mixins.spacing / 4}px;
`;

const AddPhotoButton = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background: ${theme.mixins.background.black};
  opacity: 0.6;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 20px;
  bottom: 64px;
`;

const AddPhotoIcon = styled.img`
  width: 40px;
  height: 40px;
`;

const ArrowButtonWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing / 4}px ${theme.mixins.spacing}px ${theme.mixins.spacing}px;
`;

const Agreement = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing * 3}px ${theme.mixins.spacing * 2}px ${theme.mixins.spacing}px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
  margin-bottom: ${theme.mixins.spacing * 10}px;
`;

const Label = styled(Typography)`
  width: 120px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Bottom = styled.div`
  margin-bottom: ${theme.mixins.spacing * 20}px;
`;
