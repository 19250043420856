import {
  Button,
  Component,
  InputBox,
  styleForFullExpansion,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { builder, EMPTY, Name } from '@atomica.co/utils';
import { SaveUserDepartmentRequest, UserId } from '@atomica.co/yosemal-v2';
import { default as React } from 'react';
import styled from 'styled-components';
import { HEADER_HEIGHT } from '../../../../../constants/common-constants';
import UserRequest from '../../../../../requests/user-request';
import InputProfileHeader from '../../../account-header/InputProfileHeader';
import { EditProfileIndex } from '../EditProfile';

interface P {
  userId: UserId | undefined;
  department: Name | undefined;
  onSave(department: Name): void;
  goTo(index: EditProfileIndex): void;
}

const SelectDepartment: React.FC<P> = React.memo(props => {
  const { userId, department: initDepartment = EMPTY, onSave, goTo } = props;

  const unmountRef = useUnmountRef();
  const [disabledSaveButton, setDisabledSaveButton] = useSafeState<boolean>(unmountRef, false);
  const [departmentToSave, setDepartmentToSave] = useSafeState<Name>(unmountRef, initDepartment);

  const saveDepartment = useSafeCallback(async (): Promise<void> => {
    if (!userId) return;
    setDisabledSaveButton(true);

    const request = builder<SaveUserDepartmentRequest>().userId(userId).department(departmentToSave).build();
    await UserRequest.saveDepartment(request);

    onSave(departmentToSave);
  }, [setDisabledSaveButton, userId, departmentToSave, onSave]);

  return (
    <Component style={styleForFullExpansion} className='select-department'>
      <HeaderArea>
        <InputProfileHeader label='学科 / 専攻' onClick={goTo} />
      </HeaderArea>

      <Body>
        <InputWrapper>
          <InputBox showCount shape='rect' text={departmentToSave} onChange={setDepartmentToSave} />
        </InputWrapper>

        <ButtonArea>
          <Button type='primary' disabled={disabledSaveButton} onClick={saveDepartment}>
            <StyledLabel>保存</StyledLabel>
          </Button>
        </ButtonArea>
      </Body>
    </Component>
  );
});

export default SelectDepartment;

const HeaderArea = styled.div`
  width: 100%;
  height: ${HEADER_HEIGHT}px;
`;

const Body = styled.div`
  width: 100%;
  height: calc(100% - 48px);
`;

const InputWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing * 4}px ${theme.mixins.spacing * 2}px;
`;

const ButtonArea = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
`;

const StyledLabel = styled.div`
  width: 80px;
  height: auto;
`;
