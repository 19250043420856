import {
  Component,
  Icon,
  IconTab,
  IconTabs,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { embedIdInPath, Index } from '@atomica.co/utils';
import { Confirmation, toNewOverallNotificationsPath, UserEntity } from '@atomica.co/yosemal-v2';
import React, { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { toFooterTab } from '../../../converters/path-converter';
import { database } from '../../../firebase';
import usePath from '../../../redux/hooks/usePath';
import { Path, PATH_IDS } from '../../../router/Routes';
import { isBoardPath } from '../../../utils/path-util';
import account from './../../../assets/icon/icon_account.png';
import connections from './../../../assets/icon/icon_connections.png';
import home from './../../../assets/icon/icon_home.png';
import notification from './../../../assets/icon/icon_notification.png';

export enum FooterTabEnum {
  HOME,
  // WISH,
  CONNECTIONS,
  NOTIFICATIONS,
  ACCOUNT,
  OUT_OF_TARGET
}

const getTabs = (showBadge: boolean = false): IconTab[] => [
  {
    id: FooterTabEnum.HOME,
    icon: <Icon size='small' src={home} />
  },
  // {
  //   id: FooterTabEnum.WISH,
  //   icon: <Icon size="small" src={star} />,
  // },
  {
    id: FooterTabEnum.CONNECTIONS,
    icon: <Icon size='small' src={connections} />
  },
  {
    id: FooterTabEnum.NOTIFICATIONS,
    showBadge,
    icon: <Icon size='small' src={notification} />
  },
  {
    id: FooterTabEnum.ACCOUNT,
    icon: <Icon size='small' src={account} />
  }
];

interface P {
  user: UserEntity | undefined;
}

const FooterTabs: React.FC<P> = React.memo(props => {
  const { user } = props;
  const cashedBoardPath = useRef<Path>(Path.BOARD_LIST);
  const { path, openPath } = usePath();
  const unmountRef = useUnmountRef();
  const index = useMemo<Index>(() => toFooterTab(path), [path]);
  const [tabs, setTabs] = useSafeState<IconTab[]>(unmountRef, getTabs());

  useEffect(() => {
    if (!user) return;
    const ref = database.ref(toNewOverallNotificationsPath(user.userId));
    ref.on('value', snapshot => {
      const confirmation: Confirmation | undefined = snapshot.val();
      setTabs(getTabs(!!confirmation && confirmation.isConfirmed === false));
    });
  }, [user, setTabs]);

  const handleTabChanged = useSafeCallback(
    async (tab: IconTab): Promise<void> => {
      if (isBoardPath(path)) {
        cashedBoardPath.current = window.location.pathname as Path;
      }

      switch (tab.id) {
        case FooterTabEnum.HOME:
          openPath(isBoardPath(path) ? Path.BOARD_LIST : cashedBoardPath.current);
          break;

        case FooterTabEnum.CONNECTIONS:
          openPath(Path.CONNECTIONS);
          break;

        case FooterTabEnum.NOTIFICATIONS:
          openPath(Path.NOTIFICATIONS);
          break;

        case FooterTabEnum.ACCOUNT:
          !!user && openPath(embedIdInPath(Path.ACCOUNT, PATH_IDS, [user.userId]));
          break;
      }
    },
    [path, user, openPath]
  );

  return (
    <Component className='footer-tabs'>
      <Container>
        {index !== FooterTabEnum.OUT_OF_TARGET && (
          <IconTabs selected={tabs[index]} tabs={tabs} onChange={handleTabChanged} />
        )}
      </Container>
    </Component>
  );
});

export default FooterTabs;

const Container = styled.div`
  width: 288px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
