import { AnimatedModal, getMobileWidth, theme, useSafeState, useUnmountRef } from '@atomica.co/components';
import { Height } from '@atomica.co/utils';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

interface P {
  isOpen: boolean;
  children: React.ReactNode;
  onClose(): void;
}

const FullModal: React.FC<P> = React.memo(props => {
  const { isOpen, children, onClose } = props;
  const timeout = useRef<NodeJS.Timeout>();
  const unmountRef = useUnmountRef();
  const [showChildren, setShowChildren] = useSafeState<boolean>(unmountRef, isOpen);

  useEffect(() => {
    if (isOpen) {
      !!timeout.current && clearTimeout(timeout.current);
      setShowChildren(isOpen);
    } else {
      timeout.current = setTimeout(() => setShowChildren(isOpen), 1000);
    }
  }, [isOpen, setShowChildren]);

  return (
    <AnimatedModal isOpen={isOpen} height={window.innerHeight} onClose={onClose}>
      <Container>
        <Frame height={window.innerHeight}>{showChildren && children}</Frame>
      </Container>
    </AnimatedModal>
  );
});

export default FullModal;

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  ${theme.mixins.userSelectDisabled};
`;

const Frame = styled.div<{ height: Height }>`
  width: ${getMobileWidth()}px;
  height: ${props => props.height}px;
  background: ${theme.mixins.background.white};
`;
