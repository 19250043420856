import { Button, Component, Icon, theme, useSafeCallback } from '@atomica.co/components';
import { Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import line from '../../../../../assets/icon/icon_line.png';
import { Process } from '../../../../../enums/common-enum';
import { constructLineLoginURL } from '../../../../../line';
import useProcess from '../../../../../redux/hooks/useProcess';

interface P {}

const LineSignIn: React.FC<P> = React.memo(() => {
  const { setProcess } = useProcess();

  const openLineLoginPage = useSafeCallback((): void => {
    setProcess(Process.PROCESSING);
    const lineLoginURL = constructLineLoginURL();
    window.location.href = lineLoginURL;
  }, [setProcess]);

  return (
    <Component className='line-sign-in'>
      <ButtonWrapper>
        <Button type='default' onClick={openLineLoginPage}>
          <IconWrapper>
            <Icon size='xsmall' src={line} />
          </IconWrapper>
          <Label>LINEでログイン</Label>
        </Button>
      </ButtonWrapper>
    </Component>
  );
});

export default LineSignIn;

const ButtonWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: ${theme.mixins.spacing / 2}px;
`;

const IconWrapper = styled.div`
  width: 48px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Label = styled(Typography)`
  width: 200px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
