import { URL } from '@atomica.co/utils';
import { CashedURLActionEnum } from '../../enums/action-enum';
import { CashedURLAction } from '../../models/action-model';

export const toCashedURLAction = (cashedURL: URL): CashedURLAction => {
  return {
    type: CashedURLActionEnum.SET,
    cashedURL
  };
};
