import { Component, Icon, theme } from '@atomica.co/components';
import { Label } from '@atomica.co/utils';
import { CSSProperties } from '@material-ui/styles';
import React from 'react';
import styled from 'styled-components';
import Header from '../../../components/header/Header';
import arrow_left from './../../../assets/icon/icon_arrow_left.png';

interface P {
  label: Label;
  goBack(): void;
}

const BoardMessageFrameHeader: React.FC<P> = React.memo(props => {
  const { label, goBack } = props;

  return (
    <Component className='board-message-frame-header'>
      <Header
        label={label}
        leftChild={
          <IconsWrapper>
            <IconWrapper onClick={goBack}>
              <Icon style={styleForIcon} src={arrow_left} />
            </IconWrapper>
          </IconsWrapper>
        }
        rightChild={
          <IconsWrapper>
            <IconWrapper />
          </IconsWrapper>
        }
      />
    </Component>
  );
});

export default BoardMessageFrameHeader;

const IconsWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  padding: ${theme.mixins.spacing}px;
`;

const IconWrapper = styled.div`
  width: 40px;
  height: auto;
  padding: ${theme.mixins.spacing}px;
`;

const styleForIcon: CSSProperties = {
  width: 24,
  height: 24
};
