import {
  Component,
  getMobileWidth,
  MultiLineText,
  SingleLineText,
  theme,
  useSafeCallback
} from '@atomica.co/components';
import { builder, embedIdInPath, getTimeDiffLabel, Width } from '@atomica.co/utils';
import { BoardThreadEntity, UserEntity } from '@atomica.co/yosemal-v2';
import { CSSProperties } from '@material-ui/styles';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import usePath from '../../redux/hooks/usePath';
import { Path, PATH_IDS } from '../../router/Routes';
import UserPolaroid from './UserPolaroid';

export type Rotate = 'none' | 'right' | 'left';

interface P {
  rotate: Rotate;
  thread: BoardThreadEntity | undefined;
}

const BoardThreadPolaroid: React.FC<P> = React.memo(props => {
  const { rotate, thread } = props;
  const { openPath } = usePath();
  const cardWidth = useMemo<Width | undefined>(() => getMobileWidth() - theme.mixins.spacing * 11, []);

  const handleUserPolaroidClicked = useSafeCallback((): void => {
    if (!thread) return;
    openPath(embedIdInPath(Path.ACCOUNT, PATH_IDS, [thread.userId]));
  }, [thread, openPath]);

  return (
    <Component className='board-thread-polaroid'>
      <Container width={cardWidth}>
        <PolaroidWrapper onClick={handleUserPolaroidClicked}>
          <UserPolaroid
            size='small'
            rotate='right'
            frameId={!!thread ? thread.frameId : undefined}
            user={
              !!thread
                ? builder<UserEntity>()
                    .familyName(thread.familyName)
                    .firstName(thread.firstName)
                    .photoURL(thread.photoURL)
                    .build()
                : undefined
            }
          />
        </PolaroidWrapper>

        <Card rotate={rotate} width={cardWidth}>
          <TextWrapperForMessage>
            <MultiLineText style={styleForMessage} text={!!thread ? thread.text : undefined} />
          </TextWrapperForMessage>

          <TextWrapperForDate>
            <SingleLineText style={styleForDate} text={!!thread ? getTimeDiffLabel(thread.createdAt) : undefined} />
          </TextWrapperForDate>
        </Card>

        {/* {!!frame &&
         !!message &&
         !!message.photoURL &&
          <Stamp
            frame={frame}
            src={frame.src}
          />      
        } */}
      </Container>
    </Component>
  );
});

export default BoardThreadPolaroid;

const Container = styled.div<{ width: Width }>`
  width: ${props => props.width}px;
  height: auto;
  display: flex;
  justify-content: center;
`;

const PolaroidWrapper = styled.div``;

const Card = styled.div<{
  rotate: Rotate;
  width: Width;
}>`
  width: 100%;
  height: auto;
  min-height: 120px;
  border: ${theme.mixins.border.lightGray};
  background: ${theme.mixins.background.white};
  box-shadow: ${theme.mixins.shadow.light};
  display: flex;
  flex-flow: column;
  margin-top: -${theme.mixins.spacing / 2}px;
  margin-left: -${theme.mixins.spacing / 2}px;
`;

const TextWrapperForMessage = styled.div`
  width: 100%;
  height: 100%;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing}px ${theme.mixins.spacing / 2}px;
`;

const styleForMessage: CSSProperties = {
  width: '100%',
  height: 'auto',
  color: theme.mixins.typography.fontColor.black,
  fontSize: theme.mixins.typography.fontSize.sixteen,
  fontWeight: theme.mixins.typography.fontWeight.fourHundreds,
  fontFamily: theme.mixins.typography.fontFamily,
  lineHeight: 1.25,
  wordBreak: 'break-all'
};

const TextWrapperForDate = styled.div`
  width: calc(100% - ${theme.mixins.spacing * 2}px);
  height: 24px;
  border-top: 1px solid ${theme.mixins.border.lightGray};
  padding-top: ${theme.mixins.spacing / 2}px;
  margin: 0px ${theme.mixins.spacing}px ${theme.mixins.spacing}px;
  ${theme.mixins.userSelectDisabled};
`;

const styleForDate: CSSProperties = {
  width: '100%',
  height: 16,
  color: theme.mixins.typography.fontColor.lightGray,
  fontSize: theme.mixins.typography.fontSize.twelve,
  fontWeight: theme.mixins.typography.fontWeight.fourHundreds,
  fontFamily: theme.mixins.typography.fontFamily
};
