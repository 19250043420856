import { useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { builder } from '@atomica.co/utils';
import { BoardPdfEntity, BOARD_ID, FetchBoardPdfRequest } from '@atomica.co/yosemal-v2';
import { PDFViewer } from '@react-pdf/renderer';
import React, { useEffect } from 'react';
import Screen from '../../components/screen/Screen';
import usePath from '../../redux/hooks/usePath';
import BoardRequest from '../../requests/board-request';
import BoardPagesPdf from './BoardPagesPdf';

interface P {}

const BoardExportScreen: React.FC<P> = React.memo(() => {
  const { params } = usePath();
  const unmountRef = useUnmountRef();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [board, setBoard] = useSafeState<BoardPdfEntity>(unmountRef);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const boardId = params[BOARD_ID];
    const request = builder<FetchBoardPdfRequest>().boardId(boardId!).build();
    const response = await BoardRequest.fetchBoardPdf(request);
    setBoard(response.board!);
    setLoaded(true);
  }, [params, setBoard, setLoaded]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <Screen loading={!loaded} className='board-export-screen'>
      <PDFViewer style={{ width: '100vw', height: '100vh' }}>
        <BoardPagesPdf board={board} />
      </PDFViewer>
    </Screen>
  );
});

export default BoardExportScreen;
