import {
  Button,
  Component,
  InputBox,
  styleForFullExpansion,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { builder, EMPTY, Name } from '@atomica.co/utils';
import { SaveUserTitleRequest, UserId } from '@atomica.co/yosemal-v2';
import { default as React } from 'react';
import styled from 'styled-components';
import { HEADER_HEIGHT } from '../../../../../constants/common-constants';
import UserRequest from '../../../../../requests/user-request';
import InputProfileHeader from '../../../account-header/InputProfileHeader';
import { EditProfileIndex } from '../EditProfile';

interface P {
  userId: UserId | undefined;
  title: Name | undefined;
  onSave(title: Name): void;
  goTo(index: EditProfileIndex): void;
}

const InputTitle: React.FC<P> = React.memo(props => {
  const { userId, title: initTitle = EMPTY, onSave, goTo } = props;

  const unmountRef = useUnmountRef();
  const [disabledSaveButton, setDisabledSaveButton] = useSafeState<boolean>(unmountRef, false);
  const [titleToSave, setTitleToSave] = useSafeState<Name>(unmountRef, initTitle);

  const saveTitle = useSafeCallback(async (): Promise<void> => {
    if (!userId) return;
    setDisabledSaveButton(true);

    const request = builder<SaveUserTitleRequest>().userId(userId).title(titleToSave).build();
    await UserRequest.saveTitle(request);

    onSave(titleToSave);
  }, [setDisabledSaveButton, userId, titleToSave, onSave]);

  return (
    <Component style={styleForFullExpansion} className='input-title'>
      <HeaderArea>
        <InputProfileHeader label='肩書き' onClick={goTo} />
      </HeaderArea>

      <Body>
        <InputWrapper>
          <InputBox showCount shape='rect' text={titleToSave} onChange={setTitleToSave} />
        </InputWrapper>

        <ButtonArea>
          <Button type='primary' disabled={disabledSaveButton} onClick={saveTitle}>
            <StyledLabel>保存</StyledLabel>
          </Button>
        </ButtonArea>
      </Body>
    </Component>
  );
});

export default InputTitle;

const HeaderArea = styled.div`
  width: 100%;
  height: ${HEADER_HEIGHT}px;
`;

const Body = styled.div`
  width: 100%;
  height: calc(100% - 48px);
`;

const InputWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing * 4}px ${theme.mixins.spacing * 2}px;
`;

const ButtonArea = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
`;

const StyledLabel = styled.div`
  width: 80px;
  height: auto;
`;
