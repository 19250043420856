import { Component, theme } from '@atomica.co/components';
import { Label } from '@atomica.co/utils';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import React from 'react';
import styled from 'styled-components';
import Header from '../../../components/header/Header';
import { EditProfileIndex } from '../account-full-modal/edit-profile/EditProfile';

interface P {
  label: Label;
  onClick(index: EditProfileIndex): void;
}

const InputProfileHeader: React.FC<P> = React.memo(props => {
  const { label, onClick } = props;

  return (
    <Component className='input-profile-header'>
      <Header
        label={label}
        leftChild={
          <LeftArea>
            <ButtonWrapper>
              <Button onClick={() => onClick(EditProfileIndex.EDIT_PROFILE_MAIN)}>
                <BackIcon />
              </Button>
            </ButtonWrapper>
          </LeftArea>
        }
        rightChild={<RightArea />}
      />
    </Component>
  );
});

export default InputProfileHeader;

const LeftArea = styled.div`
  width: 88px;
  height: auto;
  padding-left: ${theme.mixins.spacing}px;
`;

const RightArea = styled.div`
  width: 88px;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding-right: ${theme.mixins.spacing}px;
`;

const ButtonWrapper = styled.div<{ show: boolean }>`
  width: 40px;
  height: auto;

  &:after {
    width: 40px;
    height: 6px;
    content: '';
    display: ${props => (props.show ? 'block' : 'none')};
    background-color: ${theme.mixins.border.pink};
    border-radius: 3px;
  }
`;

const Button = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding-top: ${theme.mixins.spacing}px;
`;

const BackIcon = styled(ArrowBackIosOutlinedIcon)`
  color: ${theme.mixins.typography.fontColor.lightGray};
`;
