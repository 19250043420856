import { Modal, theme, useSafeState, useUnmountRef } from '@atomica.co/components';
import { Height } from '@atomica.co/utils';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

interface P {
  isOpen: boolean;
  height?: Height;
  children: React.ReactNode;
  onClose(): void;
}

const FixedModal: React.FC<P> = React.memo(props => {
  const { isOpen, height, children, onClose } = props;
  const timeout = useRef<NodeJS.Timeout>();
  const unmountRef = useUnmountRef();
  const [showChildren, setShowChildren] = useSafeState<boolean>(unmountRef, isOpen);

  useEffect(() => {
    if (isOpen) {
      !!timeout.current && clearTimeout(timeout.current);
      setShowChildren(isOpen);
    } else {
      timeout.current = setTimeout(() => setShowChildren(isOpen), 1000);
    }
  }, [isOpen, setShowChildren]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Frame height={height}>{showChildren && children}</Frame>
    </Modal>
  );
});

export default FixedModal;

const Frame = styled.div<{ height: Height }>`
  width: auto;
  height: ${props => props.height}px;
  background: ${theme.mixins.background.white};
  border-radius: 4px;
  padding: ${theme.mixins.spacing / 2}px;
  ${theme.mixins.userSelectDisabled};
`;
