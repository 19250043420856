import { useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { getParams, getPath, getQueryParams, PathStr } from '@atomica.co/utils';
import { useEffect, useRef } from 'react';
import { Params, QueryParams } from '../../models/path-model';
import { Path, PATH_IDS } from '../../router/Routes';

const PATH_CHANGED_EVENT = 'pathchanged';

const POP_STATE_EVENT = 'popstate';

const IGNORE_DIRECTORIES: PathStr[] = ['connections', 'notifications'];

interface P {
  path: Path;
  params: Params;
  queryParams: QueryParams;
  openPath: (path: Path) => void;
  openPathInNewTab: (path: Path) => void;
  addBrowserBackEventListener: () => void;
  removeBrowserBackEventListener: () => void;
  goBack: () => void;
}

function usePath() {
  const unmountRef = useUnmountRef();
  const previousPathName = useRef<PathStr>(window.location.pathname);
  const [path, setPath] = useSafeState<Path>(
    unmountRef,
    getPath<Path>(Object.values(Path), PATH_IDS, previousPathName.current)
  );
  const [params, setParams] = useSafeState<Params>(
    unmountRef,
    getParams<Params>(Object.values(Path), PATH_IDS, window.location.pathname)
  );
  const [queryParams, setQueryParams] = useSafeState<QueryParams>(
    unmountRef,
    getQueryParams<QueryParams>(window.location.search)
  );

  const updatePathInfo = useSafeCallback((): void => {
    const nextPathName = window.location.pathname;
    if (nextPathName === previousPathName.current) return;

    const paths = Object.values(Path);
    previousPathName.current = nextPathName;

    const nextPath = getPath<Path>(paths, PATH_IDS, nextPathName);
    !!nextPath && setPath(nextPath);

    const nextParams = getParams<Params>(paths, PATH_IDS, window.location.pathname, IGNORE_DIRECTORIES);
    setParams(nextParams);

    const nextQueryParams = getQueryParams<QueryParams>(window.location.search);
    setQueryParams(nextQueryParams);
  }, [setPath, setParams, setQueryParams]);

  useEffect(() => {
    window.addEventListener(PATH_CHANGED_EVENT, updatePathInfo);
    return () => window.removeEventListener(PATH_CHANGED_EVENT, updatePathInfo);
  }, [updatePathInfo]);

  const dispatchPathChangedEvent = useSafeCallback((): void => {
    window.dispatchEvent(new Event(PATH_CHANGED_EVENT));
  }, []);

  const openPath = useSafeCallback(
    (path: Path): void => {
      if (!path) return;
      window.history.pushState('', '', path);
      dispatchPathChangedEvent();
    },
    [dispatchPathChangedEvent]
  );

  const openPathInNewTab = useSafeCallback((path: Path): void => {
    if (!path) return;
    window.open(`${window.location.origin}${path}`, 'newtab');
  }, []);

  const goBack = useSafeCallback((): void => {
    window.history.back();
  }, []);

  const addBrowserBackEventListener = useSafeCallback((): void => {
    window.addEventListener(POP_STATE_EVENT, dispatchPathChangedEvent);
  }, [dispatchPathChangedEvent]);

  const removeBrowserBackEventListener = useSafeCallback((): void => {
    window.removeEventListener(POP_STATE_EVENT, dispatchPathChangedEvent);
  }, [dispatchPathChangedEvent]);

  return {
    path,
    params,
    queryParams,
    openPath,
    openPathInNewTab,
    addBrowserBackEventListener,
    removeBrowserBackEventListener,
    goBack
  } as P;
}

export default usePath;
