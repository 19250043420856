import {
  Button,
  Component,
  InputBox,
  styleForFullExpansion,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { builder, EMPTY, Request } from '@atomica.co/utils';
import { BoardEntity, SaveBoardRequestForNewPhotoRequest } from '@atomica.co/yosemal-v2';
import { Typography } from '@material-ui/core';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import { default as React } from 'react';
import styled from 'styled-components';
import BoardRequest from '../../../../../requests/board-request';

interface P {
  board: BoardEntity | undefined;
  onSaveRequestForNewPhoto(requestForNewPhoto: Request): void;
  goBack(): void;
}

const InputRequestForNewPhoto: React.FC<P> = React.memo(props => {
  const { board, onSaveRequestForNewPhoto, goBack } = props;
  const unmountRef = useUnmountRef();
  const [disabledSaveButton, setDisabledSaveButton] = useSafeState<boolean>(unmountRef, false);
  const [requestForNewPhotoToSave, setRequestForNewPhotoToSave] = useSafeState<Request>(
    unmountRef,
    !!board ? board.requestForNewPhoto : EMPTY
  );

  const saveRequestForNewPhoto = useSafeCallback(async (): Promise<void> => {
    if (!board) return;
    setDisabledSaveButton(true);

    try {
      const request = builder<SaveBoardRequestForNewPhotoRequest>()
        .boardId(board.boardId)
        .requestForNewPhoto(requestForNewPhotoToSave)
        .build();

      await BoardRequest.saveRequestForNewPhoto(request);
      onSaveRequestForNewPhoto(requestForNewPhotoToSave);
    } catch (e) {
      setDisabledSaveButton(false);
    }
  }, [board, setDisabledSaveButton, requestForNewPhotoToSave, onSaveRequestForNewPhoto]);

  return (
    <Component style={styleForFullExpansion} className='input-request-for-new-photo'>
      <Header>
        <HeaderButton>
          <Button onClick={goBack}>
            <BackIcon />
          </Button>
        </HeaderButton>

        <HeaderLabel>希望する写真</HeaderLabel>

        <HeaderButton />
      </Header>

      <Body>
        <InputWrapper>
          <InputBox showCount shape='rect' text={requestForNewPhotoToSave} onChange={setRequestForNewPhotoToSave} />
        </InputWrapper>

        <ButtonArea>
          <Button disabled={disabledSaveButton} type='primary' onClick={saveRequestForNewPhoto}>
            <Label>保存</Label>
          </Button>
        </ButtonArea>
      </Body>
    </Component>
  );
});

export default InputRequestForNewPhoto;

const Header = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.mixins.spacing}px;
`;

const HeaderButton = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

const BackIcon = styled(ArrowBackIosOutlinedIcon)`
  color: ${theme.mixins.typography.fontColor.lightGray};
`;

const HeaderLabel = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.lightGray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds} !important;
  font-family: ${theme.mixins.typography.fontFamily};
`;

const Body = styled.div`
  width: 100%;
  height: calc(100% - 48px);
  background: ${theme.mixins.background.lightGray};
`;

const InputWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing * 4}px ${theme.mixins.spacing * 2}px;
`;

const ButtonArea = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
`;

const Label = styled.div`
  width: 80px;
  height: auto;
`;
