import { theme } from '@atomica.co/components';
import { isEven } from '@atomica.co/utils';
import { BoardMessagePdfEntity } from '@atomica.co/yosemal-v2';
import { StyleSheet, View } from '@react-pdf/renderer';
import React from 'react';
import { Styles } from '../BoardPagesPdf';
import BoardMessage, { BOARD_MESSAGE_HEIGHT_RATIO } from './BoardMessage';

const BOARD_MESSAGE_WIDTH = 560;

const BOARD_MESSAGE_HEIGHT = BOARD_MESSAGE_WIDTH * BOARD_MESSAGE_HEIGHT_RATIO;

const styles: Styles = StyleSheet.create({
  container: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  boardMessageArea: {
    width: BOARD_MESSAGE_WIDTH,
    height: BOARD_MESSAGE_HEIGHT,
    margin: theme.mixins.spacing * 2
  }
});

interface P {
  messages: BoardMessagePdfEntity[];
}

const BoardMessagesTwo: React.FC<P> = React.memo(props => {
  const { messages } = props;

  return (
    <View style={styles.container}>
      {messages.map((message, index) => (
        <View key={index} style={styles.boardMessageArea}>
          <BoardMessage tilt={isEven(index) ? 'left' : 'right'} message={message} />
        </View>
      ))}
    </View>
  );
});

export default BoardMessagesTwo;
