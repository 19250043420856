import { useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { isEven, ONE, roundUpToNearestMultiple, ZERO } from '@atomica.co/utils';
import { BoardMessagePdfEntity, BoardPdfEntity } from '@atomica.co/yosemal-v2';
import { Document, Font } from '@react-pdf/renderer';
import { Orientation, PageSize, Style } from '@react-pdf/types';
import React, { useEffect } from 'react';
import Bold from '../../assets/fonts/NotoSansCJKjp-Bold.ttf';
import Regular from '../../assets/fonts/NotoSansCJKjp-Regular.ttf';
import { FONT_NOTO_SANS_CJK_JP, FONT_NOTO_SANS_CJK_JP_BOLD } from '../../constants/pdf-constants';
import BoardMessagesPage from './pages/BoardMessagesPage';
import FrontCoverPage from './pages/FrontCoverPage';

export const PAGE_SIZE: PageSize = 'A4';

export const PAGE_ORIENTATION: Orientation = 'portrait';

const MAX_MESSAGE_COUNT_PER_PAGE = 8;

Font.register({
  family: FONT_NOTO_SANS_CJK_JP,
  src: Regular
});

Font.register({
  family: FONT_NOTO_SANS_CJK_JP_BOLD,
  src: Bold
});

Font.registerEmojiSource({
  format: 'png',
  url: 'https://twemoji.maxcdn.com/2/72x72/'
});

export interface Styles {
  [key: string]: Style;
}

interface P {
  board: BoardPdfEntity;
}

const BoardPagesPdf: React.FC<P> = React.memo(props => {
  const { board } = props;
  const unmountRef = useUnmountRef();
  const [messages, setMessages] = useSafeState<BoardMessagePdfEntity[][]>(unmountRef, []);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const totalMessageCount = board.messages.length;
    const pageCount =
      totalMessageCount > 2 ? roundUpToNearestMultiple(totalMessageCount / MAX_MESSAGE_COUNT_PER_PAGE, 2) : ONE;
    const averageMessageCountPerPage = Math.floor(totalMessageCount / pageCount);

    let remainderMessageCount = totalMessageCount % pageCount;
    let offset = ZERO;

    const messagesChunk: BoardMessagePdfEntity[][] = [];
    while (offset < totalMessageCount) {
      const messageCountPerPage =
        remainderMessageCount === ZERO ? averageMessageCountPerPage : averageMessageCountPerPage + ONE;
      const messagesPerPage = board.messages.slice(offset, offset + messageCountPerPage);
      messagesChunk.push(messagesPerPage);

      if (remainderMessageCount > ZERO) {
        remainderMessageCount = remainderMessageCount - ONE;
      }

      offset = offset + messageCountPerPage;
    }

    setMessages(messagesChunk);
  }, [board, setMessages]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <Document>
      <FrontCoverPage board={board} />

      {messages.map((messagesPerPage, index) => (
        <BoardMessagesPage key={index} side={isEven(index) ? 'left' : 'right'} messages={messagesPerPage} />
      ))}
    </Document>
  );
});

export default BoardPagesPdf;
