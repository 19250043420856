import { UserEntity } from '@atomica.co/yosemal-v2';
import { UserActionEnum } from '../../enums/action-enum';
import { UserAction } from '../../models/action-model';

const userReducer = (user: UserEntity, action: UserAction): UserEntity | null => {
  if (user === undefined) return null;

  switch (action.type) {
    case UserActionEnum.SET:
      return action.user;

    case UserActionEnum.PURGE:
      return null;

    default:
      return user;
  }
};

export default userReducer;
