import {
  Button,
  CircularLoader,
  Component,
  Scrollable,
  styleForFullExpansion,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { builder, Count, embedIdInPath, Index, ZERO } from '@atomica.co/utils';
import { BoardEntity, BoardMemberEntity, FetchBoardMembersRequest, UserEntity } from '@atomica.co/yosemal-v2';
import { Typography } from '@material-ui/core';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import { default as React, useEffect, useRef } from 'react';
import styled from 'styled-components';
import EmailCard from '../../../../../components/card/EmailCard';
import UserCard from '../../../../../components/card/UserCard';
import usePath from '../../../../../redux/hooks/usePath';
import BoardMemberRequest from '../../../../../requests/board-member-request';
import { Path, PATH_IDS } from '../../../../../router/Routes';

const LIMIT = 10;

const OPTIONS: IntersectionObserverInit = {
  root: null,
  rootMargin: '0px 0px 300px 0px'
};

interface P {
  board: BoardEntity | undefined;
  goBack(): void;
}

const ShowBoardMembers: React.FC<P> = React.memo(props => {
  const { board, goBack } = props;
  const ref = useRef<HTMLDivElement>();
  const hasMore = useRef<boolean>(true);
  const count = useRef<Count>(ZERO);
  const { openPath } = usePath();
  const unmountRef = useUnmountRef();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [members, setMembers] = useSafeState<BoardMemberEntity[]>(unmountRef, []);

  const loadMembers = useSafeCallback(async (): Promise<void> => {
    if (!hasMore || !board) return;

    const request = builder<FetchBoardMembersRequest>()
      .boardId(board.boardId)
      .limit(LIMIT)
      .offset(count.current)
      .build();

    const response = await BoardMemberRequest.fetchMembers(request);
    const membersToAdd = response.members;

    hasMore.current = membersToAdd.length === LIMIT;
    count.current += membersToAdd.length;
    setMembers(members => [...members, ...response.members]);
    setLoaded(true);
  }, [board, setMembers, setLoaded]);

  useEffect(() => {
    loadMembers();
  }, [loadMembers]);

  const onScroll = useSafeCallback(
    (entries: IntersectionObserverEntry[]): void => {
      for (const entry of entries) {
        if (!entry.isIntersecting) return;
        loadMembers();
      }
    },
    [loadMembers]
  );

  useEffect(() => {
    if (!loaded) return;

    const observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => onScroll(entries), OPTIONS);

    ref.current && observer.observe(ref.current);
    return () => observer.disconnect();
  }, [loaded, onScroll]);

  const openUserProfile = useSafeCallback(
    (user: UserEntity): void => {
      openPath(embedIdInPath(Path.ACCOUNT, PATH_IDS, [user.userId]));
    },
    [openPath]
  );

  return (
    <Component style={styleForFullExpansion} className='show-board-members'>
      <Header>
        <HeaderButton>
          <Button onClick={goBack}>
            <BackIcon />
          </Button>
        </HeaderButton>

        <HeaderLabel>グループメンバー</HeaderLabel>

        <HeaderButton />
      </Header>

      <Body>
        <Scrollable>
          <ListWrapper>
            {members.map((member: BoardMemberEntity, index: Index) => (
              <CardWrapper key={index}>
                {!!member.userId && (
                  <UserCard
                    user={builder<UserEntity>()
                      .userId(member.userId)
                      .familyName(member.familyName!)
                      .firstName(member.firstName!)
                      .photoURL(member.photoURL!)
                      .email(member.email!)
                      .selfIntroduction(member.selfIntroduction!)
                      .build()}
                    onClick={openUserProfile}
                  />
                )}

                {!!member.userId && (
                  <MessageStatus hasMessageWritten={member.hasMessageWritten}>
                    {!member.hasJoined ? '未参加' : !member.hasMessageWritten ? '未記入' : '記入済み'}
                  </MessageStatus>
                )}

                {!member.userId && <EmailCard email={member.email!} />}
              </CardWrapper>
            ))}
          </ListWrapper>

          {hasMore.current && (
            <LoaderWrapper>
              <CircularLoader />
            </LoaderWrapper>
          )}

          <Bottom ref={ref} />
        </Scrollable>
      </Body>
    </Component>
  );
});

export default ShowBoardMembers;

const Header = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.mixins.spacing}px;
`;

const HeaderButton = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

const BackIcon = styled(ArrowBackIosOutlinedIcon)`
  color: ${theme.mixins.typography.fontColor.lightGray};
`;

const HeaderLabel = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.lightGray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds} !important;
  font-family: ${theme.mixins.typography.fontFamily};
`;

const Body = styled.div`
  width: 100%;
  height: calc(100% - 48px);
  background: ${theme.mixins.background.lightGray};
`;

const ListWrapper = styled.div`
  width: 100%;
  height: auto;
  padding-top: ${theme.mixins.spacing}px;
`;

const CardWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing / 2}px ${theme.mixins.spacing}px;
  position: relative;
`;

const MessageStatus = styled.div<{ hasMessageWritten: boolean }>`
  width: 72px;
  height: 32px;
  color: ${theme.mixins.typography.fontColor.white};
  font-size: ${theme.mixins.typography.fontSize.twelve}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => (props.hasMessageWritten ? theme.mixins.background.gray : theme.mixins.background.pink)};
  border-radius: 28px;
  position: absolute;
  top: 16px;
  right: 16px;
`;

const LoaderWrapper = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Bottom = styled.div`
  width: 100%;
  height: 240px;
`;
