import { Component, getMobileWidth, theme, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { EMPTY, getDayOfWeek, isFuture, Language, Name, toDateStr, URL, Width } from '@atomica.co/utils';
import { FrameId } from '@atomica.co/yosemal-v2';
import { Typography } from '@material-ui/core';
import AddAPhotoOutlinedIcon from '@material-ui/icons/AddAPhotoOutlined';
import { CSSProperties } from '@material-ui/styles';
import React, { useMemo, useRef } from 'react';
import styled from 'styled-components';
import '../../../node_modules/react-image-crop/dist/ReactCrop.css';
import { FramePos, FRAME_POSITION_FOR_PORTRAIT_POLAROID } from '../../constants/frame-constant';
import PhotoRequest from '../../requests/photo-request';
import TrimmingDialog from './TrimmingDialog';
interface P {
  name: Name;
  photoURL: URL | undefined;
  frameId: FrameId | undefined;
  dueDate: Date;
  onUpload(photoURL: URL): void;
}

const BoardPhotoUploader: React.FC<P> = React.memo(props => {
  const { name, photoURL: initPhotoURL, frameId, dueDate, onUpload } = props;

  const width = useMemo(() => getMobileWidth(), []);
  const frame = useMemo<FramePos | undefined>(
    () => (!!frameId ? FRAME_POSITION_FOR_PORTRAIT_POLAROID[frameId] : undefined),
    [frameId]
  );

  const unmountRef = useUnmountRef();
  const [uploadedPhotoURL, setUploadedPhotoURL] = useSafeState<URL | undefined>(unmountRef, initPhotoURL);
  const imgRef = useRef<HTMLImageElement>(null);
  const [isDialogOpen, setIsDialogOpen] = useSafeState<boolean>(unmountRef, false);

  const handleBoardPhotoSelected = useSafeCallback(
    async (image: File): Promise<void> => {
      const URL = await PhotoRequest.uploadBoardPhoto(image);
      setUploadedPhotoURL(URL);
      onUpload(URL);
      setIsDialogOpen(true);
    },
    [setUploadedPhotoURL, onUpload, setIsDialogOpen]
  );

  return (
    <Component className='board-photo-uploader'>
      <Container width={width}>
        <Card>
          {!uploadedPhotoURL && (
            <UploadArea>
              <Uploader
                type='file'
                accept='image/*'
                width={width}
                onChange={e => handleBoardPhotoSelected(e.target.files[0])}
              />
            </UploadArea>
          )}
          <PhotoWrapper width={width}>
            {!uploadedPhotoURL && (
              <NoPhoto>
                <AddAPhotoOutlinedIcon fontSize='large' />

                <Label>ボード画像を追加しよう</Label>
              </NoPhoto>
            )}

            {!!uploadedPhotoURL && (
              <CroppedPhoto
                ref={imgRef}
                alt='トリミングする写真を選択してください'
                src={uploadedPhotoURL}
                style={styleForProfilePhoto(width)}
              />
            )}
          </PhotoWrapper>

          <TitleWrapper>
            <StyledName>{name}</StyledName>
            <DueDate>
              {`${toDateStr(dueDate, Language.JAPANESE)} ${getDayOfWeek(dueDate)} ${
                isFuture(dueDate) ? 'まで' : EMPTY
              }`}
            </DueDate>
          </TitleWrapper>
        </Card>

        {!!frame && <Stamp frame={frame} src={frame.src} />}
      </Container>

      <TrimmingDialog
        isDialogOpen={isDialogOpen}
        uploadedPhotoURL={uploadedPhotoURL}
        setUploadedPhotoURL={setUploadedPhotoURL}
        onUpload={onUpload}
        onClose={() => setIsDialogOpen(false)}
      ></TrimmingDialog>
    </Component>
  );
});
export default BoardPhotoUploader;

const Container = styled.div<{ width: Width }>`
  width: 100%;
  height: ${props => props.width + theme.mixins.spacing * 6.25}px;
  display: flex;
  align-items: flex-start;
  position: relative;
`;

const Card = styled.div`
  width: 100%;
  height: auto;
  border: 0.25px solid rgba(0, 0, 0, 0.4);
  background: ${theme.mixins.background.white};
  box-shadow: ${theme.mixins.shadow.light};
  display: flex;
  flex-flow: column;
  padding: ${theme.mixins.spacing * 4}px ${theme.mixins.spacing * 2}px ${theme.mixins.spacing}px;
`;

const UploadArea = styled.div`
  width: 100%;
  height: auto;
`;

const Uploader = styled.input<{ width: Width }>`
  width: calc(100% - ${theme.mixins.spacing * 4}px);
  height: ${props => props.width - theme.mixins.spacing * 6}px;
  opacity: 0;
  appearance: none;
  position: absolute;
  z-index: 10;
`;

const PhotoWrapper = styled.div<{ width: Width }>`
  width: 100%;
  height: ${props => props.width - theme.mixins.spacing * 6}px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #f3f3f3 0%, #ccc 100%);
  border: ${theme.mixins.border.lightGray};
  box-shadow: inset ${theme.mixins.shadow.light};
`;

const NoPhoto = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

const styleForProfilePhoto = (width: Width): CSSProperties => {
  return {
    width: width - theme.mixins.spacing * 6,
    height: width - theme.mixins.spacing * 6,
    objectFit: 'contain',
    boxShadow: `inset ${theme.mixins.shadow.light}`
  };
};

const CroppedPhoto = styled.img``;

const Label = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  padding-top: ${theme.mixins.spacing}px;
`;

const TitleWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: ${theme.mixins.spacing / 2}px 0px;
`;

const StyledName = styled(Typography)`
  min-height: 32px;
  color: ${theme.mixins.typography.fontColor.black};
  font-size: ${theme.mixins.typography.fontSize.twenty}px !important;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds} !important;
  font-family: ${theme.mixins.typography.fontFamily} !important;
`;

const DueDate = styled(Typography)`
  min-height: 16px;
  color: ${theme.mixins.typography.fontColor.lightGray};
  font-size: ${theme.mixins.typography.fontSize.twelve}px !important;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds} !important;
  font-family: ${theme.mixins.typography.fontFamily} !important;
`;

const Stamp = styled.img<{ frame: FramePos }>`
  width: ${props => props.frame.width};
  margin-top: ${props => props.frame.top};
  margin-left: ${props => props.frame.left};
  object-fit: contain;
  position: absolute;
  ${theme.mixins.userSelectDisabled};
`;
