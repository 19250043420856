import { UserEntity } from '@atomica.co/yosemal-v2';
import { StyleSheet, View } from '@react-pdf/renderer';
import React from 'react';
import { Styles } from '../BoardPagesPdf';
import UserPolaroid, { USER_POLAROID_HEIGHT_RATIO } from './UserPolaroid';

const USER_POLAROID_WIDTH = 300;

const USER_POLAROID_HEIGHT = USER_POLAROID_WIDTH * USER_POLAROID_HEIGHT_RATIO;

const styles: Styles = StyleSheet.create({
  user1: {
    position: 'absolute',
    top: -4,
    left: -8
  },
  userPolaroidArea: {
    width: USER_POLAROID_WIDTH,
    height: USER_POLAROID_HEIGHT
  }
});

interface P {
  users: UserEntity[];
}

const UserPolaroidOne: React.FC<P> = React.memo(props => {
  const { users } = props;

  return (
    <View style={styles.user1}>
      <View style={styles.userPolaroidArea}>
        <UserPolaroid user={users[0]} />
      </View>
    </View>
  );
});

export default UserPolaroidOne;
