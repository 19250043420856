import {
  getFontSize,
  paddingFullWidth,
  splitTextToFitInSquare,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { BREAK, EMPTY, findMin, getDayOfWeek, Language, Line, ONE, toDateStr, ZERO } from '@atomica.co/utils';
import { BoardMessagePdfEntity } from '@atomica.co/yosemal-v2';
import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import React, { useEffect, useMemo } from 'react';
import { FramePos, FRAME_POSITION_FOR_PORTRAIT_POLAROID } from '../../../constants/frame-constant';
import { FONT_NOTO_SANS_CJK_JP, FONT_NOTO_SANS_CJK_JP_BOLD } from '../../../constants/pdf-constants';
import { toFullName } from '../../../utils/user-util';
import { Styles } from '../BoardPagesPdf';

type Size = number;

type PolaroidTilt = 'right' | 'left';

type PolaroidPosition = 'right' | 'left';

const DEFAULT_BOARD_MESSAGE_WIDTH = 280;

export const BOARD_MESSAGE_HEIGHT_RATIO = 4 / 7;

const getStyles = (size: Size, tilt: PolaroidTilt, frame: FramePos | undefined, fontSize: Size): Styles =>
  StyleSheet.create({
    container: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center'
    },
    polaroid: {
      width: '43%',
      height: '100%',
      backgroundColor: theme.mixins.background.white,
      border: `1px solid ${theme.mixins.border.lightGray}`,
      transform: tilt === 'right' ? 'rotate(1.25deg)' : 'rotate(-0.79deg)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: theme.mixins.spacing * -1.5,
      zIndex: 0
    },
    photo: {
      width: '95%',
      height: '78%',
      objectFit: 'cover',
      border: `1px solid ${theme.mixins.border.lightGray}`
    },
    stamp: {
      position: 'absolute',
      top: !!frame ? frame.top : ZERO,
      left: !!frame ? frame.left : ZERO,
      width: !!frame ? frame.width : ZERO
    },
    name: {
      width: '95%',
      height: '8%',
      color: theme.mixins.typography.fontColor.black,
      fontSize: theme.mixins.typography.fontSize.sixteen * size,
      fontFamily: FONT_NOTO_SANS_CJK_JP_BOLD,
      fontWeight: theme.mixins.typography.fontWeight.nineHundreds,
      marginTop: theme.mixins.spacing
    },
    createdAt: {
      width: '95%',
      height: '6%',
      color: theme.mixins.typography.fontColor.lightGray,
      fontSize: theme.mixins.typography.fontSize.twelve * size,
      fontFamily: FONT_NOTO_SANS_CJK_JP,
      fontWeight: theme.mixins.typography.fontWeight.fourHundreds
    },
    card: {
      width: '57%',
      height: '100%',
      backgroundColor: theme.mixins.background.white,
      border: `1px solid ${theme.mixins.border.lightGray}`,
      transform: tilt === 'right' ? 'rotate(-0.79deg)' : 'rotate(1.25deg)',
      marginTop: theme.mixins.spacing * 1.5,
      zIndex: 100
    },
    message: {
      width: '92%',
      height: '100%',
      marginVertical: '8%',
      marginHorizontal: '8%'
    },
    messageRow: {
      color: theme.mixins.typography.fontColor.black,
      fontSize,
      fontFamily: FONT_NOTO_SANS_CJK_JP_BOLD,
      fontWeight: theme.mixins.typography.fontWeight.nineHundreds
    }
  });

interface P {
  size?: Size;
  tilt?: PolaroidTilt;
  position?: PolaroidPosition;
  message: BoardMessagePdfEntity;
}

const BoardMessage: React.FC<P> = React.memo(props => {
  const { size = ONE, tilt = 'left', position = 'left', message } = props;
  const unmountRef = useUnmountRef();
  const [frame, setFrame] = useSafeState<FramePos | undefined>(unmountRef);
  const [fontSize, setFontSize] = useSafeState<Size>(unmountRef);
  const [messageLines, setMessageLines] = useSafeState<Line[]>(unmountRef, []);

  const styles = useMemo((): Styles => {
    return getStyles(size, tilt, frame, fontSize);
  }, [size, tilt, frame, fontSize]);

  const initialize = useSafeCallback((): void => {
    const frame = FRAME_POSITION_FOR_PORTRAIT_POLAROID[message.frameId!];
    setFrame(!!frame && !!frame.src ? frame : undefined);

    const text = message.text.replaceAll(BREAK, EMPTY);
    const texts = splitTextToFitInSquare(text.length === ONE ? paddingFullWidth(text) : text);
    const textWidthPerLine = DEFAULT_BOARD_MESSAGE_WIDTH * size;
    const fontSizes = texts.map(text => getFontSize(text, FONT_NOTO_SANS_CJK_JP, textWidthPerLine));
    const fontSize = fontSizes.reduce(findMin) - 2;
    setFontSize(fontSize);
    setMessageLines(texts);
  }, [setFrame, message, size, setFontSize, setMessageLines]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <View style={styles.container}>
      {position === 'left' && (
        <View style={styles.polaroid}>
          <Image style={styles.photo} src={message.photoURL} />
          {!!frame && <Image style={styles.stamp} src={frame.src} />}
          <Text style={styles.name}>{toFullName(message)}</Text>
          <Text style={styles.createdAt}>{`${toDateStr(message.createdAt, Language.JAPANESE)} ${getDayOfWeek(
            message.createdAt
          )}`}</Text>
        </View>
      )}

      <View style={{ ...styles.card, marginLeft: position === 'left' ? theme.mixins.spacing * -1 : ZERO }}>
        <View style={styles.message}>
          {messageLines.map((messageRow, index) => (
            <Text key={index} style={styles.messageRow}>
              {messageRow}
            </Text>
          ))}
        </View>
      </View>

      {position === 'right' && (
        <View style={{ ...styles.polaroid, marginLeft: theme.mixins.spacing * -1 }}>
          <Image style={styles.photo} src={message.photoURL} />
          {!!frame && <Image style={styles.stamp} src={frame.src} />}
          <Text style={styles.name}>{toFullName(message)}</Text>
          <Text style={styles.createdAt}>{`${toDateStr(message.createdAt, Language.JAPANESE)} ${getDayOfWeek(
            message.createdAt
          )}`}</Text>
        </View>
      )}
    </View>
  );
});

export default BoardMessage;
