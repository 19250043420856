import { Component, Scrollable, theme, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { builder, embedIdInPath } from '@atomica.co/utils';
import { BoardMessageEntity, BoardMessageId, FetchBoardMessageRequest, UserId } from '@atomica.co/yosemal-v2';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import BoardMessagePolaroid, { ClickArea } from '../../../components/polaroid/BoardMessagePolaroid';
import { HEADER_HEIGHT } from '../../../constants/common-constants';
import usePath from '../../../redux/hooks/usePath';
import BoardMessageRequest from '../../../requests/board-message-request';
import { Path, PATH_IDS } from '../../../router/Routes';
import BoardThreadList, { BoardThreadRef } from '../board-details/parts/BoardThreadList';

interface P {
  boardMessageId: BoardMessageId | undefined;
}

const BoardThread: React.ForwardRefExoticComponent<P & React.RefAttributes<BoardThreadRef>> = React.forwardRef<
  BoardThreadRef,
  P
>((props, ref) => {
  const { boardMessageId } = props;
  const { path, openPath } = usePath();
  const loadable = useMemo(() => path === Path.BOARD_THREAD, [path]);
  const unmountRef = useUnmountRef();
  const [message, setMessage] = useSafeState<BoardMessageEntity | undefined>(unmountRef);

  const initialize = useSafeCallback(async (): Promise<void> => {
    if (!boardMessageId) return;
    const request = builder<FetchBoardMessageRequest>().boardMessageId(boardMessageId).build();
    const response = await BoardMessageRequest.fetchMessage(request);
    setMessage(response.message);
  }, [boardMessageId, setMessage]);

  useEffect(() => {
    if (!loadable) return;
    initialize();
  }, [loadable, initialize]);

  const handleBoardMessageClicked = useSafeCallback(
    (area: ClickArea, id: UserId | BoardMessageId) => {
      switch (area) {
        case ClickArea.USER:
          openPath(embedIdInPath(Path.ACCOUNT, PATH_IDS, [id]));
          return;
      }
    },
    [openPath]
  );

  return (
    <Component className='board-thread'>
      <Container>
        <Scrollable showScrollbar>
          <PolaroidWrapper>
            <BoardMessagePolaroid rotate='left' message={message} onClick={handleBoardMessageClicked} />
          </PolaroidWrapper>

          {!!message && (
            <ThreadListWrapper>
              <BoardThreadList
                ref={ref}
                loadable={loadable}
                loadAllThreads
                totalCount={message.threadCount}
                boardMessageId={boardMessageId}
              />
            </ThreadListWrapper>
          )}
        </Scrollable>
      </Container>
    </Component>
  );
});

export default BoardThread;

const Container = styled.div`
  width: 100%;
  height: calc(100vh - ${HEADER_HEIGHT}px);
  display: flex;
  margin-top: ${HEADER_HEIGHT}px;
`;

const PolaroidWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding-top: ${theme.mixins.spacing * 2}px;
`;

const ThreadListWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: 0px 0px ${theme.mixins.spacing * 4}px ${theme.mixins.spacing * 6}px;
  margin-bottom: ${theme.mixins.spacing * 20}px;
`;
