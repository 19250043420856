import { Component, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { builder } from '@atomica.co/utils';
import { BoardPdfEntity, BOARD_ID, FetchBoardPdfRequest } from '@atomica.co/yosemal-v2';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { PDFDownloadLink } from '@react-pdf/renderer';
import React, { CSSProperties, useEffect, useRef } from 'react';
import styled from 'styled-components';
import usePath from '../../redux/hooks/usePath';
import BoardRequest from '../../requests/board-request';
import BoardPagesPdf from './BoardPagesPdf';

interface P {}

const BoardExportButton: React.FC<P> = React.memo(() => {
  const { params } = usePath();
  const ref = useRef<HTMLDivElement | null>(null);
  const unmountRef = useUnmountRef();
  const [processing, setProcessing] = useSafeState<boolean>(unmountRef, false);
  const [board, setBoard] = useSafeState<BoardPdfEntity>(unmountRef);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const boardId = params[BOARD_ID];
    const request = builder<FetchBoardPdfRequest>().boardId(boardId!).build();
    const response = await BoardRequest.fetchBoardPdf(request);
    setBoard(response.board!);
  }, [params, setBoard]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const handleIconClicked = useSafeCallback((): void => {
    setProcessing(true);
    setTimeout(() => {
      !!ref.current && ref.current.click();
      setProcessing(false);
    }, 5000);
  }, [setProcessing]);

  return (
    <>
      <Component loading={processing} style={styleForComponent} className='board-export-button'>
        <PdfIcon onClick={handleIconClicked} />
      </Component>

      {processing && (
        <PDFDownloadLink document={<BoardPagesPdf board={board} />} fileName={`${board.name}.pdf`}>
          {({ blob, url, loading, error }) => <InvisibleDiv ref={ref} />}
        </PDFDownloadLink>
      )}
    </>
  );
});

export default BoardExportButton;

const styleForComponent: CSSProperties = {
  width: 40,
  height: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

const PdfIcon = styled(PictureAsPdfIcon)`
  display: flex;
  color: #777;
`;

const InvisibleDiv = styled.div`
  display: none;
`;
