import { convert, RequestType } from '@atomica.co/utils';
import {
  FetchBoardThreadsRequest,
  FetchBoardThreadsResponse,
  FETCH_BOARD_THREADS,
  SaveBoardThreadRequest,
  SaveBoardThreadResponse,
  SAVE_BOARD_THREAD,
  toFuncName
} from '@atomica.co/yosemal-v2';
import { functions } from '../firebase';

export default class BoardThreadRequest {
  public static saveNewThread = async (request: SaveBoardThreadRequest): Promise<SaveBoardThreadResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_BOARD_THREAD));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static fetchThreads = async (request: FetchBoardThreadsRequest): Promise<FetchBoardThreadsResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(FETCH_BOARD_THREADS));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };
}
