import { Button, Component, Icon, theme } from '@atomica.co/components';
import { EMPTY } from '@atomica.co/utils';
import { BoardEntity } from '@atomica.co/yosemal-v2';
import { CSSProperties } from '@material-ui/styles';
import React from 'react';
import styled from 'styled-components';
import Header from '../../../components/header/Header';
import arrow_left from './../../../assets/icon/icon_arrow_left.png';

interface P {
  disabledSaveButton: boolean;
  board: BoardEntity | undefined;
  onClickSaveButton(): void;
  goBack(): void;
}

const BoardMessageHeader: React.FC<P> = React.memo(props => {
  const { disabledSaveButton, board, onClickSaveButton, goBack } = props;

  return (
    <Component className='board-message-header'>
      <Header
        label={!!board ? board.name : EMPTY}
        leftChild={
          <IconsWrapper>
            <IconWrapper onClick={goBack}>
              <Icon style={styleForIcon} src={arrow_left} />
            </IconWrapper>
          </IconsWrapper>
        }
        rightChild={
          <ButtonWrapper>
            <Button type='primary' shape='rect' disabled={disabledSaveButton} onClick={onClickSaveButton}>
              完成
            </Button>
          </ButtonWrapper>
        }
      />
    </Component>
  );
});

export default BoardMessageHeader;

const IconsWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  padding: ${theme.mixins.spacing}px;
`;

const IconWrapper = styled.div`
  width: 40px;
  height: auto;
  padding: ${theme.mixins.spacing}px;
`;

const styleForIcon: CSSProperties = {
  width: 24,
  height: 24
};

const ButtonWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px;
`;
