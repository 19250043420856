import { Component, getMobileWidth, theme, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { builder, Count as CountVo, embedIdInPath, Index, ZERO } from '@atomica.co/utils';
import { BoardId, BoardMemberEntity, FetchBoardMembersRequest, UserEntity, UserId } from '@atomica.co/yosemal-v2';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import EmailCard from '../../../../components/card/EmailCard';
import UserCard from '../../../../components/card/UserCard';
import usePath from '../../../../redux/hooks/usePath';
import BoardMemberRequest from '../../../../requests/board-member-request';
import { Path, PATH_IDS } from '../../../../router/Routes';

const LIMIT = 10;

const OPTIONS: IntersectionObserverInit = {
  root: null,
  rootMargin: '0px 0px 300px 0px'
};

interface P {
  boardId: BoardId | undefined;
}

const BoardMembers: React.FC<P> = React.memo(props => {
  const { boardId } = props;
  const { openPath } = usePath();
  const scrollRef = useRef<HTMLDivElement>();
  const hasMore = useRef<boolean>(true);
  const count = useRef<CountVo>(ZERO);
  const unmountRef = useUnmountRef();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [members, setMembers] = useSafeState<BoardMemberEntity[]>(unmountRef, []);

  const loadMembers = useSafeCallback(async (): Promise<void> => {
    if (!boardId || !hasMore) return;

    const request = builder<FetchBoardMembersRequest>().boardId(boardId).limit(LIMIT).offset(count.current).build();

    const response = await BoardMemberRequest.fetchMembers(request);
    const membersToAdd = response.members;

    hasMore.current = membersToAdd.length === LIMIT;
    count.current += membersToAdd.length;
    setMembers(member => (count.current === membersToAdd.length ? response.members : [...member, ...response.members]));
    setLoaded(true);
  }, [boardId, setMembers, setLoaded]);

  useEffect(() => {
    loadMembers();
  }, [loadMembers]);

  const onScroll = useSafeCallback(
    (entries: IntersectionObserverEntry[]): void => {
      for (const entry of entries) {
        if (!entry.isIntersecting) return;
        loadMembers();
      }
    },
    [loadMembers]
  );

  useEffect(() => {
    if (!loaded) return;

    const observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => onScroll(entries), OPTIONS);

    scrollRef.current && observer.observe(scrollRef.current);
    return () => observer.disconnect();
  }, [loaded, onScroll]);

  const openBoardMemberScreen = useSafeCallback(
    (userId: UserId | undefined): void => {
      !!userId && openPath(embedIdInPath(Path.ACCOUNT, PATH_IDS, [userId]));
    },
    [openPath]
  );

  return (
    <Component className='board-members'>
      <Frame>
        <TitleWrapper>
          <Title>参加者リスト</Title>

          <Count>{members.length}</Count>
        </TitleWrapper>

        {members.map((member: BoardMemberEntity, index: Index) => (
          <CardWrapper key={index} onClick={() => openBoardMemberScreen(member.userId)}>
            {!!member.userId && (
              <UserCard
                user={builder<UserEntity>()
                  .userId(member.userId)
                  .familyName(member.familyName!)
                  .firstName(member.firstName!)
                  .selfIntroduction(member.selfIntroduction!)
                  .photoURL(member.photoURL!)
                  .build()}
              />
            )}

            {!member.userId && <EmailCard email={member.email!} />}
          </CardWrapper>
        ))}
      </Frame>
    </Component>
  );
});

export default BoardMembers;

const Frame = styled.div`
  width: ${getMobileWidth()}px;
  height: auto;
  background: ${theme.mixins.background.white};
  border-radius: 24px 24px 0px 0px;
  padding: ${theme.mixins.spacing * 2}px ${theme.mixins.spacing * 2}px ${theme.mixins.spacing * 20}px;
`;

const TitleWrapper = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  ${theme.mixins.userSelectDisabled};
`;

const Title = styled.div`
  color: ${theme.mixins.typography.fontColor.black};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px !important;
  font-weight: ${theme.mixins.typography.fontWeight.nineHundreds} !important;
  font-family: ${theme.mixins.typography.fontFamily};
  text-decoration: underline;
  text-align: center;
`;

const Count = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.mixins.typography.fontColor.black};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px !important;
  font-weight: ${theme.mixins.typography.fontWeight.nineHundreds} !important;
  font-family: ${theme.mixins.typography.fontFamily};
  border: solid 2px ${theme.mixins.border.black};
  border-radius: 4px;
  padding-bottom: ${theme.mixins.spacing / 4}px;
  margin-left: ${theme.mixins.spacing}px;
`;

const CardWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing / 4}px;
`;
