import { theme } from '@atomica.co/components';
import { ONE, Size, ZERO } from '@atomica.co/utils';
import { UserEntity } from '@atomica.co/yosemal-v2';
import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import React, { useMemo } from 'react';
import { FramePos, FRAME_POSITION_FOR_PORTRAIT_POLAROID } from '../../../constants/frame-constant';
import { FONT_NOTO_SANS_CJK_JP_BOLD } from '../../../constants/pdf-constants';
import { toFullName } from '../../../utils/user-util';
import { Styles } from '../BoardPagesPdf';

type FontSize = Size;

export const USER_POLAROID_HEIGHT_RATIO = 5 / 4;

const getStyles = (fontSize: FontSize, frame: FramePos | undefined): Styles =>
  StyleSheet.create({
    polaroid: {
      width: '100%',
      height: '100%',
      backgroundColor: theme.mixins.background.white,
      border: `1px solid ${theme.mixins.border.lightGray}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 0
    },
    photo: {
      width: '95%',
      height: '76%',
      objectFit: 'cover',
      border: `1px solid ${theme.mixins.border.lightGray}`
    },
    stamp: {
      position: 'absolute',
      top: !!frame ? frame.top : ZERO,
      left: !!frame ? frame.left : ZERO,
      width: !!frame ? frame.width : ZERO
    },
    name: {
      width: '95%',
      height: '10%',
      color: theme.mixins.typography.fontColor.black,
      fontSize: theme.mixins.typography.fontSize.twentyFour * fontSize,
      fontFamily: FONT_NOTO_SANS_CJK_JP_BOLD,
      fontWeight: theme.mixins.typography.fontWeight.nineHundreds,
      marginTop: theme.mixins.spacing
    }
  });

interface P {
  fontSize?: FontSize;
  user: UserEntity;
}

const UserPolaroid: React.FC<P> = React.memo(props => {
  const { fontSize = ONE, user } = props;

  const frame = useMemo<FramePos | undefined>(() => {
    const position = FRAME_POSITION_FOR_PORTRAIT_POLAROID[user.frameId!];
    return !!position.src ? position : undefined;
  }, [user]);

  const styles = useMemo<Styles>(() => {
    return getStyles(fontSize, frame);
  }, [fontSize, frame]);

  return (
    <View style={styles.polaroid}>
      <Image style={styles.photo} src={user.photoURL} />
      {!!frame && <Image style={styles.stamp} src={frame.src} />}
      <Text style={styles.name}>{toFullName(user)}</Text>
    </View>
  );
});

export default UserPolaroid;
