import { Component, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { Email, EMPTY } from '@atomica.co/utils';
import React from 'react';
import EmailSent from './parts/EmailSent';
import InputEmail from './parts/InputEmail';

export enum ResetPasswordStatus {
  INPUT_EMAIL,
  EMAIL_SENT
}

interface P {
  goBack(): void;
}

const ResetPassword: React.FC<P> = React.memo(props => {
  const { goBack } = props;
  const unmountRef = useUnmountRef();
  const [index, setIndex] = useSafeState<ResetPasswordStatus>(unmountRef, ResetPasswordStatus.INPUT_EMAIL);
  const [email, setEmail] = useSafeState<Email>(unmountRef, EMPTY);

  const showEmailSentMessage = useSafeCallback(
    (email: Email): void => {
      setEmail(email);
      setIndex(ResetPasswordStatus.EMAIL_SENT);
    },
    [setEmail, setIndex]
  );

  return (
    <Component className='reset-password'>
      {index === ResetPasswordStatus.INPUT_EMAIL && <InputEmail onSendEmail={showEmailSentMessage} goBack={goBack} />}

      {index === ResetPasswordStatus.EMAIL_SENT && <EmailSent email={email} />}
    </Component>
  );
});

export default ResetPassword;
