import { Component } from '@atomica.co/components';
import React from 'react';
import Header from '../../../components/header/Header';

interface P {}

const BoardListHeader: React.FC<P> = React.memo(() => {
  return (
    <Component className='board-list-header'>
      <Header label='参加したイベント' />
    </Component>
  );
});

export default BoardListHeader;
