import { isEven } from '@atomica.co/utils';
import { BoardMessagePdfEntity } from '@atomica.co/yosemal-v2';
import { StyleSheet, View } from '@react-pdf/renderer';
import React from 'react';
import { Styles } from '../BoardPagesPdf';
import BoardMessage, { BOARD_MESSAGE_HEIGHT_RATIO } from './BoardMessage';

const BOARD_MESSAGE_WIDTH = 280;

const BOARD_MESSAGE_HEIGHT = BOARD_MESSAGE_WIDTH * BOARD_MESSAGE_HEIGHT_RATIO;

const FONT_SIZE = 0.52;

const styles: Styles = StyleSheet.create({
  container: {
    width: '100vw',
    height: '100vh'
  },
  message1: {
    position: 'absolute',
    top: 56,
    left: 16
  },
  message2: {
    position: 'absolute',
    top: 248,
    left: 16
  },
  message3: {
    position: 'absolute',
    top: 440,
    left: 16
  },
  message4: {
    position: 'absolute',
    top: 632,
    left: 16
  },
  message5: {
    position: 'absolute',
    top: 56,
    left: 304
  },
  message6: {
    position: 'absolute',
    top: 248,
    left: 304
  },
  message7: {
    position: 'absolute',
    top: 440,
    left: 304
  },
  message8: {
    position: 'absolute',
    top: 632,
    left: 304
  },
  boardMessageArea: {
    width: BOARD_MESSAGE_WIDTH,
    height: BOARD_MESSAGE_HEIGHT
  }
});

interface P {
  messages: BoardMessagePdfEntity[];
}

const BoardMessagesEight: React.FC<P> = React.memo(props => {
  const { messages } = props;

  return (
    <View style={styles.container}>
      {messages.map((message, index) => (
        <View key={index} style={styles[`message${index + 1}`]}>
          <View style={styles.boardMessageArea}>
            <BoardMessage
              size={FONT_SIZE}
              tilt={isEven(index) ? 'left' : 'right'}
              position={isEven(index) ? 'left' : 'right'}
              message={message}
            />
          </View>
        </View>
      ))}
    </View>
  );
});

export default BoardMessagesEight;
