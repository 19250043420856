import {
  Button,
  Component,
  InputBox,
  styleForFullExpansion,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { builder, EMPTY, Name, Text } from '@atomica.co/utils';
import { SaveUserSelfIntroductionRequest, UserId } from '@atomica.co/yosemal-v2';
import { default as React } from 'react';
import styled from 'styled-components';
import { HEADER_HEIGHT } from '../../../../../constants/common-constants';
import UserRequest from '../../../../../requests/user-request';
import InputProfileHeader from '../../../account-header/InputProfileHeader';
import { EditProfileIndex } from '../EditProfile';

interface P {
  userId: UserId | undefined;
  selfIntroduction: Text | undefined;
  onSave(selfIntroduction: Text): void;
  goTo(index: EditProfileIndex): void;
}

const InputSelfIntroduction: React.FC<P> = React.memo(props => {
  const { userId, selfIntroduction: initSelfIntroduction = EMPTY, onSave, goTo } = props;

  const unmountRef = useUnmountRef();
  const [disabledSaveButton, setDisabledSaveButton] = useSafeState<boolean>(unmountRef, false);
  const [selfIntroductionToSave, setSelfIntroductionToSave] = useSafeState<Name>(unmountRef, initSelfIntroduction);

  const saveSelfIntroduction = useSafeCallback(async (): Promise<void> => {
    if (!userId) return;
    setDisabledSaveButton(true);

    const request = builder<SaveUserSelfIntroductionRequest>()
      .userId(userId)
      .selfIntroduction(selfIntroductionToSave)
      .build();

    await UserRequest.saveSelfIntroduction(request);
    onSave(selfIntroductionToSave);
  }, [setDisabledSaveButton, userId, selfIntroductionToSave, onSave]);

  return (
    <Component style={styleForFullExpansion} className='input-self-introduction'>
      <HeaderArea>
        <InputProfileHeader label='自己PR' onClick={goTo} />
      </HeaderArea>

      <Body>
        <InputWrapper>
          <InputBox
            multiline
            maxLength={1200}
            shape='rect'
            text={selfIntroductionToSave}
            onChange={setSelfIntroductionToSave}
          />
        </InputWrapper>

        <ButtonArea>
          <Button type='primary' disabled={disabledSaveButton} onClick={saveSelfIntroduction}>
            <StyledLabel>保存</StyledLabel>
          </Button>
        </ButtonArea>
      </Body>
    </Component>
  );
});

export default InputSelfIntroduction;

const HeaderArea = styled.div`
  width: 100%;
  height: ${HEADER_HEIGHT}px;
`;

const Body = styled.div`
  width: 100%;
  height: calc(100% - 48px);
`;

const InputWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing * 4}px ${theme.mixins.spacing * 2}px;
`;

const ButtonArea = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
`;

const StyledLabel = styled.div`
  width: 80px;
  height: auto;
`;
