import { BoxButton, Component, Shape, SingleLineText, theme } from '@atomica.co/components';
import { EMPTY, Label, Placeholder, Text } from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { CSSProperties } from '@material-ui/styles';
import React from 'react';
import styled from 'styled-components';

interface P {
  shape?: Shape;
  icon?: React.ReactNode;
  label?: Label;
  placeholder?: Placeholder;
  text: Text;
  onClick(): void;
}

export const ArrowForwardButton: React.FC<P> = React.memo(props => {
  const { shape, icon, label, placeholder, text, onClick } = props;

  return (
    <Component className='arrow-forward-button'>
      <BoxButton shape={shape} onClick={onClick}>
        <Container>
          <TextWrapper>
            {!!label && <StyledLabel>{label}</StyledLabel>}

            {text === EMPTY && <StyledPlaceholder>{placeholder}</StyledPlaceholder>}

            {text !== EMPTY && <SingleLineText style={styleForText} text={text} />}
          </TextWrapper>

          {!!icon && <IconWrapper>{icon}</IconWrapper>}

          <IconWrapper>
            <ArrowIcon />
          </IconWrapper>
        </Container>
      </BoxButton>
    </Component>
  );
});

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  ${theme.mixins.userSelectDisabled};
`;

const TextWrapper = styled.div`
  width: calc(100% - 40px);
  height: auto;
  min-height: 32px;
  display: flex;
  flex-flow: column;
  justify-content: center;
`;

const StyledLabel = styled(Typography)`
  width: 100%;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${theme.mixins.typography.fontColor.lightGray} !important;
  font-size: ${theme.mixins.typography.fontSize.twelve}px !important;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  padding-bottom: ${theme.mixins.spacing / 2}px;
`;

const StyledPlaceholder = styled(Typography)`
  width: 100%;
  height: auto;
  color: ${theme.mixins.typography.fontColor.lightGray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
`;

const styleForText: CSSProperties = {
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  color: theme.mixins.typography.fontColor.black,
  fontSize: theme.mixins.typography.fontSize.sixteen,
  fontWeight: theme.mixins.typography.fontWeight.fourHundreds,
  fontFamily: theme.mixins.typography.fontFamily,
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-all'
};

const IconWrapper = styled.div`
  width: 40px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const ArrowIcon = styled(ArrowForwardIosIcon)`
  color: ${theme.mixins.typography.fontColor.lightGray};
`;
