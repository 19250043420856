import React from 'react';
import styled from 'styled-components';
import { Path } from '../../../router/Routes';
import HomeScreen from '../../board/BoardScreen';
import LiffScreen from '../../liff/LiffScreen';
import RegisterScreen from '../../register/RegisterScreen';
import SignOutScreen from '../../sign-out/SignOutScreen';
import SignUpInScreen from '../../sign-up-in/SignUpInScreen';

interface P {
  path: Path;
}

const NoGuardedScreens: React.FC<P> = React.memo(props => {
  const { path } = props;

  return (
    <>
      {path === Path.LIFF && <LiffScreen />}

      {(path === Path.SIGN_UP || path === Path.SIGN_IN) && <SignUpInScreen />}

      {path === Path.SIGN_OUT && <SignOutScreen />}

      {path === Path.REGISTER_ACCOUNT && <RegisterScreen />}

      <ScreenWrapper display={(path === Path.BOARD_MEMBER || path === Path.BOARD_MESSAGE).toString()}>
        <HomeScreen />
      </ScreenWrapper>
    </>
  );
});

export default NoGuardedScreens;

const ScreenWrapper = styled.div<{ display: string }>`
  width: 100%;
  height: auto;
  display: ${props => (props.display === 'true' ? 'block' : 'none')};
`;
