import {
  Component,
  Icon,
  styleForFullExpansion,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { builder } from '@atomica.co/utils';
import { BoardEntity, BoardMemberRole, SaveBoardNotificationStatusRequest, UserId } from '@atomica.co/yosemal-v2';
import { Typography } from '@material-ui/core';
import { default as React } from 'react';
import styled from 'styled-components';
import BoardRequest from '../../../requests/board-request';
import { BoardSettingsIndex } from '../board-full-modal/board-settings/BoardSettings';
import connections from './../../../assets/icon/icon_connections.png';
import leave from './../../../assets/icon/icon_leave.png';
import notification_off from './../../../assets/icon/icon_notification_off.png';
import notification_on from './../../../assets/icon/icon_notification_on.png';

interface P {
  role: BoardMemberRole;
  userId: UserId;
  board: BoardEntity | undefined;
  onClickIcon(index: BoardSettingsIndex): void;
}

const BoardSettingsHeader: React.FC<P> = React.memo(props => {
  const { role, userId, board, onClickIcon } = props;
  const unmountRef = useUnmountRef();
  const [isNotificationEnabled, setIsNotificationEnabled] = useSafeState<boolean>(
    unmountRef,
    !!board ? board.receiveNotifications : true
  );

  const handleNotificationIconClicked = useSafeCallback(async (): Promise<void> => {
    if (!board) return;

    setIsNotificationEnabled(prevIsNotificationEnabled => {
      const isNotificationEnabledToUpdate = !prevIsNotificationEnabled;

      const request = builder<SaveBoardNotificationStatusRequest>()
        .boardId(board.boardId)
        .userId(userId)
        .receiveNotifications(isNotificationEnabledToUpdate)
        .build();

      BoardRequest.saveBoardNotificationStatus(request);
      return isNotificationEnabledToUpdate;
    });
  }, [board, setIsNotificationEnabled, userId]);

  return (
    <Component style={styleForFullExpansion} className='board-settings-header'>
      <Container>
        <ButtonWrapper>
          <Button onClick={handleNotificationIconClicked}>
            <IconWrapper>
              {isNotificationEnabled ? (
                <Icon size='small' src={notification_on} />
              ) : (
                <Icon size='small' src={notification_off} />
              )}
            </IconWrapper>
          </Button>
          <ButtonLabel>{isNotificationEnabled ? '通知オフ' : '通知オン'}</ButtonLabel>
        </ButtonWrapper>

        <ButtonWrapper>
          <Button onClick={() => onClickIcon(BoardSettingsIndex.SHOW_BOARD_MEMBERS)}>
            <IconWrapper>
              <Icon size='small' src={connections} />
            </IconWrapper>
          </Button>
          <ButtonLabel>メンバー</ButtonLabel>
        </ButtonWrapper>

        {role !== BoardMemberRole.OWNER && (
          <ButtonWrapper>
            <Button onClick={() => onClickIcon(BoardSettingsIndex.LEAVE_BOARD)}>
              <IconWrapper>
                <Icon size='small' src={leave} />
              </IconWrapper>
            </Button>
            <ButtonLabel>退会</ButtonLabel>
          </ButtonWrapper>
        )}
      </Container>
    </Component>
  );
});

export default BoardSettingsHeader;

const Container = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const ButtonWrapper = styled.div`
  width: 56x;
  height: 51px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

const Button = styled.div``;

const ButtonLabel = styled(Typography)`
  width: 56px;
  height: auto;
  color: ${theme.mixins.typography.fontColor.black};
  font-size: ${theme.mixins.typography.fontSize.twelve}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds} !important;
  font-family: ${theme.mixins.typography.fontFamily};
  text-align: center;
  margin-top: -${theme.mixins.spacing}px;
`;

const IconWrapper = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
