import { Component } from '@atomica.co/components';
import React from 'react';
import Header from '../../../components/header/Header';

interface P {}

const NotificationHeader: React.FC<P> = React.memo(() => {
  return (
    <Component className='notification-header'>
      <Header label='通知' />
    </Component>
  );
});

export default NotificationHeader;
