import { useSafeCallback } from '@atomica.co/components';
import { builder, EMAIL_CONST, getQueryParams, LIFF_STATE, REDIRECT_PATH } from '@atomica.co/utils';
import { ProviderId, VerifyUserRequest } from '@atomica.co/yosemal-v2';
import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import Screen from '../../components/screen/Screen';
import firebase, { auth } from '../../firebase';
import { getLiff } from '../../line';
import { QueryParams } from '../../models/path-model';
import usePath from '../../redux/hooks/usePath';
import useUser from '../../redux/hooks/useUser';
import { persistor } from '../../redux/store';
import AuthRequest from '../../requests/auth-request';

interface P extends RouteComponentProps {}

const LiffScreen: React.FC<P> = React.memo(() => {
  const { queryParams, openPath } = usePath();
  const { existUser, createNonActivatedUserIfNotExisted } = useUser();

  const openRedirectPath = useSafeCallback((): void => {
    const liffState = queryParams[LIFF_STATE];
    const redirectPath = !!liffState
      ? getQueryParams<QueryParams>(liffState)[REDIRECT_PATH]
      : queryParams[REDIRECT_PATH];
    openPath(redirectPath);
  }, [queryParams, openPath]);

  const succeededToSignIn = useSafeCallback(
    async (res: firebase.auth.UserCredential): Promise<void> => {
      if (!res.user) {
        openRedirectPath();
        return;
      }

      const email =
        !!res.additionalUserInfo && !!res.additionalUserInfo.profile
          ? res.additionalUserInfo.profile[EMAIL_CONST]
          : res.user.email;

      if (!email) {
        openRedirectPath();
        return;
      }

      await createNonActivatedUserIfNotExisted(res.user, email);
      openRedirectPath();
    },
    [openRedirectPath, createNonActivatedUserIfNotExisted]
  );

  const failedToSignIn = useSafeCallback((): void => {
    openRedirectPath();
  }, [openRedirectPath]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    try {
      const [isSignedIn] = await Promise.all([existUser(), persistor.purge()]);

      if (isSignedIn) {
        openRedirectPath();
        return;
      }

      const liff = await getLiff();

      if (!liff.isLoggedIn()) {
        liff.login();
        return;
      }

      const idToken = await liff.getIDToken();

      if (!idToken) {
        openRedirectPath();
        return;
      }

      const request = builder<VerifyUserRequest>().idToken(idToken).providerId(ProviderId.LINE).build();
      const response = await AuthRequest.verifyUser(request);
      const customToken = response.customToken;

      if (!customToken) {
        failedToSignIn();
        return;
      }

      auth.signInWithCustomToken(customToken).then(succeededToSignIn).catch(failedToSignIn);
    } catch (e) {
      openRedirectPath();
    }
  }, [existUser, openRedirectPath, succeededToSignIn, failedToSignIn]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return <Screen loading={true} className='liff-screen' />;
});

export default LiffScreen;
