import { EMPTY, Name } from '@atomica.co/utils';
import firebase from 'firebase';
import {
  BoardMessageEntity,
  BoardMessagePdfEntity,
  BoardThreadEntity,
  NotificationEntity,
  ProfileEntity,
  ProviderId,
  SaveNewUserRequest,
  UserEntity
} from '@atomica.co/yosemal-v2';

export const toFullName = (
  entity:
    | UserEntity
    | ProfileEntity
    | SaveNewUserRequest
    | BoardMessageEntity
    | BoardThreadEntity
    | NotificationEntity
    | BoardMessagePdfEntity
    | undefined
): Name => (!!entity ? `${entity.familyName} ${entity.firstName}` : EMPTY);

export const toProviderId = (fbUser: firebase.User): ProviderId => {
  const providerId = fbUser.displayName;

  switch (providerId) {
    case ProviderId.LINE:
      return ProviderId.LINE;

    default:
      return ProviderId.GOOGLE;
  }
};
