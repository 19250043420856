import { Component, Scrollable, theme } from '@atomica.co/components';
import { BoardEntity } from '@atomica.co/yosemal-v2';
import React from 'react';
import styled from 'styled-components';
import BoardDetailsPolaroid from '../../../../components/polaroid/BoardDetailsPolaroid';
import BoardMessageEntryHeader from '../../board-header/BoardMessageEntryHeader';
import { BoardMessageIndex } from '../BoardMessage';
import BoardMembers, { BoardMembersRef } from './BoardMembers';

interface P {
  board: BoardEntity | undefined;
  onClickWriteButton(index: BoardMessageIndex): void;
}

const BoardMessageEntry: React.ForwardRefExoticComponent<P & React.RefAttributes<BoardMembersRef>> = React.forwardRef<
  BoardMembersRef,
  P
>((props, ref) => {
  const { board, onClickWriteButton } = props;

  return (
    <Component style={{ width: '100%' }} className='board-message-entry'>
      <BoardMessageEntryHeader onClickWriteButton={onClickWriteButton} board={board} />

      <Scrollable showScrollbar>
        <Container>
          <PolaroidWrapper>
            <BoardDetailsPolaroid rotate='none' board={board} />
          </PolaroidWrapper>

          <CardListWrapper>
            <BoardMembers ref={ref} boardId={!!board ? board.boardId : undefined} />
          </CardListWrapper>
        </Container>
      </Scrollable>
    </Component>
  );
});

export default BoardMessageEntry;

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding-top: ${theme.mixins.spacing}px;
`;

const PolaroidWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
`;

const CardListWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
`;
