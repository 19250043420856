import { Component, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import React from 'react';
import firebase from './../../../firebase';
import ResetPassword from './reset-password/ResetPassword';
import SignInOptionList from './sign-in-option-list/SignInOptionList';

export enum SignInStatus {
  SELECT_SIGN_IN_OPTION,
  RESET_PASSWORD
}

interface P {
  onSignIn(res: firebase.auth.UserCredential): void;
}

const SignIn: React.FC<P> = React.memo(props => {
  const { onSignIn } = props;
  const unmountRef = useUnmountRef();
  const [index, setIndex] = useSafeState<SignInStatus>(unmountRef, SignInStatus.SELECT_SIGN_IN_OPTION);

  const showResetPasswordScreen = useSafeCallback((): void => {
    setIndex(SignInStatus.RESET_PASSWORD);
  }, [setIndex]);

  const showSignInScreen = useSafeCallback((): void => {
    setIndex(SignInStatus.SELECT_SIGN_IN_OPTION);
  }, [setIndex]);

  return (
    <Component className='sign-in'>
      {index === SignInStatus.SELECT_SIGN_IN_OPTION && (
        <SignInOptionList onSignIn={onSignIn} resetPassword={showResetPasswordScreen} />
      )}

      {index === SignInStatus.RESET_PASSWORD && <ResetPassword goBack={showSignInScreen} />}
    </Component>
  );
});

export default SignIn;
