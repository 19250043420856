import { convert, RequestType } from '@atomica.co/utils';
import {
  FetchUserByExternalIdRequest,
  FetchUserByExternalIdResponse,
  FetchUserProfileRequest,
  FetchUserProfileResponse,
  FetchUserRequest,
  FetchUserResponse,
  FETCH_USER,
  FETCH_USER_BY_EXTERNAL_ID,
  FETCH_USER_PROFILE,
  SaveFirebaseRequest,
  SaveFirebaseResponse,
  SaveNewUserRequest,
  SaveNewUserResponse,
  SaveUserBeriefHistoryRequest,
  SaveUserBeriefHistoryResponse,
  SaveUserCollegeRequest,
  SaveUserCollegeResponse,
  SaveUserCollegeYearRequest,
  SaveUserCollegeYearResponse,
  SaveUserCompanyNameRequest,
  SaveUserCompanyNameResponse,
  SaveUserDepartmentNameRequest,
  SaveUserDepartmentNameResponse,
  SaveUserDepartmentRequest,
  SaveUserDepartmentResponse,
  SaveUserEmailToContactRequest,
  SaveUserEmailToContactResponse,
  SaveUserFacebookURLRequest,
  SaveUserFacebookURLResponse,
  SaveUserFacultyRequest,
  SaveUserFacultyResponse,
  SaveUserFrameIdRequest,
  SaveUserFrameIdResponse,
  SaveUserInstagramURLRequest,
  SaveUserInstagramURLResponse,
  SaveUserMajorRequest,
  SaveUserMajorResponse,
  SaveUserPhotoRequest,
  SaveUserPhotoResponse,
  SaveUserResearchSubjectRequest,
  SaveUserResearchSubjectResponse,
  SaveUserrSelfIntroductionResponse,
  SaveUserSelfIntroductionRequest,
  SaveUserTitleRequest,
  SaveUserTitleResponse,
  SaveUserTwitterURLRequest,
  SaveUserTwitterURLResponse,
  SAVE_FIREBASE,
  SAVE_NEW_USER,
  SAVE_USER_BERIEF_HISTORY,
  SAVE_USER_COLLEGE,
  SAVE_USER_COLLEGE_YEAR,
  SAVE_USER_COMPANY_NAME,
  SAVE_USER_DEPARTMENT,
  SAVE_USER_DEPARTMENT_NAME,
  SAVE_USER_EMAIL_TO_CONTACT,
  SAVE_USER_FACEBOOK_URL,
  SAVE_USER_FACULTY,
  SAVE_USER_FRAME_ID,
  SAVE_USER_INSTAGRAM_URL,
  SAVE_USER_MAJOR,
  SAVE_USER_PHOTO,
  SAVE_USER_RESEARCH_SUBJECT,
  SAVE_USER_SELF_INTRODUCTION,
  SAVE_USER_TITLE,
  SAVE_USER_TWITTER_URL,
  toFuncName
} from '@atomica.co/yosemal-v2';
import { functions } from '../firebase';

export default class UserRequest {
  public static saveNewUser = async (request: SaveNewUserRequest): Promise<SaveNewUserResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_NEW_USER));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static saveUserPhoto = async (request: SaveUserPhotoRequest): Promise<SaveUserPhotoResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_USER_PHOTO));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static saveUserFrameId = async (request: SaveUserFrameIdRequest): Promise<SaveUserFrameIdResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_USER_FRAME_ID));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static saveCompanyName = async (request: SaveUserCompanyNameRequest): Promise<SaveUserCompanyNameResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_USER_COMPANY_NAME));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static saveDepartmentName = async (
    request: SaveUserDepartmentNameRequest
  ): Promise<SaveUserDepartmentNameResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_USER_DEPARTMENT_NAME));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static saveTitle = async (request: SaveUserTitleRequest): Promise<SaveUserTitleResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_USER_TITLE));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static saveBeriefHistory = async (
    request: SaveUserBeriefHistoryRequest
  ): Promise<SaveUserBeriefHistoryResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_USER_BERIEF_HISTORY));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static saveCollege = async (request: SaveUserCollegeRequest): Promise<SaveUserCollegeResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_USER_COLLEGE));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static saveCollegeYear = async (request: SaveUserCollegeYearRequest): Promise<SaveUserCollegeYearResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_USER_COLLEGE_YEAR));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static saveFaculty = async (request: SaveUserFacultyRequest): Promise<SaveUserFacultyResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_USER_FACULTY));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static saveDepartment = async (request: SaveUserDepartmentRequest): Promise<SaveUserDepartmentResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_USER_DEPARTMENT));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static saveMajor = async (request: SaveUserMajorRequest): Promise<SaveUserMajorResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_USER_MAJOR));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static saveResearchSubject = async (
    request: SaveUserResearchSubjectRequest
  ): Promise<SaveUserResearchSubjectResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_USER_RESEARCH_SUBJECT));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static saveSelfIntroduction = async (
    request: SaveUserSelfIntroductionRequest
  ): Promise<SaveUserrSelfIntroductionResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_USER_SELF_INTRODUCTION));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static saveEmailToContact = async (
    request: SaveUserEmailToContactRequest
  ): Promise<SaveUserEmailToContactResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_USER_EMAIL_TO_CONTACT));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static saveTwitterURL = async (request: SaveUserTwitterURLRequest): Promise<SaveUserTwitterURLResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_USER_TWITTER_URL));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static saveFacebookURL = async (request: SaveUserFacebookURLRequest): Promise<SaveUserFacebookURLResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_USER_FACEBOOK_URL));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static saveInstagramURL = async (
    request: SaveUserInstagramURLRequest
  ): Promise<SaveUserInstagramURLResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_USER_INSTAGRAM_URL));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static saveFirebase = async (request: SaveFirebaseRequest): Promise<SaveFirebaseResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_FIREBASE));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static fetchUser = async (request: FetchUserRequest): Promise<FetchUserResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(FETCH_USER));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static fetchUserByExternalId = async (
    request: FetchUserByExternalIdRequest
  ): Promise<FetchUserByExternalIdResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(FETCH_USER_BY_EXTERNAL_ID));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static fetchProfile = async (request: FetchUserProfileRequest): Promise<FetchUserProfileResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(FETCH_USER_PROFILE));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };
}
