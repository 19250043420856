import { BoardMemberRole } from '@atomica.co/yosemal-v2';

export const toRole = (role: BoardMemberRole | undefined): BoardMemberRole => {
  switch (role) {
    case BoardMemberRole.OWNER:
    case BoardMemberRole.ADMIN:
    case BoardMemberRole.MEMBER:
      return role;

    default:
      return BoardMemberRole.MEMBER;
  }
};
