import { Component, getMobileWidth, SingleLineText, Skeleton, theme } from '@atomica.co/components';
import { EMPTY, Height, Width } from '@atomica.co/utils';
import { FrameId, UserEntity } from '@atomica.co/yosemal-v2';
import { CSSProperties } from '@material-ui/styles';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { FramePos, FRAME_POSITION_FOR_PORTRAIT_POLAROID } from '../../constants/frame-constant';
import { toFullName } from '../../utils/user-util';

export type Size = 'large' | 'medium' | 'xmedium' | 'small';

export type Rotate = 'none' | 'right' | 'left';

interface P {
  size: Size;
  rotate: Rotate;
  frameId: FrameId | undefined;
  user: UserEntity | undefined;
}

const UserPolaroid: React.FC<P> = React.memo(props => {
  const { size, rotate, frameId, user } = props;

  const photoWidth = useMemo<Width | undefined>(() => {
    const fullWidth = getMobileWidth();

    switch (size) {
      case 'large':
        return fullWidth - theme.mixins.spacing * 4;
      case 'medium':
        return fullWidth / 2 - theme.mixins.spacing * 5;
      case 'xmedium':
        return fullWidth / 2 - theme.mixins.spacing * 7;
      case 'small':
        return fullWidth / 4 - theme.mixins.spacing * 3;
    }
  }, [size]);

  const photoHeight = useMemo<Height | undefined>(() => {
    return photoWidth;
  }, [photoWidth]);

  const cardWidth = useMemo<Width | undefined>(() => {
    switch (size) {
      case 'large':
        return photoWidth! + theme.mixins.spacing * 2;
      case 'medium':
        return photoWidth! + theme.mixins.spacing * 2;
      case 'xmedium':
        return photoWidth! + theme.mixins.spacing * 2;
      case 'small':
        return photoWidth! + theme.mixins.spacing;
    }
  }, [size, photoWidth]);

  const cardHeight = useMemo<Height | undefined>(() => {
    switch (size) {
      case 'large':
        return photoHeight! + theme.mixins.spacing * 12;
      case 'medium':
        return photoHeight! + theme.mixins.spacing * 6;
      case 'xmedium':
        return photoHeight! + theme.mixins.spacing * 5;
      case 'small':
        return photoHeight! + theme.mixins.spacing * 4;
    }
  }, [size, photoHeight]);

  const frame = useMemo<FramePos | undefined>(() => {
    return !!frameId ? FRAME_POSITION_FOR_PORTRAIT_POLAROID[frameId] : undefined;
  }, [frameId]);

  return (
    <Component className='user-polaroid'>
      <Container width={cardWidth} height={cardHeight}>
        <Card size={size} rotate={rotate} width={cardWidth} height={cardHeight}>
          <Skeleton
            animation='wave'
            variant='rect'
            style={getStyleForPhoto(photoWidth!, photoHeight!)}
            src={!!user ? user.photoURL! : EMPTY}
          />

          <TextWrapperForName size={size}>
            <SingleLineText style={styleForName(size)} text={!!user ? toFullName(user) : undefined} />
          </TextWrapperForName>

          {size === 'large' && (
            <SingleLineText
              style={styleForDate}
              text={!!user ? `${user.dateOfBirth.getMonth() + 1}月${user.dateOfBirth.getDate()}日生まれ` : undefined}
            />
          )}
        </Card>

        {!!frame && <Stamp rotate={rotate} frame={frame} src={frame.src} />}
      </Container>
    </Component>
  );
});

export default UserPolaroid;

const Container = styled.div<{
  width: Width;
  height: Height;
}>`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  display: flex;
  align-items: flex-start;
  position: relative;
`;

const Card = styled.div<{
  size: Size;
  rotate: Rotate;
  width: Width;
  height: Height;
}>`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  border: 0.25px solid rgba(0, 0, 0, 0.4);
  background: ${theme.mixins.background.white};
  box-shadow: ${theme.mixins.shadow};
  display: flex;
  flex-flow: column;
  position: absolute;
  transform: ${props => {
    switch (props.rotate) {
      case 'right':
        return 'rotate(2.25deg)';
      case 'left':
        return 'rotate(-1.79deg)';
      default:
        return 'unset';
    }
  }};
  padding: ${props => {
    switch (props.size) {
      case 'large':
        return `${theme.mixins.spacing * 4}px ${theme.mixins.spacing * 2}px ${theme.mixins.spacing}px`;
      case 'medium':
        return `${theme.mixins.spacing}px`;
      case 'xmedium':
        return `${theme.mixins.spacing}px`;
      case 'small':
        return `${theme.mixins.spacing / 2}px`;
    }
  }};
`;

const getStyleForPhoto = (width: Width, height: Height): CSSProperties => {
  return {
    width,
    height,
    background: theme.mixins.background.lightGray,
    border: theme.mixins.border.lightGray,
    boxShadow: `inset ${theme.mixins.shadow}`,
    objectFit: 'contain'
  };
};

const Stamp = styled.img<{ rotate: Rotate; frame: FramePos }>`
  width: ${props => props.frame.width};
  object-fit: contain;
  position: absolute;
  ${theme.mixins.userSelectDisabled};
  ${props => {
    switch (props.rotate) {
      case 'right':
        return `
          margin-top: calc(${props.frame.top} - 4.5%);
          margin-left: calc(${props.frame.left} + 2%);
          transform: rotate(2.25deg);
        `;
      case 'left':
        return `
          margin-top: calc(${props.frame.top} - 3%);
          margin-left: calc(${props.frame.left} - 1%);
          transform: rotate(-1.79deg);
        `;
      default:
        return `
          margin-top: ${props.frame.top};
          margin-left: ${props.frame.left};
        `;
    }
  }};
`;

const TextWrapperForName = styled.div<{ size: Size }>`
  width: 100%;
  height: ${props => (props.size === 'large' ? 36 : 24)}px;
  padding: ${theme.mixins.spacing}px 0px;
`;

const styleForName = (size: Size): CSSProperties => {
  return {
    width: '100%',
    height: 'auto',
    color: theme.mixins.typography.fontColor.black,
    fontSize:
      size === 'large'
        ? theme.mixins.typography.fontSize.twenty
        : size === 'medium'
        ? theme.mixins.typography.fontSize.twelve
        : theme.mixins.typography.fontSize.eight,
    fontWeight: theme.mixins.typography.fontWeight.sevenHundreds,
    fontFamily: theme.mixins.typography.fontFamily,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    userSelect: 'none'
  };
};

const styleForDate: CSSProperties = {
  width: '100%',
  height: 16,
  color: theme.mixins.typography.fontColor.black,
  fontSize: theme.mixins.typography.fontSize.twelve,
  fontWeight: theme.mixins.typography.fontWeight.fourHundreds,
  fontFamily: theme.mixins.typography.fontFamily,
  userSelect: 'none'
};
