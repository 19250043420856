import { Component, Icon, theme } from '@atomica.co/components';
import { UserEntity } from '@atomica.co/yosemal-v2';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import React from 'react';
import styled from 'styled-components';
import Header from '../../../components/header/Header';
import { toFullName } from '../../../utils/user-util';
import { AccountAction } from '../AccountScreen';
import qr from './../../../assets/icon/icon_qr.png';

interface P {
  isMyAccount: boolean;
  user: UserEntity | undefined;
  emitAction(action: AccountAction): void;
}

const ProfileHeader: React.FC<P> = React.memo(props => {
  const { isMyAccount, user, emitAction } = props;

  return (
    <Component className='profile-header'>
      <Header
        label={isMyAccount ? 'マイアカウント' : toFullName(user)}
        leftChild={<LeftArea />}
        rightChild={
          <RightArea>
            {isMyAccount && (
              <ButtonWrapper>
                <Button onClick={() => emitAction(AccountAction.SHOW_QR)}>
                  <Icon size='xsmall' style={styleForIcon} src={qr} />
                </Button>
              </ButtonWrapper>
            )}
          </RightArea>
        }
      />
    </Component>
  );
});

export default ProfileHeader;

const LeftArea = styled.div`
  width: 88px;
  height: auto;
  padding-left: ${theme.mixins.spacing}px;
`;

const RightArea = styled.div`
  width: 88px;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding-right: ${theme.mixins.spacing}px;
`;

const ButtonWrapper = styled.div<{ show: boolean }>`
  width: 40px;
  height: auto;

  &:after {
    width: 40px;
    height: 6px;
    content: '';
    display: ${props => (props.show ? 'block' : 'none')};
    background-color: ${theme.mixins.border.pink};
    border-radius: 3px;
  }
`;

const Button = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding-top: ${theme.mixins.spacing}px;
`;

const styleForIcon: CSSProperties = {
  color: theme.mixins.typography.fontColor.lightGray
};
