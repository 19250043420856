import { CollegeYear, UserDiv } from '@atomica.co/yosemal-v2';
import { Labels } from '../models/common-model';

export const USER_DIV_LABELS: Labels = {
  [UserDiv.STUDENT]: '学生',
  [UserDiv.WORKER]: '社会人'
};

export const COLLEGE_YEAR_LABELS: Labels = {
  [CollegeYear.B1]: '学士1年生',
  [CollegeYear.B2]: '学士2年生',
  [CollegeYear.B3]: '学士3年生',
  [CollegeYear.B4]: '学士4年生',
  [CollegeYear.M1]: '修士1年生',
  [CollegeYear.M2]: '修士2年生',
  [CollegeYear.D1]: '博士1年生',
  [CollegeYear.D2]: '博士2年生',
  [CollegeYear.D3]: '博士3年生'
};
