import { Component, Scrollable, theme } from '@atomica.co/components';
import { Email } from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import React from 'react';
import styled from 'styled-components';
import Logo from '../../../../../components/logo/Logo';
import { HEADER_HEIGHT } from '../../../../../constants/common-constants';

interface P {
  email: Email;
}

const EmailSent: React.FC<P> = React.memo(props => {
  const { email } = props;

  return (
    <Component className='email-sent'>
      <Container>
        <Scrollable>
          <LogoWrapper>
            <Logo />
          </LogoWrapper>

          <BoxWrapper>
            <Box>
              <IconWrapper>
                <CheckIcon />
              </IconWrapper>
              <Message>メールを送信しました</Message>
            </Box>
          </BoxWrapper>

          <RemarksWrapper>
            <Remarks>
              <Row>{email}</Row>
              <Row>宛にメールを送信しました。</Row>
            </Remarks>

            <Remarks>
              <Row>本文内のURLにアクセスし、</Row>
              <Row>パスワードを再設定してください。</Row>
            </Remarks>
          </RemarksWrapper>
        </Scrollable>
      </Container>
    </Component>
  );
});

export default EmailSent;

const Container = styled.div`
  width: 100%;
  height: calc(100vh - ${HEADER_HEIGHT}px);
  display: flex;
  flex-flow: column;
  align-items: center;
`;

const LogoWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: ${theme.mixins.spacing * 2}px;
`;

const BoxWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${theme.mixins.spacing * 2}px;
`;

const Box = styled.div`
  width: 288px;
  height: 48px;
  border-radius: 24px;
  background: ${theme.mixins.background.white};
  display: flex;
  align-items: center;
  box-shadow: ${theme.mixins.shadow.light};
`;

const IconWrapper = styled.div`
  width: 48px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Message = styled(Typography)`
  width: calc(100% - 56px);
  height: auto;
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
`;

const RemarksWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing * 2}px;
  margin-bottom: ${theme.mixins.spacing * 10}px;
`;

const Remarks = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px;
`;

const Row = styled(Typography)`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.twelve}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
`;
