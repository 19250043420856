import {
  AutoSizedText,
  Button,
  Component,
  getMobileWidth,
  Icon,
  SingleLineText,
  Skeleton,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { EMPTY, getDayOfWeek, Height, Language, noop, toDateStr, URL, Width } from '@atomica.co/utils';
import { BoardMessageEntity } from '@atomica.co/yosemal-v2';
import { Typography } from '@material-ui/core';
import { CSSProperties } from '@material-ui/styles';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { FramePos as FrameVo, FRAME_POSITION_FOR_LANDSCAPE_POLAROID } from '../../constants/frame-constant';
import PhotoRequest from '../../requests/photo-request';
import { toFullName } from '../../utils/user-util';
import addPhoto from './../../assets/icon/icon_photo_add.png';

interface P {
  isRequiredNewPhoto: boolean | undefined;
  message: BoardMessageEntity | undefined;
  onUpload(photoURL: URL): void;
}

const BoardMessagePhotoUploader: React.FC<P> = React.memo(props => {
  const { isRequiredNewPhoto = false, message, onUpload } = props;

  const cardWidth = useMemo<Width>(() => getMobileWidth() - theme.mixins.spacing * 4, []);
  const cardHeight = useMemo<Height>(() => (cardWidth * 2) / 3 + theme.mixins.spacing, [cardWidth]);
  const frame = useMemo<FrameVo | undefined>(
    () =>
      !!message && !!message.photoURL && !!message.frameId
        ? FRAME_POSITION_FOR_LANDSCAPE_POLAROID[message.frameId]
        : undefined,
    [message]
  );

  const unmountRef = useUnmountRef();
  const [photoURL, setPhotoURL] = useSafeState<URL | undefined>(unmountRef, !!message ? message.photoURL : undefined);

  const handleBoardPhotoSelected = useSafeCallback(
    async (image): Promise<void> => {
      const uploadedPhotoURL = await PhotoRequest.uploadMessagePhoto(image);
      setPhotoURL(uploadedPhotoURL);
      onUpload(uploadedPhotoURL);
    },
    [setPhotoURL, onUpload]
  );

  return (
    <Component className='board-message-photo-uploader'>
      <Container width={cardWidth} height={cardHeight}>
        <CardWrapper>
          <Card width={cardWidth} height={cardHeight}>
            <LeftArea>
              <Uploader
                type='file'
                accept='image/*'
                width={cardWidth}
                onChange={e => handleBoardPhotoSelected(e.target.files[0])}
              />

              <PhotoWrapper>
                <Skeleton
                  animation='wave'
                  variant='rect'
                  style={getStyleForPhoto(cardWidth!)}
                  src={!!photoURL ? photoURL : EMPTY}
                />

                <ButtonWrapper>
                  <Button onClick={noop}>
                    <Icon size='small' src={addPhoto} />
                  </Button>
                </ButtonWrapper>
              </PhotoWrapper>

              <NameWrapper>
                <SingleLineText style={styleForName} text={!!message ? toFullName(message) : undefined} />
              </NameWrapper>

              <DateWrapper>
                <SingleLineText
                  style={styleForDate}
                  text={
                    !!message ? `${toDateStr(new Date(), Language.JAPANESE)} ${getDayOfWeek(new Date())}` : undefined
                  }
                />
              </DateWrapper>
            </LeftArea>

            <RightArea>
              <MessageWrapper>
                <AutoSizedText style={styleForMessage} text={!!message ? message.text : EMPTY} />
              </MessageWrapper>
            </RightArea>
          </Card>

          {isRequiredNewPhoto && <Alert>このイベントは新しい写真必須に設定されています。</Alert>}
        </CardWrapper>

        {!!frame && <Stamp frame={frame} src={frame.src} />}
      </Container>
    </Component>
  );
});

export default BoardMessagePhotoUploader;

const Container = styled.div<{
  width: Width;
  height: Height;
}>`
  width: ${props => props.width + theme.mixins.spacing * 2}px;
  height: ${props => props.height + theme.mixins.spacing * 2}px;
  position: relative;
  display: flex;
  justify-content: center;
`;

const CardWrapper = styled.div`
  width: 100%;
  height: auto;
`;

const Card = styled.div<{
  width: Width;
  height: Height;
}>`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  border: ${theme.mixins.border.lightGray};
  background: ${theme.mixins.background.white};
  box-shadow: ${theme.mixins.shadow.light};
  display: flex;
`;

const LeftArea = styled.div`
  width: 50%;
  height: 100%;
`;

const Uploader = styled.input<{ width: Width }>`
  width: ${props => props.width / 2}px;
  height: ${props => props.width / 2 + theme.mixins.spacing}px;
  opacity: 0;
  appearance: none;
  position: absolute;
  z-index: 10;
`;

const PhotoWrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing / 2}px ${theme.mixins.spacing / 2}px
    ${theme.mixins.spacing}px;
`;

const getStyleForPhoto = (width: Width): CSSProperties => {
  return {
    width: width / 2 - theme.mixins.spacing,
    height: width / 2 - theme.mixins.spacing,
    background: theme.mixins.background.lightGray,
    border: theme.mixins.border.lightGray,
    boxShadow: `inset ${theme.mixins.shadow.light}`,
    objectFit: 'contain'
  };
};

const ButtonWrapper = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: ${theme.mixins.background.black};
  opacity: 0.6;
  position: absolute;
  right: 0px;
  bottom: 0px;
`;

const NameWrapper = styled.div`
  width: 100%;
  height: 28px;
  padding: ${theme.mixins.spacing / 4}px ${theme.mixins.spacing / 2}px ${theme.mixins.spacing / 4}px;
  ${(theme.mixins.spacing * 3) / 2}px;
  ${theme.mixins.userSelectDisabled};
`;

const styleForName: CSSProperties = {
  width: '100%',
  height: 16,
  color: theme.mixins.typography.fontColor.black,
  fontSize: theme.mixins.typography.fontSize.sixteen,
  fontWeight: theme.mixins.typography.fontWeight.sevenHundreds,
  fontFamily: theme.mixins.typography.fontFamily
};

const DateWrapper = styled.div`
  width: 100%;
  height: 24px;
  padding: ${theme.mixins.spacing / 4}px ${theme.mixins.spacing / 2}px ${theme.mixins.spacing / 4}px;
  ${(theme.mixins.spacing * 3) / 2}px;
  ${theme.mixins.userSelectDisabled};
`;

const styleForDate: CSSProperties = {
  width: '100%',
  height: 16,
  color: theme.mixins.typography.fontColor.lightGray,
  fontSize: theme.mixins.typography.fontSize.twelve,
  fontWeight: theme.mixins.typography.fontWeight.fourHundreds,
  fontFamily: theme.mixins.typography.fontFamily
};

const RightArea = styled.div`
  width: 50%;
  height: 100%;
`;

const MessageWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing}px ${theme.mixins.spacing}px ${theme.mixins.spacing / 2}px;
`;

const styleForMessage: CSSProperties = {
  width: '100%',
  height: '100%',
  color: theme.mixins.typography.fontColor.black,
  fontWeight: theme.mixins.typography.fontWeight.sevenHundreds,
  fontFamily: theme.mixins.typography.fontFamily,
  wordBreak: 'break-all',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
};

const Alert = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.pink};
  font-size: ${theme.mixins.typography.fontSize.twelve}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  padding: 0px 8px;
`;

const Stamp = styled.img<{ frame: FrameVo }>`
  width: ${props => props.frame.width};
  position: absolute;
  top: ${props => `calc(${props.frame.top} + 0%)`};
  left: ${props => `calc(${props.frame.left} - 2.5%)`};
  object-fit: contain;
  z-index: 10;
  ${theme.mixins.userSelectDisabled};
`;
