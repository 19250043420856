import { UserEntity } from '@atomica.co/yosemal-v2';
import { StyleSheet, View } from '@react-pdf/renderer';
import React from 'react';
import { Styles } from '../BoardPagesPdf';
import UserPolaroid, { USER_POLAROID_HEIGHT_RATIO } from './UserPolaroid';

const USER_POLAROID_WIDTH = 224;

const USER_POLAROID_HEIGHT = USER_POLAROID_WIDTH * USER_POLAROID_HEIGHT_RATIO;

const FONT_SIZE = 0.77;

const styles: Styles = StyleSheet.create({
  user1: {
    position: 'absolute',
    top: -8,
    left: -4
  },
  user2: {
    position: 'absolute',
    left: -12,
    bottom: -4
  },
  user3: {
    position: 'absolute',
    right: -8,
    bottom: -4
  },
  user4: {
    position: 'absolute',
    top: 56,
    left: 120
  },
  user5: {
    position: 'absolute',
    top: -12,
    right: -12
  },
  userPolaroidArea: {
    width: USER_POLAROID_WIDTH,
    height: USER_POLAROID_HEIGHT
  }
});

interface P {
  users: UserEntity[];
}

const UserPolaroidsFour: React.FC<P> = React.memo(props => {
  const { users } = props;

  return (
    <>
      {users.map((user, index) => (
        <View key={index} style={styles[`user${index + 1}`]}>
          <View style={styles.userPolaroidArea}>
            <UserPolaroid fontSize={FONT_SIZE} user={user} />
          </View>
        </View>
      ))}
    </>
  );
});

export default UserPolaroidsFour;
