import { Button, Component, Icon, theme, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import {
  builder,
  copyURL,
  embedIdInPath,
  EQUAL,
  QUESTION_MARK,
  REDIRECT_PATH,
  toDataURL,
  URL
} from '@atomica.co/utils';
import { BoardEntity, BoardId, BoardMemberRole, FetchBoardRequest, LINE_URLS, UserId } from '@atomica.co/yosemal-v2';
import { Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { ROLE } from '../../../../constants/common-constants';
import { COPY_URL, SUCCESS } from '../../../../constants/snackbar-const';
import env from '../../../../env/env';
import BoardRequest from '../../../../requests/board-request';
import { Path, PATH_IDS } from '../../../../router/Routes';
import share from './../../../../assets/icon/icon_share.png';
import ShareTabs, { ShareTabEnum } from './ShareTabs';

const MESSAGE_FOR_ADMIN = '招待する方を選んでください。\nQRコードをスキャンすると\nこのイベントに参加できます。';

const MESSAGE_FOR_MEMBER = 'QRコードをスキャンすると\nこのイベントに参加できます。';

interface P {
  boardId: BoardId | undefined;
  userId: UserId;
  onClose(): void;
}

const ShareBoard: React.FC<P> = React.memo(props => {
  const { boardId, userId, onClose } = props;
  const { enqueueSnackbar } = useSnackbar();
  const unmountRef = useUnmountRef();
  const [index, setIndex] = useSafeState<ShareTabEnum>(unmountRef, ShareTabEnum.INVITE_MEMBER);
  const [urlToShare, setUrlToShare] = useSafeState<URL>(unmountRef);
  const [dataURL, setDataURL] = useSafeState<URL>(unmountRef);
  const [board, setBoard] = useSafeState<BoardEntity | undefined>(unmountRef);

  const isAdmin = useMemo((): boolean => {
    return !!board && (board.role === BoardMemberRole.OWNER || board.role === BoardMemberRole.ADMIN);
  }, [board]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    if (!boardId) return;
    const request = builder<FetchBoardRequest>().boardId(boardId).userId(userId).build();
    const response = await BoardRequest.fetchBoard(request);
    setBoard(response.board);
  }, [boardId, userId, setBoard]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const createUrlToShare = useSafeCallback((index: ShareTabEnum, board: BoardEntity): URL => {
    switch (index) {
      case ShareTabEnum.INVITE_ADMIN:
        return `${LINE_URLS[env]}${QUESTION_MARK}${REDIRECT_PATH}${EQUAL}${embedIdInPath(Path.BOARD_MEMBER, PATH_IDS, [
          board.boardId
        ])}?${ROLE}=${BoardMemberRole.ADMIN}`;

      default:
        return `${LINE_URLS[env]}${QUESTION_MARK}${REDIRECT_PATH}${EQUAL}${embedIdInPath(Path.BOARD_MEMBER, PATH_IDS, [
          board.boardId
        ])}`;
    }
  }, []);

  const setURLAndQrCode = useSafeCallback(
    async (index: ShareTabEnum, board: BoardEntity): Promise<void> => {
      const urlToShare = createUrlToShare(index, board);
      setUrlToShare(urlToShare);

      const dataURL = await toDataURL(urlToShare);
      setDataURL(dataURL);
    },
    [createUrlToShare, setUrlToShare, setDataURL]
  );

  useEffect(() => {
    if (!board) return;
    setURLAndQrCode(index, board);
  }, [setURLAndQrCode, index, board]);

  const handleShareButtonClicked = useSafeCallback((): void => {
    if (!board) return;

    if (!!navigator.share) {
      navigator.share({ text: urlToShare });
      return;
    }

    copyURL(urlToShare);
    enqueueSnackbar(COPY_URL, { variant: SUCCESS });
  }, [board, urlToShare, enqueueSnackbar]);

  return (
    <Component className='share-board'>
      <Header>
        <ButtonArea>
          <Button onClick={onClose}>
            <CloseIcon />
          </Button>
        </ButtonArea>
      </Header>

      {isAdmin && (
        <TabWrapper>
          <ShareTabs initTab={index} onChange={setIndex} />
        </TabWrapper>
      )}

      <QRCodeWrapper>{!!dataURL && <QRCode src={dataURL} />}</QRCodeWrapper>

      <Message>{isAdmin ? MESSAGE_FOR_ADMIN : MESSAGE_FOR_MEMBER}</Message>

      <ButtonsWrapper>
        <Button onClick={handleShareButtonClicked}>
          <IconWrapper>
            <Icon size='small' src={share} />
            <Label>送る</Label>
          </IconWrapper>
        </Button>
      </ButtonsWrapper>
    </Component>
  );
});

export default ShareBoard;

const Header = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing}px 0px;
`;

const ButtonArea = styled.div`
  width: 40px;
  height: 40px;
`;

const TabWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing * 2}px ${theme.mixins.spacing}px;
`;

const QRCodeWrapper = styled.div`
  width: 100%;
  height: 176px;
  display: flex;
  justify-content: center;
  padding: ${theme.mixins.spacing}px;
`;

const QRCode = styled.img`
  width: 160px;
  height: 160px;
`;

const Message = styled.div`
  width: 100%;
  height: auto;
  color: ${theme.mixins.typography.fontColor.lightGray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  text-align: center;
  white-space: pre;
  padding: ${theme.mixins.spacing * 2}px ${theme.mixins.spacing}px;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-around;
`;

const IconWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
`;

const Label = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.lightGray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  text-align: center;
  padding-top: ${theme.mixins.spacing / 2}px;
`;
