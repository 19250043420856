import { Component } from '@atomica.co/components';
import React from 'react';
import Header from '../../../components/header/Header';

interface P {}

const BoardMessageWrittenHeader: React.FC<P> = React.memo(() => {
  return (
    <Component className='board-message-written-header'>
      <Header label='送信の完了' />
    </Component>
  );
});

export default BoardMessageWrittenHeader;
