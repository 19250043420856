import { Component, MultiLineText, SingleLineText, theme } from '@atomica.co/components';
import { builder, getTimeDiffLabel } from '@atomica.co/utils';
import { NotificationEntity, UserEntity } from '@atomica.co/yosemal-v2';
import { Typography } from '@material-ui/core';
import { CSSProperties } from '@material-ui/styles';
import React from 'react';
import styled from 'styled-components';
import UserPolaroid from '../polaroid/UserPolaroid';

interface P {
  notification: NotificationEntity;
}

const NotificationCard: React.FC<P> = React.memo(props => {
  const { notification } = props;

  return (
    <Component className='notification-card'>
      <Box>
        <PolaroidArea>
          <UserPolaroid
            size='small'
            rotate='none'
            frameId={notification.frameId}
            user={builder<UserEntity>()
              .photoURL(notification.photoURL)
              .frameId(notification.frameId)
              .familyName(notification.familyName)
              .firstName(notification.firstName)
              .createdAt(notification.createdAt)
              .build()}
          />
        </PolaroidArea>

        <TextArea>
          <Title>
            <MultiLineText style={styleForTitle} text={notification.title} />
          </Title>

          <CreatedDate>{getTimeDiffLabel(notification.createdAt)}</CreatedDate>

          {!!notification.description && (
            <Description>
              <TextWrapper>
                <SingleLineText style={styleForDescription} text={notification.description} />
              </TextWrapper>
            </Description>
          )}
        </TextArea>
      </Box>
    </Component>
  );
});

export default NotificationCard;

const Box = styled.div`
  width: 100%;
  height: auto;
  background: ${theme.mixins.background.white};
  display: flex;
  padding: ${theme.mixins.spacing / 2}px;
`;

const PolaroidArea = styled.div`
  width: auto;
  height: auto;
`;

const TextArea = styled.div`
  width: calc(100% - 80px);
  height: auto;
  padding: 0px ${theme.mixins.spacing}px;
  ${theme.mixins.userSelectDisabled};
`;

const Title = styled.div`
  width: calc(100% - 16px);
  height: auto;
  min-height: 48px;
`;

const styleForTitle: CSSProperties = {
  width: '100%',
  maxHeight: 72,
  color: theme.mixins.typography.fontColor.black,
  fontSize: 14,
  fontWeight: theme.mixins.typography.fontWeight.sevenHundreds,
  fontFamily: theme.mixins.typography.fontFamily,
  lineHeight: 1.5,
  overflow: 'hidden',
  whiteSpace: 'normal',
  textOverflow: 'ellipsis'
};

const CreatedDate = styled(Typography)`
  width: 100%;
  height: 16px;
  color: ${theme.mixins.typography.fontColor.black};
  font-size: ${theme.mixins.typography.fontSize.twelve}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
`;

const Description = styled.div`
  width: 100%;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TextWrapper = styled.div`
  width: 100%;
  height: 16px;
`;

const styleForDescription: CSSProperties = {
  color: theme.mixins.typography.fontColor.lightGray,
  fontSize: 14,
  fontWeight: theme.mixins.typography.fontWeight.fourHundreds,
  fontFamily: theme.mixins.typography.fontFamily,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis'
};
