import { Button, Component, theme, useSafeCallback } from '@atomica.co/components';
import { Typography } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import React from 'react';
import styled from 'styled-components';
import Header from '../../../components/header/Header';
import { auth } from '../../../firebase';
import usePath from '../../../redux/hooks/usePath';
import { persistor } from '../../../redux/store';
import { Path } from '../../../router/Routes';
import { AccountAction } from '../AccountScreen';

interface P {
  emitAction(action: AccountAction): void;
}

const EditProfileHeader: React.FC<P> = React.memo(props => {
  const { emitAction } = props;
  const { openPath } = usePath();

  const signOut = useSafeCallback(async (): Promise<void> => {
    await auth.signOut();
    await persistor.purge();
    openPath(Path.SIGN_IN);
  }, [openPath]);

  return (
    <Component className='edit-profile-header'>
      <Header
        label='マイアカウント'
        leftChild={
          <ButtonArea>
            <Button onClick={() => emitAction(AccountAction.REFRESH_PROFILE)}>
              <CloseIcon />
            </Button>
          </ButtonArea>
        }
        rightChild={
          <ButtonArea>
            <Button type='primary' shape='rect' onClick={signOut}>
              <ButtonLabel>ログアウト</ButtonLabel>
            </Button>
          </ButtonArea>
        }
      />
    </Component>
  );
});

export default EditProfileHeader;

const ButtonArea = styled.div`
  width: 88px;
  height: auto;
`;

const CloseIcon = styled(Close)`
  color: ${theme.mixins.typography.fontColor.lightGray};
`;

const ButtonLabel = styled(Typography)`
  width: 64px;
  height: 20px;
  color: ${theme.mixins.typography.fontColor.white};
  font-size: ${theme.mixins.typography.fontSize.twelve}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  display: flex;
  align-items: center;
  justify-content: center;
`;
