import { Screen as BaseScreen } from '@atomica.co/components';
import { Message } from '@atomica.co/utils';
import React from 'react';

type Source = string;

interface P {
  loading?: boolean;
  loadingSrc?: Source;
  loadingMsg?: Message;
  className: string;
  children?: React.ReactNode;
}

const Screen: React.FC<P> = React.memo(props => {
  const { loading, loadingSrc, loadingMsg, className, children } = props;

  return (
    <BaseScreen loading={loading} src={loadingSrc} message={loadingMsg} className={className}>
      {children}
    </BaseScreen>
  );
});

export default Screen;
