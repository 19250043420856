import { Component, getMobileWidth, SingleLineText, theme, useSafeState, useUnmountRef } from '@atomica.co/components';
import {
  EMPTY,
  getDayOfWeek,
  Height,
  isFuture,
  isMoreThanZero,
  Language,
  MINUS_ONE,
  toDateStr,
  Width,
  ZERO
} from '@atomica.co/utils';
import {
  BoardEntity,
  BoardId,
  BoardMemberRole,
  Confirmation,
  toNewMessageNotificationsPath,
  UserEntity
} from '@atomica.co/yosemal-v2';
import { Typography } from '@material-ui/core';
import { CSSProperties } from '@material-ui/styles';
import React, { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { FramePos as FrameVo, FRAME_POSITION_FOR_PORTRAIT_POLAROID } from '../../constants/frame-constant';
import { database } from '../../firebase';
import newIcon from './../../assets/icon/icon_new.png';
import warnIcon from './../../assets/icon/icon_warn.png';
import BoardPhoto from './BoardPhoto';

const BOARD_NAME_FONT_SIZE = theme.mixins.typography.fontSize.sixteen;
const BOARD_NAME_FONT_WEIGHT = theme.mixins.typography.fontWeight.sevenHundreds;
const BOARD_NAME_FONT_FAMILY = theme.mixins.typography.fontFamily;

type Position = 'center' | 'flex-start';

export type Rotate = 'right' | 'left' | 'none';

interface P {
  rotate: Rotate;
  user?: UserEntity | undefined;
  board: BoardEntity | undefined;
  onClickBoard(boardId: BoardId): void;
}

const BoardListPolaroid: React.FC<P> = React.memo(props => {
  const { rotate, user, board, onClickBoard } = props;
  const ref = useRef<HTMLDivElement>();

  const photoWidth = useMemo<Width>(() => getMobileWidth() / 2 - theme.mixins.spacing * 4, []);
  const photoHeight = useMemo<Height>(() => photoWidth, [photoWidth]);
  const cardWidth = useMemo<Width>(() => photoWidth + theme.mixins.spacing * 2, [photoWidth]);
  const cardHeight = useMemo<Height>(() => photoHeight + theme.mixins.spacing * 7, [photoHeight]);
  const nameWidth = useMemo<Width>(() => {
    if (!board) return ZERO;
    const measurement = document.createElement('span');
    measurement.innerHTML = board.name;
    measurement.style.fontSize = BOARD_NAME_FONT_SIZE.toString();
    measurement.style.fontWeight = BOARD_NAME_FONT_WEIGHT.toString();
    measurement.style.fontFamily = BOARD_NAME_FONT_FAMILY;
    measurement.style.position = 'absolute';
    measurement.style.whiteSpace = 'nowrap';
    measurement.style.opacity = ZERO.toString();
    measurement.style.zIndex = MINUS_ONE.toString();
    document.body.appendChild(measurement);
    return measurement.clientWidth;
  }, [board]);

  const showCount = useMemo<boolean>(
    () => !!board && !!board.messageCount && isMoreThanZero(board.messageCount),
    [board]
  );
  const showNew = useMemo<boolean>(
    () => !!board && board.isDraft && !!board.dueDate && isFuture(board.dueDate),
    [board]
  );
  const showWarning = useMemo<boolean>(
    () =>
      !!board && board.role === BoardMemberRole.MEMBER && board.isDraft && !!board.dueDate && !isFuture(board.dueDate),
    [board]
  );
  const frame = useMemo<FrameVo | undefined>(
    () =>
      !!board && !!board.photoURL && !!board.frameId ? FRAME_POSITION_FOR_PORTRAIT_POLAROID[board.frameId] : undefined,
    [board]
  );

  const unmountRef = useUnmountRef();
  const [showIndicator, setShowIndicator] = useSafeState<boolean>(unmountRef, false);
  const [showWarningDescription, setShowWarningDescription] = useSafeState<boolean>(unmountRef, false);

  useEffect(() => {
    if (!user || !board || board.isDraft) return;
    const ref = database.ref(toNewMessageNotificationsPath(user.userId, board.boardId));
    ref.on('value', snapshot => {
      const confirmation: Confirmation | undefined = snapshot.val();
      setShowIndicator(!!confirmation && confirmation.isConfirmed === false);
    });
  }, [user, board, setShowIndicator]);

  return (
    <Component className='board-list-polaroid'>
      <Container width={cardWidth} height={cardHeight}>
        <CardWrapper width={cardWidth} height={cardHeight}>
          <Card
            rotate={rotate}
            width={cardWidth}
            height={cardHeight}
            onClick={() => !!board && onClickBoard(board.boardId)}
          >
            <BoardPhoto size='medium' width={photoWidth!} height={photoHeight!} board={board} />

            <TitleArea ref={ref}>
              <TextWrapperForName>
                <SingleLineText
                  style={getStyleForName(nameWidth < photoWidth ? 'center' : 'flex-start')}
                  text={!!board ? board.name : undefined}
                />
              </TextWrapperForName>
            </TitleArea>
          </Card>

          <TextWrapperForDueDate>
            <SingleLineText
              style={styleForOuterDate}
              text={
                !!board
                  ? `${toDateStr(board.dueDate, Language.JAPANESE)} ${getDayOfWeek(board.dueDate)} ${
                      isFuture(board.dueDate) ? 'まで' : EMPTY
                    }`
                  : undefined
              }
            />
          </TextWrapperForDueDate>
        </CardWrapper>

        {!!frame && <Stamp rotate={rotate} frame={frame} src={frame.src} />}

        {showCount && !!board && <Count showIndicator={showIndicator}>{board.messageCount}</Count>}

        {showNew && <New src={newIcon} />}

        {showWarning && <Warning src={warnIcon} onClick={() => setShowWarningDescription(show => !show)} />}

        {showWarningDescription && <WarningDescription position={rotate}>未記入のイベントです</WarningDescription>}
      </Container>
    </Component>
  );
});

export default BoardListPolaroid;

const Container = styled.div<{ width: Width; height: Height }>`
  width: ${props => props.width + theme.mixins.spacing * 2}px;
  height: ${props => props.height + theme.mixins.spacing * 5}px;
  position: relative;
  display: flex;
  align-items: flex-start;
`;

const CardWrapper = styled.div<{ width: Width; height: Height }>`
  width: ${props => props.width + theme.mixins.spacing * 2}px;
  height: ${props => props.height + theme.mixins.spacing * 2}px;
  position: absolute;
`;

const Card = styled.div<{
  rotate: Rotate;
  width: Width;
  height: Height;
}>`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  background: ${theme.mixins.background.white};
  border: ${theme.mixins.border.lightGray};
  display: flex;
  flex-flow: column;
  box-shadow: ${theme.mixins.shadow.light};
  transform: ${props => {
    switch (props.rotate) {
      case 'right':
        return 'rotate(1.25deg)';
      case 'left':
        return 'rotate(-0.79deg)';
      default:
        return 'unset';
    }
  }};
  padding: ${theme.mixins.spacing * 2}px ${theme.mixins.spacing}px ${theme.mixins.spacing / 2}px;
  margin: ${theme.mixins.spacing}px;
`;

const TitleArea = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  padding: ${theme.mixins.spacing / 2}px 0px;
`;

const TextWrapperForName = styled.div`
  width: 100%;
  height: 32px;
  padding: ${theme.mixins.spacing / 2}px 0px;
`;

const getStyleForName = (position: Position): CSSProperties => ({
  width: '100%',
  height: 'auto',
  fontSize: BOARD_NAME_FONT_SIZE,
  fontWeight: BOARD_NAME_FONT_WEIGHT,
  fontFamily: BOARD_NAME_FONT_FAMILY,
  display: 'flex',
  alignItems: 'center',
  justifyContent: position,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis'
});

const TextWrapperForDueDate = styled.div`
  width: 100%;
  height: 16px;
  text-align: center;
  padding: 0px ${theme.mixins.spacing * 2}px;
`;

const styleForOuterDate: CSSProperties = {
  width: '100%',
  height: 16,
  color: theme.mixins.typography.fontColor.black,
  fontSize: theme.mixins.typography.fontSize.twelve,
  fontWeight: theme.mixins.typography.fontWeight.sevenHundreds,
  fontFamily: theme.mixins.typography.fontFamily,
  userSelect: 'none'
};

const Stamp = styled.img<{ rotate: Rotate; frame: FrameVo }>`
  width: ${props => props.frame.width};
  position: absolute;
  object-fit: contain;
  z-index: 10;
  ${theme.mixins.userSelectDisabled};
  ${props => {
    switch (props.rotate) {
      case 'right':
        return `
          top: calc(${props.frame.top} - 1%);
          left: calc(${props.frame.left} + 4%);
          transform: rotate(1.25deg);
        `;
      case 'left':
        return `
          top: calc(${props.frame.top} + 0%);
          left: calc(${props.frame.left} + 2.5%);
          transform: rotate(-0.79deg);
        `;
      default:
        return `
          top: ${props.frame.top};
          left: ${props.frame.left};
        `;
    }
  }};
`;

const Count = styled.div<{ showIndicator: boolean }>`
  width: 24px;
  height: 24px;
  color: ${theme.mixins.typography.fontColor.black};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px !important;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds} !important;
  font-family: ${theme.mixins.typography.fontFamily} !important;
  background: ${theme.mixins.background.white};
  border: 2px solid ${theme.mixins.border.black};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 0px;
  left: 5px;
  z-index: 10;
  padding-bottom: ${theme.mixins.spacing / 4}px;
  ${theme.mixins.userSelectDisabled};

  &:after {
    display: ${props => (props.showIndicator ? 'block' : 'none')};
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: ${theme.mixins.background.pink};
    position: absolute;
    top: -7px;
    left: 15px;
    ${theme.mixins.userSelectDisabled};
  }
`;

const New = styled.img`
  width: 48px;
  position: absolute;
  top: 0px;
  right: 5px;
  object-fit: contain;
  z-index: 10;
  ${theme.mixins.userSelectDisabled};
`;

const Warning = styled.img`
  width: 28px;
  height: 28px;
  position: absolute;
  top: -5px;
  right: 0px;
  object-fit: contain;
  z-index: 10;
  ${theme.mixins.userSelectDisabled};
`;

const WarningDescription = styled(Typography)<{ position: Rotate }>`
  width: 144px;
  height: 28px;
  color: ${theme.mixins.typography.fontColor.pink};
  font-size: ${theme.mixins.typography.fontSize.twelve}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  background: ${theme.mixins.background.white};
  border-radius: 14px;
  border: 2px solid ${theme.mixins.border.pink};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  position: absolute;
  ${theme.mixins.userSelectDisabled};
  ${props =>
    props.position === 'left'
      ? `
      top: 20px;
      right: -60px;  
    `
      : `
      top: 20px;
      right: 0px;
    `}
`;
