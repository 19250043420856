import { Component, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { builder, EMPTY, Message, Text, URL } from '@atomica.co/utils';
import {
  BoardEntity,
  BoardId,
  BoardMessageEntity,
  BoardMessageStatus,
  FetchBoardMessageByUserIdRequest,
  FetchBoardRequest,
  FrameId,
  SaveBoardMessageRequest,
  UserEntity
} from '@atomica.co/yosemal-v2';
import React, { useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { HEADER_HEIGHT } from '../../../constants/common-constants';
import useCashedURL from '../../../redux/hooks/useCashedURL';
import usePath from '../../../redux/hooks/usePath';
import useUser from '../../../redux/hooks/useUser';
import BoardMessageRequest from '../../../requests/board-message-request';
import BoardRequest from '../../../requests/board-request';
import { Path } from '../../../router/Routes';
import { BoardMembersRef } from './parts/BoardMembers';
import BoardMessageEntry from './parts/BoardMessageEntry';
import BoardMessageMain from './parts/BoardMessageMain';
import BoardMessageWritten from './parts/BoardMessageWritten';
import InputBoardMessage from './parts/InputBoardMessage';
import SelectMessageFrame from './parts/SelectMessageFrame';

export interface BoardMessageRef {
  refresh(): void;
}

export enum BoardMessageIndex {
  SHOW_BOARD_PREVIEW = 'show_board_preview',
  BOARD_MESSAGE_MAIN = 'board_message_main',
  SELECT_MESSAGE_FRAME = 'select_message_frame',
  INPUT_BOARD_MESSAGE = 'input_board_message',
  BOARD_MESSAGE_WRITTEN = 'board_message_written'
}

interface P {
  boardId: BoardId | undefined;
  user: UserEntity | undefined;
}

const BoardMessage: React.ForwardRefExoticComponent<P & React.RefAttributes<BoardMessageRef>> = React.forwardRef<
  BoardMessageRef,
  P
>((props, ref) => {
  const { boardId, user } = props;
  const { saveCurrentURL } = useCashedURL();
  const { path, openPath } = usePath();
  const { getFirebase } = useUser();
  const isOpen = useMemo(() => path === Path.BOARD_MESSAGE, [path]);
  const boardMembersRef = useRef<BoardMembersRef>(null);
  const unmountRef = useUnmountRef();
  const [index, setIndex] = useSafeState<BoardMessageIndex>(unmountRef, BoardMessageIndex.SHOW_BOARD_PREVIEW);
  const [board, setBoard] = useSafeState<BoardEntity | undefined>(unmountRef);
  const [message, setMessage] = useSafeState<BoardMessageEntity | undefined>(unmountRef);

  /** The props of SaveBoardMessageRequest to save */
  const [photoURLToSave, setPhotoURLToSave] = useSafeState<URL>(unmountRef);
  const [frameIdToSave, setFrameIdToSave] = useSafeState<FrameId>(unmountRef);
  const [messageToSave, setMessageToSave] = useSafeState<Message>(unmountRef, EMPTY);

  const loadBoard = useSafeCallback(async (): Promise<void> => {
    if (!boardId) return;

    const boardRequest = builder<FetchBoardRequest>().boardId(boardId).build();
    const messageRequest = builder<FetchBoardMessageByUserIdRequest>()
      .boardId(boardId)
      .userId(!!user ? user.userId : EMPTY)
      .build();

    const [boardResponse, messageResponse] = await Promise.all([
      BoardRequest.fetchBoard(boardRequest),
      BoardMessageRequest.fetchMessageByUserId(messageRequest)
    ]);

    setBoard(boardResponse.board);
    setMessage(messageResponse.message);

    const photoURL = !!messageResponse.message ? messageResponse.message.photoURL : !!user ? user.photoURL : undefined;
    const frameId = !!messageResponse.message ? messageResponse.message.frameId : !!user ? user.frameId : undefined;
    const message = !!messageResponse.message ? messageResponse.message.text : EMPTY;

    !!photoURL && setPhotoURLToSave(photoURL);
    !!frameId && setFrameIdToSave(frameId);
    !!message && setMessageToSave(message);
  }, [boardId, user, setBoard, setMessage, setPhotoURLToSave, setFrameIdToSave, setMessageToSave]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    if (!isOpen) return;
    loadBoard();
  }, [isOpen, loadBoard]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const saveMessage = useSafeCallback(async (): Promise<void> => {
    if (!board || !user) return;

    const request = builder<SaveBoardMessageRequest>()
      .boardMessageId(!!message ? message.boardMessageId : EMPTY)
      .boardId(board.boardId)
      .status(BoardMessageStatus.SUBMITTED)
      .frameId(frameIdToSave)
      .photoURL(photoURLToSave)
      .text(messageToSave)
      .userId(user.userId)
      .build();

    await BoardMessageRequest.saveNewMessage(request);
    setIndex(BoardMessageIndex.BOARD_MESSAGE_WRITTEN);
  }, [board, user, message, frameIdToSave, photoURLToSave, messageToSave, setIndex]);

  const refresh = useSafeCallback(async (): Promise<void> => {
    if (!isOpen) return;
    await loadBoard();
    !!boardMembersRef.current && boardMembersRef.current.refresh();
  }, [isOpen, loadBoard]);

  useImperativeHandle(ref, () => ({
    refresh: () => refresh()
  }));

  const handleWriteButtonClicked = useSafeCallback(
    async (index: BoardMessageIndex): Promise<void> => {
      const firebase = await getFirebase();

      if (!firebase) {
        saveCurrentURL();
        openPath(Path.SIGN_UP);
        return;
      }

      setIndex(index);
    },
    [getFirebase, saveCurrentURL, openPath, setIndex]
  );

  return (
    <Component className='board-message'>
      <Container>
        {index === BoardMessageIndex.SHOW_BOARD_PREVIEW && (
          <BoardMessageEntry ref={boardMembersRef} board={board} onClickWriteButton={handleWriteButtonClicked} />
        )}

        {index === BoardMessageIndex.BOARD_MESSAGE_MAIN && (
          <BoardMessageMain
            photoURL={photoURLToSave}
            frameId={frameIdToSave}
            message={messageToSave}
            board={board}
            user={user}
            onClick={setIndex}
            onUpload={setPhotoURLToSave}
            onClickSaveButton={saveMessage}
          />
        )}

        {index === BoardMessageIndex.SELECT_MESSAGE_FRAME && (
          <SelectMessageFrame
            photoURL={photoURLToSave}
            frameId={frameIdToSave}
            onClickSaveButton={(frameId: FrameId) => {
              setFrameIdToSave(frameId);
              setIndex(BoardMessageIndex.BOARD_MESSAGE_MAIN);
            }}
            goTo={setIndex}
          />
        )}

        {index === BoardMessageIndex.INPUT_BOARD_MESSAGE && (
          <InputBoardMessage
            label='あなたのコメント'
            message={messageToSave}
            onClickSaveButton={(text: Text) => {
              setMessageToSave(text);
              setIndex(BoardMessageIndex.BOARD_MESSAGE_MAIN);
            }}
            goTo={setIndex}
          />
        )}

        {index === BoardMessageIndex.BOARD_MESSAGE_WRITTEN && (
          <BoardMessageWritten
            boardId={boardId}
            newlyWrittenMessage={builder<BoardMessageEntity>()
              .frameId(frameIdToSave!)
              .photoURL(photoURLToSave!)
              .text(messageToSave)
              .userId(!!user ? user.userId : undefined!)
              .familyName(!!user ? user.familyName : EMPTY)
              .firstName(!!user ? user.firstName : EMPTY)
              .dateOfBirth(!!user ? user.dateOfBirth : undefined!)
              .build()}
          />
        )}
      </Container>
    </Component>
  );
});

export default BoardMessage;

const Container = styled.div`
  width: 100%;
  height: calc(100vh - ${HEADER_HEIGHT}px);
  display: flex;
  margin-top: ${HEADER_HEIGHT}px;
`;
