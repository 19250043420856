import { getFontSize, theme, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { embedIdInPath, getDayOfWeek, Language, Size, toDataURL, toDateStr, URL } from '@atomica.co/utils';
import { BoardPdfEntity, DOMAINS } from '@atomica.co/yosemal-v2';
import { Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import React, { useEffect, useMemo } from 'react';
import { FramePos, FRAME_POSITION_FOR_PORTRAIT_POLAROID } from '../../../constants/frame-constant';
import { FONT_NOTO_SANS_CJK_JP, FONT_NOTO_SANS_CJK_JP_BOLD } from '../../../constants/pdf-constants';
import { PAGE_ORIENTATION, PAGE_SIZE, Styles } from '../BoardPagesPdf';
import UserPolaroids from '../users/UserPolaroids';
import yosemal from './../../../assets/yosemal/yosemal_logo.png';
import env from './../../../env/env';
import { Path, PATH_IDS } from './../../../router/Routes';

const getStyles = (fontSizeForBoardName: Size): Styles =>
  StyleSheet.create({
    page: { width: '100vw', height: '100vh', backgroundColor: theme.mixins.background.yellow },
    container: {
      width: '100vw',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    frame: {
      width: 500,
      height: 600,
      backgroundColor: theme.mixins.background.white,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      border: `1px solid ${theme.mixins.border.lightGray}`,
      position: 'relative',
      marginTop: theme.mixins.spacing * 2
    },
    boardPhoto: {
      width: 450,
      height: 450,
      objectFit: 'cover',
      border: `1px solid ${theme.mixins.border.lightGray}`,
      marginTop: theme.mixins.spacing * 4
    },
    userPhotos: {
      width: 450,
      height: 450,
      border: `1px solid ${theme.mixins.border.lightGray}`,
      backgroundColor: theme.mixins.background.lightGray
    },
    boardName: {
      color: theme.mixins.typography.fontColor.black,
      fontSize: fontSizeForBoardName,
      fontFamily: FONT_NOTO_SANS_CJK_JP_BOLD,
      fontWeight: theme.mixins.typography.fontWeight.nineHundreds,
      paddingTop: theme.mixins.spacing * 2,
      marginTop: theme.mixins.spacing
    },
    dueDate: {
      color: theme.mixins.typography.fontColor.gray,
      fontSize: fontSizeForBoardName < 16 ? fontSizeForBoardName : 16,
      fontFamily: FONT_NOTO_SANS_CJK_JP,
      fontWeight: theme.mixins.typography.fontWeight.nineHundreds,
      marginTop: theme.mixins.spacing
    },
    footerArea: {
      width: 500,
      height: 160,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-around',
      marginTop: theme.mixins.spacing * 2
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    qr: {
      width: 112,
      height: 112,
      backgroundColor: theme.mixins.background.white,
      borderRadius: 16,
      padding: theme.mixins.spacing / 2,
      margin: theme.mixins.spacing,
      objectFit: 'contain'
    },
    message: {
      color: theme.mixins.typography.fontColor.black,
      fontSize: theme.mixins.typography.fontSize.eight,
      fontFamily: FONT_NOTO_SANS_CJK_JP_BOLD,
      fontWeight: theme.mixins.typography.fontWeight.nineHundreds,
      paddingBottom: theme.mixins.spacing / 2
    },
    logo: {
      width: 80,
      height: 112,
      objectFit: 'contain'
    },
    url: {
      color: theme.mixins.typography.fontColor.gray,
      fontSize: theme.mixins.typography.fontSize.eight,
      fontFamily: FONT_NOTO_SANS_CJK_JP,
      fontWeight: theme.mixins.typography.fontWeight.nineHundreds
    }
  });

interface P {
  board: BoardPdfEntity;
}

const FrontCoverPage: React.FC<P> = React.memo(props => {
  const { board } = props;
  const unmountRef = useUnmountRef();
  const [frame, setFrame] = useSafeState<FramePos | undefined>(unmountRef);
  const [photoURL, setPhotoURL] = useSafeState<URL | undefined>(unmountRef);
  const [fontSize, setFontSize] = useSafeState<Size>(unmountRef);
  const [dataURL, setDataURL] = useSafeState<URL | undefined>(unmountRef);

  const styles = useMemo<Styles>(() => {
    return getStyles(fontSize);
  }, [fontSize]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const frame = FRAME_POSITION_FOR_PORTRAIT_POLAROID[board.frameId!];
    setFrame(!!frame && !!frame.src ? frame : undefined);
    setPhotoURL(board.photoURL);

    const fontSize = getFontSize(board.name, FONT_NOTO_SANS_CJK_JP, 450);
    setFontSize(fontSize < 36 ? fontSize : 36);

    const url = await toDataURL(
      `https://${DOMAINS[env]}${embedIdInPath(Path.BOARD_DETAILS, PATH_IDS, [board.boardId])}`
    );
    setDataURL(url);
  }, [board, setFrame, setPhotoURL, setFontSize, setDataURL]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <Page size={PAGE_SIZE} orientation={PAGE_ORIENTATION} style={styles.page}>
      <View style={styles.container}>
        <View style={styles.frame}>
          {!!photoURL && <Image style={styles.boardPhoto} src={board.photoURL} />}
          {!photoURL && (
            <View style={styles.userPhotos}>
              <UserPolaroids users={board.membersForThumbnail} />
            </View>
          )}
          {!!frame && <Image style={getStyleForStamp(frame)} src={frame.src} />}
          <Text style={styles.boardName}>{board.name}</Text>
          <Text style={styles.dueDate}>{`${toDateStr(board.dueDate, Language.JAPANESE)} ${getDayOfWeek(
            board.dueDate
          )}`}</Text>
        </View>

        <View style={styles.footerArea}>
          <View style={styles.wrapper}>
            {!!dataURL && <Image style={styles.qr} src={dataURL} />}
            <Text style={styles.message}>ここからイベントメンバーに連絡できるよ</Text>
          </View>

          <View style={styles.wrapper}>
            <Image style={styles.logo} src={yosemal} />
            <Text style={styles.message}>寄せ書いてつながろう</Text>
            <Text style={styles.url}>https://yosemal.com</Text>
          </View>
        </View>
      </View>
    </Page>
  );
});

export default FrontCoverPage;

const getStyleForStamp = (frame: FramePos): Style => {
  return {
    position: 'absolute',
    top: frame.top,
    left: frame.left,
    width: frame.width
  };
};
