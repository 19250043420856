import {
  Button,
  Component,
  InputBox,
  styleForFullExpansion,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { builder, EMPTY, Name } from '@atomica.co/utils';
import { SaveUserMajorRequest, UserId } from '@atomica.co/yosemal-v2';
import { default as React } from 'react';
import styled from 'styled-components';
import { HEADER_HEIGHT } from '../../../../../constants/common-constants';
import UserRequest from '../../../../../requests/user-request';
import InputProfileHeader from '../../../account-header/InputProfileHeader';
import { EditProfileIndex } from '../EditProfile';

interface P {
  userId: UserId | undefined;
  major: Name | undefined;
  onSave(major: Name): void;
  goTo(index: EditProfileIndex): void;
}

const SelectMajor: React.FC<P> = React.memo(props => {
  const { userId, major: initMajor = EMPTY, onSave, goTo } = props;

  const unmountRef = useUnmountRef();
  const [disabledSaveButton, setDisabledSaveButton] = useSafeState<boolean>(unmountRef, false);
  const [majorToSave, setMajorToSave] = useSafeState<Name>(unmountRef, initMajor);

  const saveMajor = useSafeCallback(async (): Promise<void> => {
    if (!userId) return;
    setDisabledSaveButton(true);

    const request = builder<SaveUserMajorRequest>().userId(userId).major(majorToSave).build();
    await UserRequest.saveMajor(request);

    onSave(majorToSave);
  }, [setDisabledSaveButton, userId, majorToSave, onSave]);

  return (
    <Component style={styleForFullExpansion} className='select-major'>
      <HeaderArea>
        <InputProfileHeader label='専攻 / コース' onClick={goTo} />
      </HeaderArea>

      <Body>
        <InputWrapper>
          <InputBox showCount shape='rect' text={majorToSave} onChange={setMajorToSave} />
        </InputWrapper>

        <ButtonArea>
          <Button type='primary' disabled={disabledSaveButton} onClick={saveMajor}>
            <StyledLabel>保存</StyledLabel>
          </Button>
        </ButtonArea>
      </Body>
    </Component>
  );
});

export default SelectMajor;

const HeaderArea = styled.div`
  width: 100%;
  height: ${HEADER_HEIGHT}px;
`;

const Body = styled.div`
  width: 100%;
  height: calc(100% - 48px);
`;

const InputWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing * 4}px ${theme.mixins.spacing * 2}px;
`;

const ButtonArea = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
`;

const StyledLabel = styled.div`
  width: 80px;
  height: auto;
`;
