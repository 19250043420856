import {
  CircularLoader,
  Component,
  Scrollable,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { builder, Count, ZERO } from '@atomica.co/utils';
import { FetchConnectingsRequest, UserEntity, UserId } from '@atomica.co/yosemal-v2';
import React, { useEffect, useImperativeHandle, useRef } from 'react';
import styled from 'styled-components';
import { HEADER_HEIGHT } from '../../../constants/common-constants';
import ConnectionRequest from '../../../requests/connection-request';
import ConnectionList from '../connection-list/ConnectionList';
import ConnectionPolaroids from '../connection-polaroids/ConnectionPolaroids';
import { ConnectionAction, ConnectionIndex } from '../ConnectionsScreen';

const LIMIT = 10;

const OPTIONS: IntersectionObserverInit = {
  root: null,
  rootMargin: '0px 0px 300px 0px'
};

export interface ConnectionsRef {
  refresh(): void;
}

interface P {
  selectable: boolean;
  index: ConnectionIndex;
  selectedConnections: UserEntity[];
  userId: UserId | undefined;
  onClickConnection(userId: UserId): void;
  onSelectConnection(user: UserEntity): void;
  emitAction(action: ConnectionAction): void;
}

const ConnectionSwitcher: React.ForwardRefExoticComponent<P & React.RefAttributes<ConnectionsRef>> = React.forwardRef<
  ConnectionsRef,
  P
>((props, ref) => {
  const { selectable, index, selectedConnections, userId, onClickConnection, onSelectConnection, emitAction } = props;
  const bottomRef = useRef<HTMLDivElement>();
  const hasMore = useRef<boolean>(true);
  const count = useRef<Count>(ZERO);
  const unmountRef = useUnmountRef();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [connections, setConnections] = useSafeState<UserEntity[]>(unmountRef, []);

  const loadConnections = useSafeCallback(async (): Promise<void> => {
    if (!hasMore || !userId) return;

    const request = builder<FetchConnectingsRequest>().userId(userId).limit(LIMIT).offset(count.current).build();

    const response = await ConnectionRequest.fetchConnectings(request);
    const connectionsToAdd = response.connections;

    hasMore.current = connectionsToAdd.length === LIMIT;
    count.current += connectionsToAdd.length;
    setConnections(connections =>
      count.current === connectionsToAdd.length ? response.connections : [...connections, ...response.connections]
    );
    setLoaded(true);
  }, [userId, setLoaded, setConnections]);

  useEffect(() => {
    loadConnections();
  }, [loadConnections]);

  const onScroll = useSafeCallback(
    (entries: IntersectionObserverEntry[]): void => {
      for (const entry of entries) {
        if (!entry.isIntersecting) return;
        loadConnections();
      }
    },
    [loadConnections]
  );

  useEffect(() => {
    if (!loaded) return;

    const observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => onScroll(entries), OPTIONS);

    bottomRef.current && observer.observe(bottomRef.current);
    return () => observer.disconnect();
  }, [loaded, onScroll]);

  const refresh = useSafeCallback(async (): Promise<void> => {
    hasMore.current = true;
    count.current = ZERO;
    await loadConnections();
  }, [loadConnections]);

  useImperativeHandle(ref, () => ({
    refresh: async () => await refresh()
  }));

  return (
    <Component
      loading={!loaded}
      style={{ width: '100%', height: window.innerHeight - HEADER_HEIGHT }}
      className='connection-switcher'
    >
      <Scrollable>
        <Container>
          {index === ConnectionIndex.CONNECTION_LIST && (
            <ConnectionList
              selectable={selectable}
              selectedConnections={selectedConnections}
              connections={connections}
              onClickConnection={onClickConnection}
              onSelectConnection={onSelectConnection}
              emitAction={emitAction}
            />
          )}

          {index === ConnectionIndex.CONNECTION_CARDS && (
            <ConnectionPolaroids
              selectable={selectable}
              selectedConnections={selectedConnections}
              connections={connections}
              onClickConnection={onClickConnection}
              onSelectConnection={onSelectConnection}
              emitAction={emitAction}
            />
          )}

          {hasMore.current && (
            <LoaderWrapper>
              <CircularLoader />
            </LoaderWrapper>
          )}

          <Bottom ref={bottomRef} />
        </Container>
      </Scrollable>
    </Component>
  );
});

export default ConnectionSwitcher;

const Container = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px 0px;
`;

const LoaderWrapper = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Bottom = styled.div`
  width: 100%;
  height: 240px;
`;
