import { Component, getMobileWidth, theme } from '@atomica.co/components';
import { builder, Index } from '@atomica.co/utils';
import { BoardMemberEntity, UserEntity } from '@atomica.co/yosemal-v2';
import React from 'react';
import styled from 'styled-components';
import EmailCard from '../../../../../components/card/EmailCard';
import UserCard from '../../../../../components/card/UserCard';

interface P {
  selectedMembers: BoardMemberEntity[];
}

const ShowSelectedMembersToInvite: React.FC<P> = React.memo(props => {
  const { selectedMembers } = props;

  return (
    <Component className='show-selected-members-to-invite'>
      <Frame>
        <TitleWrapper>
          <Title>参加者リスト</Title>

          <Count>{selectedMembers.length}</Count>
        </TitleWrapper>

        {selectedMembers.map((member: BoardMemberEntity, index: Index) => (
          <CardWrapper key={index}>
            {!!member.userId && (
              <UserCard
                user={builder<UserEntity>()
                  .userId(member.userId)
                  .familyName(member.familyName!)
                  .firstName(member.firstName!)
                  .selfIntroduction(member.selfIntroduction!)
                  .photoURL(member.photoURL!)
                  .build()}
              />
            )}

            {!member.userId && <EmailCard email={member.email!} />}
          </CardWrapper>
        ))}
      </Frame>
    </Component>
  );
});

export default ShowSelectedMembersToInvite;

const Frame = styled.div`
  width: ${getMobileWidth()}px;
  height: auto;
  background: ${theme.mixins.background.white};
  border-radius: 24px 24px 0px 0px;
  padding: ${theme.mixins.spacing * 2}px ${theme.mixins.spacing * 2}px ${theme.mixins.spacing * 20}px;
`;

const TitleWrapper = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
`;

const Title = styled.div`
  color: ${theme.mixins.typography.fontColor.black};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px !important;
  font-weight: ${theme.mixins.typography.fontWeight.nineHundreds} !important;
  font-family: ${theme.mixins.typography.fontFamily};
  text-decoration: underline;
  text-align: center;
`;

const Count = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.mixins.typography.fontColor.black};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px !important;
  font-weight: ${theme.mixins.typography.fontWeight.nineHundreds} !important;
  font-family: ${theme.mixins.typography.fontFamily};
  border: solid 2px ${theme.mixins.border.black};
  border-radius: 4px;
  padding-bottom: ${theme.mixins.spacing / 4}px;
  margin-left: ${theme.mixins.spacing}px;
`;

const CardWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing / 4}px;
`;
