import { Button, Component, Icon, theme, useSafeCallback } from '@atomica.co/components';
import { EMAIL_CONST } from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import firebase from 'firebase';
import React from 'react';
import styled from 'styled-components';
import { Process } from '../../../../../enums/common-enum';
import { auth } from '../../../../../firebase';
import useProcess from '../../../../../redux/hooks/useProcess';
import google from './../../../../../assets/icon/icon_google.png';

interface P {}

const GoogleSignUp: React.FC<P> = React.memo(() => {
  const { setProcess } = useProcess();

  const signInWithRedirect = useSafeCallback((): void => {
    setProcess(Process.PROCESSING);
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope(EMAIL_CONST);
    auth.signInWithRedirect(provider);
  }, [setProcess]);

  return (
    <Component className='google-sign-up'>
      <ButtonWrapper>
        <Button type='default' onClick={signInWithRedirect}>
          <IconWrapper>
            <Icon size='xsmall' src={google} />
          </IconWrapper>

          <Label>Googleで登録</Label>
        </Button>
      </ButtonWrapper>
    </Component>
  );
});

export default GoogleSignUp;

const ButtonWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: ${theme.mixins.spacing / 2}px;
`;

const IconWrapper = styled.div`
  width: 48px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Label = styled(Typography)`
  width: 200px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
