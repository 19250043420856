import { Component, Icon, theme } from '@atomica.co/components';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import React from 'react';
import styled from 'styled-components';
import Header from '../../../components/header/Header';
import { ConnectionIndex } from '../ConnectionsScreen';
import cards from './../../../assets/icon/icon_cards.png';
import list from './../../../assets/icon/icon_list.png';

interface P {
  index: ConnectionIndex;
  onChange(index: ConnectionIndex): void;
}

const ConnectionHeader: React.FC<P> = React.memo(props => {
  const { index, onChange } = props;

  return (
    <Component className='connection-header'>
      <Header
        label='知り合い'
        leftChild={<LeftArea />}
        rightChild={
          <RightArea>
            <ButtonWrapper show={index === ConnectionIndex.CONNECTION_CARDS}>
              <Button onClick={() => onChange(ConnectionIndex.CONNECTION_CARDS)}>
                <Icon size='xsmall' style={styleForIcon} src={cards} />
              </Button>
            </ButtonWrapper>

            <ButtonWrapper show={index === ConnectionIndex.CONNECTION_LIST}>
              <Button onClick={() => onChange(ConnectionIndex.CONNECTION_LIST)}>
                <Icon size='xsmall' style={styleForIcon} src={list} />
              </Button>
            </ButtonWrapper>
          </RightArea>
        }
      />
    </Component>
  );
});

export default ConnectionHeader;

const LeftArea = styled.div`
  width: 88px;
  height: auto;
  padding-left: ${theme.mixins.spacing}px;
`;

const RightArea = styled.div`
  width: 88px;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-right: ${theme.mixins.spacing}px;
`;

const ButtonWrapper = styled.div<{ show: boolean }>`
  width: 40px;
  height: auto;

  &:after {
    width: 40px;
    height: 6px;
    content: '';
    display: ${props => (props.show ? 'block' : 'none')};
    background-color: ${theme.mixins.border.pink};
    border-radius: 3px;
  }
`;

const Button = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding-top: ${theme.mixins.spacing}px;
`;

const styleForIcon: CSSProperties = {
  color: theme.mixins.typography.fontColor.lightGray
};
