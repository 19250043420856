import { theme } from '@atomica.co/components';
import { Count } from '@atomica.co/utils';
import { BoardMessagePdfEntity } from '@atomica.co/yosemal-v2';
import { Page as PdfPage, StyleSheet } from '@react-pdf/renderer';
import React, { useMemo } from 'react';
import { PAGE_ORIENTATION, PAGE_SIZE, Styles } from '../BoardPagesPdf';
import BoardMessageOne from '../messages/BoardMessageOne';
import BoardMessagesEight from '../messages/BoardMessagesEight';
import BoardMessagesFive from '../messages/BoardMessagesFive';
import BoardMessagesFour from '../messages/BoardMessagesFour';
import BoardMessagesSeven from '../messages/BoardMessagesSeven';
import BoardMessagesSix from '../messages/BoardMessagesSix';
import BoardMessagesThree from '../messages/BoardMessagesThree';
import BoardMessagesTwo from '../messages/BoardMessagesTwo';

export type PageSide = 'right' | 'left';

const styles: Styles = StyleSheet.create({
  page: { width: '100vw', height: '100vh', backgroundColor: theme.mixins.background.yellow }
});

interface P {
  side: PageSide;
  messages: BoardMessagePdfEntity[];
}

const BoardMessagesPage: React.FC<P> = React.memo(props => {
  const { side, messages } = props;
  const messageCount = useMemo<Count>(() => messages.length, [messages]);

  return (
    <PdfPage size={PAGE_SIZE} orientation={PAGE_ORIENTATION} style={styles.page}>
      {messageCount === 1 && <BoardMessageOne messages={messages} />}
      {messageCount === 2 && <BoardMessagesTwo messages={messages} />}
      {messageCount === 3 && <BoardMessagesThree side={side} messages={messages} />}
      {messageCount === 4 && <BoardMessagesFour side={side} messages={messages} />}
      {messageCount === 5 && <BoardMessagesFive side={side} messages={messages} />}
      {messageCount === 6 && <BoardMessagesSix messages={messages} />}
      {messageCount === 7 && <BoardMessagesSeven side={side} messages={messages} />}
      {messageCount === 8 && <BoardMessagesEight messages={messages} />}
    </PdfPage>
  );
});

export default BoardMessagesPage;
