import {
  Button,
  Component,
  InputBox,
  styleForFullExpansion,
  theme,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { EMPTY, Label, Name, Text } from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import { default as React } from 'react';
import styled from 'styled-components';
import { CreateBoardIndex } from '../CreateBoard';

interface P {
  multiline?: boolean;
  maxLength?: number;
  label: Label;
  text: Text | undefined;
  onClickSaveButton(text: Text): void;
  goTo(index: CreateBoardIndex): void;
}

const InputBoardInfo: React.FC<P> = React.memo(props => {
  const { multiline, maxLength, label, text: initText = EMPTY, onClickSaveButton, goTo } = props;

  const unmountRef = useUnmountRef();
  const [text, setText] = useSafeState<Name>(unmountRef, initText);

  return (
    <Component style={styleForFullExpansion} className='input-board-info'>
      <Header>
        <HeaderButton>
          <Button onClick={() => goTo(CreateBoardIndex.CREATE_BOARD_MAIN)}>
            <BackIcon />
          </Button>
        </HeaderButton>

        <HeaderLabel>{label}</HeaderLabel>

        <HeaderButton />
      </Header>

      <Body>
        <InputWrapper>
          <InputBox showCount multiline={multiline} maxLength={maxLength} shape='rect' text={text} onChange={setText} />
        </InputWrapper>

        <ButtonArea>
          <Button type='primary' onClick={() => onClickSaveButton(text)}>
            <StyledLabel>保存</StyledLabel>
          </Button>
        </ButtonArea>
      </Body>
    </Component>
  );
});

export default InputBoardInfo;

const Header = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.mixins.spacing}px;
`;

const HeaderButton = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

const BackIcon = styled(ArrowBackIosOutlinedIcon)`
  color: ${theme.mixins.typography.fontColor.lightGray};
`;

const HeaderLabel = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.lightGray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds} !important;
  font-family: ${theme.mixins.typography.fontFamily};
`;

const Body = styled.div`
  width: 100%;
  height: calc(100% - 48px);
  background: ${theme.mixins.background.yellow};
`;

const InputWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing * 4}px ${theme.mixins.spacing * 2}px;
`;

const ButtonArea = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
`;

const StyledLabel = styled.div`
  width: 80px;
  height: auto;
`;
