import {
  AutoSizedText,
  Component,
  getMobileWidth,
  Icon,
  SingleLineText,
  Skeleton,
  theme,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { EMPTY, getTimeDiffLabel, Height, isMoreThanZero, noop, Width } from '@atomica.co/utils';
import {
  BoardMessageEntity,
  BoardMessageId,
  Confirmation,
  toNewThreadNotificationsPath,
  UserEntity,
  UserId
} from '@atomica.co/yosemal-v2';
import { CSSProperties } from '@material-ui/styles';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { FramePos as FrameVo, FRAME_POSITION_FOR_LANDSCAPE_POLAROID } from '../../constants/frame-constant';
import { database } from '../../firebase';
import { toFullName } from '../../utils/user-util';
import reply from './../../assets/icon/icon_reply.png';

export type Size = 'large' | 'medium' | 'small';

export type Rotate = 'none' | 'right' | 'left';

export enum ClickArea {
  MESSAGE = 'message',
  USER = 'user',
  USER_PHOTO = 'user_photo',
  THREAD_ICON = 'thread_icon',
  REPLY_ICON = 'reply_icon'
}

interface P {
  showThread?: boolean;
  size?: Size;
  rotate?: Rotate;
  user?: UserEntity | undefined;
  message: BoardMessageEntity | undefined;
  onClick?(target: ClickArea, id: UserId | BoardMessageId): void;
}

const BoardMessagePolaroid: React.FC<P> = React.memo(props => {
  const { showThread = false, size = 'large', rotate = 'none', user, message, onClick = noop } = props;

  const cardWidth = useMemo<Width>(() => {
    switch (size) {
      case 'large':
        return getMobileWidth() - theme.mixins.spacing * 4;
      case 'medium':
      case 'small':
        return (getMobileWidth() * 2) / 3;
    }
  }, [size]);

  const cardHeight = useMemo<Height>(() => {
    return (cardWidth * 2) / 3 + theme.mixins.spacing;
  }, [cardWidth]);

  const frame = useMemo<FrameVo | undefined>(() => {
    return !!message && !!message.photoURL && !!message.frameId
      ? FRAME_POSITION_FOR_LANDSCAPE_POLAROID[message.frameId]
      : undefined;
  }, [message]);

  const unmountRef = useUnmountRef();
  const [showIndicator, setShowIndicator] = useSafeState<boolean>(unmountRef, false);

  useEffect(() => {
    if (!user || !message) return;
    const ref = database.ref(toNewThreadNotificationsPath(user.userId, message.boardMessageId));
    ref.on('value', snapshot => {
      const confirmation: Confirmation | undefined = snapshot.val();
      setShowIndicator(!!confirmation && confirmation.isConfirmed === false);
    });
  }, [user, message, setShowIndicator]);

  return (
    <Component style={{ display: 'flex', justifyContent: 'center' }} className='board-message-polaroid'>
      <Container showThread={showThread} width={cardWidth} height={cardHeight}>
        <CardWrapper rotate={rotate} width={cardWidth} height={cardHeight}>
          <Card width={cardWidth} height={cardHeight}>
            <LeftArea onClick={() => !!message && onClick(ClickArea.USER, message.userId)}>
              <PhotoWrapper
                onClick={e => {
                  !!message && onClick(ClickArea.USER_PHOTO, message.boardMessageId);
                  e.stopPropagation();
                }}
              >
                <Skeleton
                  animation='wave'
                  variant='rect'
                  style={getStyleForPhoto(cardWidth!)}
                  src={!!message ? message.photoURL! : EMPTY}
                />
              </PhotoWrapper>

              <NameWrapper size={size}>
                <SingleLineText style={styleForName(size)} text={!!message ? toFullName(message) : undefined} />
              </NameWrapper>

              <DateWrapper size={size}>
                <SingleLineText
                  style={styleForDate(size)}
                  text={!!message ? getTimeDiffLabel(message.createdAt) : undefined}
                />
              </DateWrapper>
            </LeftArea>

            <RightArea onClick={() => !!message && onClick(ClickArea.MESSAGE, message.boardMessageId)}>
              <MessageWrapper>
                <AutoSizedText style={styleForMessage} text={!!message ? message.text : EMPTY} />
              </MessageWrapper>
            </RightArea>
          </Card>

          {showThread && !!message && (
            <ButtonsWrapper>
              {isMoreThanZero(message.threadCount) && (
                <CountButton
                  showIndicator={showIndicator}
                  onClick={() => onClick(ClickArea.THREAD_ICON, message.boardMessageId)}
                >
                  {message.threadCount}
                </CountButton>
              )}

              <ReplyButton onClick={() => onClick(ClickArea.REPLY_ICON, message.boardMessageId)}>
                <Icon style={{ width: 24, height: 24 }} src={reply} />
              </ReplyButton>
            </ButtonsWrapper>
          )}
        </CardWrapper>

        {!!frame && (
          <Stamp
            rotate={rotate}
            frame={frame}
            src={frame.src}
            onClick={() => !!message && onClick(ClickArea.USER_PHOTO, message.boardMessageId)}
          />
        )}
      </Container>
    </Component>
  );
});

export default BoardMessagePolaroid;

const Container = styled.div<{
  showThread: boolean;
  width: Width;
  height: Height;
}>`
  width: ${props => props.width + theme.mixins.spacing * 2}px;
  height: ${props =>
    props.showThread ? props.height + theme.mixins.spacing * 5 : props.height + theme.mixins.spacing * 2}px;
  position: relative;
  display: flex;
  justify-content: center;
`;

const CardWrapper = styled.div<{
  rotate: Rotate;
  width: Width;
  height: Height;
}>`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  transform: ${props => {
    switch (props.rotate) {
      case 'right':
        return 'rotate(1.25deg)';
      case 'left':
        return 'rotate(-0.79deg)';
      default:
        return 'unset';
    }
  }};
`;

const Card = styled.div<{
  width: Width;
  height: Height;
}>`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  border: ${theme.mixins.border.lightGray};
  background: ${theme.mixins.background.white};
  box-shadow: ${theme.mixins.shadow.light};
  display: flex;
`;

const LeftArea = styled.div`
  width: 50%;
  height: 100%;
`;

const PhotoWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing / 2}px ${theme.mixins.spacing / 2}px
    ${theme.mixins.spacing}px;
`;

const getStyleForPhoto = (width: Width): CSSProperties => {
  return {
    width: width / 2 - theme.mixins.spacing,
    height: width / 2 - theme.mixins.spacing,
    background: theme.mixins.background.lightGray,
    border: theme.mixins.border.lightGray,
    boxShadow: `inset ${theme.mixins.shadow.light}`,
    objectFit: 'contain'
  };
};

const NameWrapper = styled.div<{ size: Size }>`
  width: 100%;
  height: ${props => {
    switch (props.size) {
      case 'large':
        return 28;
      case 'medium':
      case 'small':
        return 20;
    }
  }}px;
  padding: ${theme.mixins.spacing / 4}px ${theme.mixins.spacing / 2}px ${theme.mixins.spacing / 4}px;
  ${(theme.mixins.spacing * 3) / 2}px;
  ${theme.mixins.userSelectDisabled};
`;

const styleForName = (size: Size): CSSProperties => {
  return {
    width: '100%',
    height: 'auto',
    color: theme.mixins.typography.fontColor.black,
    fontSize: size === 'large' ? theme.mixins.typography.fontSize.sixteen : theme.mixins.typography.fontSize.twelve,
    fontWeight: theme.mixins.typography.fontWeight.sevenHundreds,
    fontFamily: theme.mixins.typography.fontFamily,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  };
};

const DateWrapper = styled.div<{ size: Size }>`
  width: 100%;
  height: ${props => {
    switch (props.size) {
      case 'large':
        return 24;
      case 'medium':
      case 'small':
        return 16;
    }
  }}px;
  padding: ${theme.mixins.spacing / 4}px ${theme.mixins.spacing / 2}px ${theme.mixins.spacing / 4}px;
  ${(theme.mixins.spacing * 3) / 2}px;
  ${theme.mixins.userSelectDisabled};
`;

const styleForDate = (size: Size): CSSProperties => {
  return {
    width: '100%',
    height: 'auto',
    color: theme.mixins.typography.fontColor.lightGray,
    fontSize: size === 'large' ? theme.mixins.typography.fontSize.twelve : theme.mixins.typography.fontSize.eight,
    fontWeight: theme.mixins.typography.fontWeight.fourHundreds,
    fontFamily: theme.mixins.typography.fontFamily,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  };
};

const RightArea = styled.div`
  width: 50%;
  height: 100%;
`;

const MessageWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing}px ${theme.mixins.spacing}px ${theme.mixins.spacing / 2}px;
`;

const styleForMessage: CSSProperties = {
  width: '100%',
  height: '100%',
  color: theme.mixins.typography.fontColor.black,
  fontWeight: theme.mixins.typography.fontWeight.sevenHundreds,
  fontFamily: theme.mixins.typography.fontFamily,
  wordBreak: 'break-all',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
};

const ButtonsWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-end;
  ${theme.mixins.userSelectDisabled};
`;

const ReplyButton = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: ${theme.mixins.background.pink};
  box-shadow: ${theme.mixins.shadow.dark};
  margin: -${theme.mixins.spacing / 4}px ${theme.mixins.spacing / 2}px 0px;
  position: relative;

  &:after {
    content: '';
    width: 32px;
    height: 4px;
    background: ${theme.mixins.background.white};
    position: absolute;
    top: -2px;
    left: -4px;
  }
`;

const CountButton = styled.div<{ showIndicator: boolean }>`
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 0px 4px 4px;
  background: ${theme.mixins.background.white};
  border: 1px solid ${theme.mixins.border.black};
  border-top: none;
  box-shadow: ${theme.mixins.shadow.dark};
  color: ${theme.mixins.typography.fontColor.black};
  font-size: ${theme.mixins.typography.fontSize.sixteen};
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  margin: 0px ${theme.mixins.spacing / 2}px;
  position: relative;

  &:after {
    display: ${props => (props.showIndicator ? 'block' : 'none')};
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: ${theme.mixins.background.pink};
    position: absolute;
    top: -6px;
    left: 18px;
  }
`;

const Stamp = styled.img<{ rotate?: Rotate; frame: FrameVo }>`
  width: ${props => props.frame.width};
  position: absolute;
  object-fit: contain;
  z-index: 10;
  ${theme.mixins.userSelectDisabled};
  ${props => {
    switch (props.rotate) {
      case 'right':
        return `
          top: calc(${props.frame.top} - 1%);
          left: calc(${props.frame.left} + 1%);
          transform: rotate(1.25deg);
        `;
      case 'left':
        return `
          top: calc(${props.frame.top} + 1%);
          left: calc(${props.frame.left} + 0%);
          transform: rotate(-0.79deg);
        `;
      default:
        return `
          top: calc(${props.frame.top} + 2%);
          left: calc(${props.frame.left} + 2%);
        `;
    }
  }};
`;
